import { baseApiURL } from "../Utilities/Utility";
import axios from "axios";

export function CustomerDataUpload(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) { 
    const options = {
        method: "post",
        headers: {
            //Accept: "application/json, text/plain, */*",
            //"Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData,
    };
    return fetch(baseApiURL + "/Customer/UploadCustomerDatafile?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


export function GetAllCustomerList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Customer/GetAllCustomerDetails?EncdUserId=" + encodeURIComponent(userId) ,  options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetCustomerList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Customer/GetCustomerTestimonial?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetCustomerYear(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,yearId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        
        
    };
    return fetch(baseApiURL + "/Customer/GetCustomerTestimonialBasedOnYear?EncdUserId=" + encodeURIComponent(userId) +"&yearId=" +yearId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getSave(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {

    const options = {
        method: "post",
        headers: {
            
            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData
    };
    return fetch(baseApiURL + "/Customer/PostCustomerTestimonialsDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function getUpdate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,formData) {

    const options = {
        method: "put",
        headers: {
           

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData
    };
    return fetch(baseApiURL + "/Customer/UpdateCustomerTestimonialsDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//export function CustomerAttachmentsBasedonId(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId, epPoentryId) {
   
//    const options = {
//        method: "get",
//        url: baseApiURL + "/Customer/CTAttachmentsBasedonId", 
       
//        headers: {
//            Authorization: "Bearer " + siteToken.token,
//            AzureToken: azureToken,
//            UserEmail: userEmail,
//            RoleName: roleName,
//            Gid: gid,
//            Roleid: roleId,
//        },
//        params: {
//            customerTestimonialId: epPoentryId,
//            EncdUserId: encodeURIComponent(userId),
//        }
//    };
//    return (
//        axios(options)
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );
//}
export function CustomerAttachmentsBasedonId(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, epPoentryId) {
   
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
      
    return fetch(baseApiURL + "/Customer/CTAttachmentsBasedonId?customerTestimonialId="+ epPoentryId + "&EncdUserId=" +encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deletePOEntryAttachments(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {
    var formdata = {
        AttachmetId: data.attachmetId,
    };
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formdata)
    };
    return fetch(baseApiURL + "/Customer/CTDeleteAttachments?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function deleteCTEntry(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,id) {
    var formdata = {
        Id:id,
    };
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formdata)
    };
    return fetch(baseApiURL + "/Customer/DeleteCTEntryDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getCountryMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
    };
    return fetch(baseApiURL + "/Common/GetCountryMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


//export function getState(siteToken,azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId, countryid) {
//    const options = {
//        method: "get",
//        url: baseApiURL + "/Common/GetStateMaster",
//        headers: {
//            Authorization: "Bearer " + siteToken.token,
//            AzureToken: azureToken,
//            UserEmail: userEmail,
//            RoleName: roleName,
//            Gid: gid,
//            Roleid: roleId,
//        },
//        params: { EncdUserId: encodeURIComponent(userId), countryid: countryid },
//    };
//    return (
//        axios(options)
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );
    
//}
export function getState(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, countryid) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Common/GetStateMaster?EncdUserId=" + encodeURIComponent(userId) +"&countryid=" +countryid , options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getEngineerMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        
    };
    return fetch(baseApiURL + "/Common/GetEngineerMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
//export function getOfficeMaster(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId, EngineerId) {
//    const options = {
//        method: "get",
//        url: baseApiURL + "/Common/GetOfficeMaster",
//        headers: {
//            Authorization: "Bearer " + siteToken.token,
//            AzureToken: azureToken,
//            UserEmail: userEmail,
//            RoleName: roleName,
//            Gid: gid,
//            Roleid: roleId,
//        }, 
//        params: { EncdUserId: encodeURIComponent(userId), EngineerId: EngineerId },
//    };
//    return (
//        axios(options)
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );

//}
export function getOfficeMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, EngineerId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },


    };
    return fetch(baseApiURL + "/Common/GetOfficeMaster?EncdUserId=" + encodeURIComponent(userId) + "&EngineerId=" + EngineerId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

//export function getRegionMaster(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId, EngineerId) {
//    const options = {
//        method: "get",
//        url: baseApiURL + "/Common/GetRegionMaster",
//        headers: {
//            Authorization: "Bearer " + siteToken.token,
//            AzureToken: azureToken,
//            UserEmail: userEmail,
//            RoleName: roleName,
//            Gid: gid,
//            Roleid: roleId,
//        }, 
//        params: { EncdUserId: encodeURIComponent(userId), EngineerId: EngineerId },
//    };
//    return (
//        axios(options)
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );

//}
export function getRegionMaster(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, EngineerId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },


    };
    return fetch(baseApiURL + "/Common/GetRegionMaster?EncdUserId=" + encodeURIComponent(userId) + "&EngineerId=" + EngineerId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getUpdateDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,data) {

    const options = {

        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
       

        body: JSON.stringify(data),

    };
    return fetch(baseApiURL + "/Customer/UpdateCustomerDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function getSaveDetails(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, data) {

    const options = {

        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

        body: JSON.stringify(data),

    };
    return fetch(baseApiURL + "/Customer/PostCustomerDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function PriceDataUpload(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,formData) {
    const options = {
        method: "post",
        headers: {
           

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
       
        body: formData,
    };
    return fetch(baseApiURL + "/Offer/UploadPriceMaster?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetPriceList(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Offer/GetAllPriceDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function updatePrice(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "put",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formData)
        

    };
    return fetch(baseApiURL + "/Offer/UpdatePriceDetails?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function savePrice(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
       
       
        body: JSON.stringify(formData),

    };
    return fetch(baseApiURL + "/Offer/PostPriceData?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}