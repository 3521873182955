import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Grid,
    IconButton,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import * as FileSaver from 'file-saver';
import DateRangeIcon from '@material-ui/icons/DateRange';
import "./Generate.scss";
import { baseApiURL } from "../../Utilities/Utility";
import {

    makeStyles,
    TableBody,
    ThemeProvider,
} from "@material-ui/core";
import EPQuotation_Template from "../../Common/EPQuotation Template.xlsx";
import {
    MTableBody,
    MTableGroupRow,
    MTableHeader,
    MTablePagination,
} from "material-table";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialTable from "material-table";
import Loader from "../../Components/Loader/Loader";
import { blue } from "@material-ui/core/colors";
import Customer_Template from "../../Common/CustomerMaster Template.xlsx";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector, useDispatch } from "react-redux";
import "../CreateQuotation/Create.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { GetAllCustomerList, GetCustomerList, GetCustomerYear, getSave, getUpdate } from "../../APIs/api_Customer";
import { GetGridData } from "../../APIs/api_Quotation";
import { getProduct, getOrder, getSector, getYear } from "../../APIs/api_Insert";
import FormControl from "@material-ui/core/FormControl";
import { BootstrapInput } from "../../Utilities/Utility";
import * as XLSX from "xlsx";
import Select from '@material-ui/core/Select';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { QuotationUpload, GetView,downloadPDFCertificate ,GetGSRYear,QuotationDate,downloadGoldCertificate} from "../../APIs/api_Quotation";
import { MenuItem, Tooltip } from "@material-ui/core";
import {
    getRoundUptoXDecimals,
    tableCellCurrentFY,
    tableCellPreviousFY,
    tableIcons,
    renderValueWithThousandSeparator,
    tdInputCurrentFY,
    tdTotalInPut,
} from "../../Utilities/Utility";

export default function GenerateReport() {
    const useStyles = makeStyles(() => ({
        paperSty: {
            padding: "40px",
            marginTop: "30px",
        },
        childGrid: {
            marginTop: "20px",
        },
        btnGrid: {
            justifyContent: "center",
            marginTop: "2rem",
        },
        tableCellPreviousFY: tableCellPreviousFY,
        tableCellCurrentFY: tableCellCurrentFY,
        tableCellTotal: tdTotalInPut,
    }));
    const classes = useStyles();
    const [fileValue, setfileValue] = useState("");
    let [errorData, setErrorData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [data, setData] = useState([]);

    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [isLoader, setIsLoader] = useState(false);
    
    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [product, setProduct] = useState([]);
    const [productValue, setProductValue] = useState("");
    const [order, setOrder] = useState([]);
    const [orderValue, setOrderValue] = useState("");
    const [sector, setSector] = useState([]);
    const [sectorValue, setSectorValue] = useState("");
    const [year, setYear] = useState([]);
    const [yearValue, setYearValue] = useState("");
    const [yearId, setYearId] = useState(null);
    //End - snackbar
    const [openDia, setOpenDia] = useState(false);
    const [openDate, setOpenDate] = useState(false);
   

    const handleCloseDia = () => {
        //clear();
        setOpenDia(false);
    };
    const handleCloseDate= () => {
        //clear();
        setOpenDate(false);
    };
    const handleOpenDia = (rowData) => {
        //clear();
        setOpenDia(true);
        setRow(rowData);
    };
    
    
    const api = axios.create({
        baseURL: "api/Customer",
    });
    const [formDialogTitle, setFormDialogTitle] = useState("Save");
    
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    useEffect(() => {
        console.log( baseApiURL + "/Quotation/UpdateServiceCompletedOn")
        setYearValue(43);
        setYearId(43);
        GridData();
        //Setproduct();
        //Setsector();
        //Setorder();
        Setyear();
        ////handleClickOpen();
        //cust();
    }, []);

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        if (
            typeof dialogContent === "string" &&
            dialogContent.toLowerCase().includes("success")
        ) {
            setInputFileValue(Date.now);
            setSelectedFile(null);
        }
        setDialogOpen(false);
        setInputFileValue(Date.now);
        setErrorData([]);
    };
    const handleCloseDialog = (e) => {
        setOpen(false);
    };
    const handleOpenDate = (rowData) => {
        setSelectedDate(rowData.serviceCompletedOn)
        setOpenDate(true);
        setRowData(rowData)
    }
    const handleCertFileDownload = (rowData) => {
        setIsLoader(true);
        //if (rowData.fileUrl == null) {
        //    setIsLoader(false);
        //    handleSnackOpen("Certificate Not Uploaded", "warning");
        //    return false;
        //}
        console.log(rowData.ourRef);

        var poentryid = rowData.epPoentryPageId;
        var customer = rowData.customerName;
        downloadPDFCertificate(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, poentryid)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen("No Records are Found", "success");
                    } else {

                        const fileName = customer +"_SilverReport";
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, fileName + ".pdf");
                        setIsLoader(false);
                        handleSnackOpen("Report downloaded.", "success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen("Report not Available to Download.", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }
    const handleCertFileDownloadGold = (rowData) => {
        setIsLoader(true);
        //if (rowData.fileUrl == null) {
        //    setIsLoader(false);
        //    handleSnackOpen("Certificate Not Uploaded", "warning");
        //    return false;
        //}
        console.log(rowData.ourRef);

        var poentryid = rowData.epPoentryPageId;
        var custId = rowData.customerId;
        var customer = rowData.customerName;
        downloadGoldCertificate(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, custId,poentryid)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen("No Records are Found", "success");
                    } else {

                        const fileName = customer +"_GoldReport";
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, fileName + ".pdf");
                        setIsLoader(false);
                        handleSnackOpen("Report downloaded.", "success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen("Report not Available to Download.", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }
    function ExportCustomerErrorDataToExcel() {
        console.log("test");
    }
    const exportErrorData = () => {

        ExportCustomerErrorDataToExcel();


    };
    function ExportCustomerErrorDataToExcel() {
        const dataToDownload = errorData.map((row) => {
            return {

                quotationId: row.quotationId,

                cutomerPo: row.cutomerPo,

                ourRef: row.ourRef,

                idNo: row.idNo,

                remarks: row.remarks?.replace(".", ".\n "),

            };
        });
        let headers = ["sl_no", "cutomer_po", "our_ref", "id_no", "Remarks"  ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsCustomer(dataToDownload);
        ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 150 }]; 
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "ExcelUploadErrors.xlsx");
    }
    function cellAdjustToContentsCustomer(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.quotationId ? c.quotationId?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerPo ? c.customerPo?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.idNo ? c.idNo?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }
    const [row, setRow] = useState([]);
    const [rowData, setRowData] = useState([]);
    const columns = [
        { title: "Customer", field: "customerName" },

        { title: "PO Number", field: "ponumber" },

       /* { title: "Service Completed On", field: "serviceCompletedOn" ,type:"date"},*/
        {
            title: "Service Completed On", field: "serviceCompletedOn",type: "date",
            render: (rowData) => (
              // setRowData(rowData),
            
               
                <Button>
                    <DateRangeIcon
                        className="checkIcon"
                        onClick={() => handleOpenDate(rowData)
                                             

                        }
                    />
                 <span>{(user(rowData.serviceCompletedOn))}</span>
                </Button>

                
            )
           
        },
        {
            title: "No Of Breaker Completed", field: "noOfBreaker",
           
        },
        {
            title: "Upload Breaker Data", field: "product", editable: false,
            render: (rowData) => (
                                         
                <Button>
                    <CloudUploadIcon
                        className="checkIcon"
                        onClick={() => handleOpenDia(rowData)

                        }
                    />
                </Button>
                

            ), },
        
        {
            title: "Silver Report", field: "doc", editable: false,
            render: (rowData) => (
                <Button>
                    <CloudDownloadIcon
                        className="checkIcon"
                    onClick={() => handleCertFileDownload(rowData)}
                    />
                </Button>
            ),
        },
        {
            title: "Gold Report", field: "doc", editable: false,
            render: (rowData) => (
                <Button>
                    <CloudDownloadIcon
                        className="checkIcon"
                    onClick={() => handleCertFileDownloadGold(rowData)}
                    />
                </Button>
            ),
        },
        {
            title: "Platinum Report", field: "doc", editable: false,
            render: (rowData) => (
                <Button>
                    <CloudDownloadIcon
                        className="checkIcon"
                    //onClick={() => handleCertFileDownload(rowData)}
                    />
                </Button>
            ),
        },
    ];
    function user(value) {
        if (value!= null && value != undefined) {
            const dateStr = new Date(value);
            const options = {
                year: "numeric",
                month: "numeric",
                day: "numeric"
                //day: '2-digit',
                //month: '2-digit',
                //year: 'numeric' 
                //hour: "numeric",
                //minute: "numeric",
                //second: "numeric",
                //hour12: true,
            };
            const retDateStr = dateStr.toLocaleDateString("en-US", options);
            return retDateStr;
        }

    }
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertClose = () => {
        setSelectedFile(null);
        setOpenAlertDialog(false);
        setIsLoading(false);
    };
    const [alertMessage, setAlertMessage] = useState("");
    const handleAlertOpen = (message) => {
        setOpenAlertDialog(true);
        setAlertMessage(message);
    };
    const handleErrorClose = () => {
        setOpenErrorDialog(false);
    };
    const handleYear = (e) => {
        setYearValue(e.target.value);
        setYearId(e.target.value);


    }
    const fileUploadHandler = (e) => {
        setInputFileValue(e.target.files[0]);
        console.log(e.target.files[0]);

    }
    function convertDateFormat(selData) {
        var dateStr =
            selData.getFullYear() + "-" +
            ("00" + (selData.getMonth() + 1)).slice(-2) + "-" +
            ("00" + selData.getDate()).slice(-2) + " " +
            ("00" + selData.getHours()).slice(-2) + ":" +
            ("00" + selData.getMinutes()).slice(-2) + ":" +
            ("00" + selData.getSeconds()).slice(-2);

        return dateStr;
    }
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
       // const selData = convertDateFormat(date);
       //console.log(selData);
        //const selData = ;
        setSelectedDate(date.toISOString());
    };
   
    function Setyear() {
        console.log("hi");
        setIsLoader(true);

        getYear(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                setYear(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function SetCustyear() {
        console.log("hi");
        setIsLoader(true);

        GetGSRYear(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, yearId)
            .then((response) => {
                console.log("gsr",response);

                setData(response);
               
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
   
    function GridData() {
        GetGridData(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("hi",response);
                console.log("grid data", response);

                setData(response);
                //setTotals([{ ...response.totals }]);
                //console.log("totals", totals);

            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }
    const [display, setDisplay] = useState([]);
    //function viewDisplay(rowData) {
    //    console.log("hi");
    //    setIsLoader(true);

    //    GetView(token, rowData.epPoentryPageId, userDetails.userId,)
    //        .then((response) => {
    //            console.log("gridres",response);

    //            setDisplay(response);
                
    //            setIsLoader(false);
    //        })
    //        .catch((error) => {
    //            handleSnackOpen("Error : " + error, "error");
    //            setIsLoader(false);
    //        });


    //}
    function btoaSecure(value) {
        const { btoa } = require("abab");
        let currDate = new Date().toDateString();
        let valueModified = value + " " + currDate;
        let valueSecured = btoa(btoa(valueModified));
        return valueSecured;
    }

    function clickEditHardCodeMaster(rowData) {
        console.log("rowData",rowData)
        let id = rowData.epPoentryPageId;
        let idEncrypted = btoaSecure(id);
        //viewDisplay(rowData.epPoentryPageId);
        window.location.href = "/view?id=" + idEncrypted;
        //viewDisplay(rowData.epPoentryPageId);
    }
    function GetEditButton(rowData) {
        return (
            <Tooltip title="Modify Quotation" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function submitDate() {
        const formData = new FormData();
        setIsLoader(true);
      
        //formData.append("UserId", userDetails.userId);
        
        //formData.append("PoEntryId", row.epPoentryPageId);
        //formData.append("ServiceCompletedOn", selectedDate);
        
        
        //UploadData(formData);
        QuotationDate(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, userDetails.userId, rowData.epPoentryPageId, selectedDate)
            .then((response) => {
                console.log("hi");
                console.log(response);
                if (response.response) {
                    setIsLoader(false);

                    GridData();
                    handleSnackOpen("updated");
                    setSelectedDate(null);
                    handleCloseDate()
                } else {
                    setIsLoader(false);
                    setSelectedDate(null);
                   // setInputFileValue(Date.now);
                    console.log(response.objResult);
                    handleSnackOpen("error","error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                //setSelectedFile(null);
                //setInputFileValue(Date.now);
                handleDialogMessage("Exception in update");
            });
    }
    function submitValueDetails() {
        const formData = new FormData();
        setIsLoader(true);
        formData.append("file", selectedFile);
        formData.append("UserId", userDetails.userId);
        formData.append("customerId", row.customerId);
        formData.append("productId", row.epPoentryPageId);
        //UploadData(formData);
        QuotationUpload(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,formData)
            .then((response) => {
                console.log("hi");
                console.log(response);
                if (response.response) {
                    setIsLoader(false);
                    GridData();
                    handleDialogMessage(response.responseMsg);
                } else {
                    setIsLoader(false);
                    setSelectedFile(null);
                    setInputFileValue(Date.now);
                    console.log(response.objResult);
                    setErrorData(response.objResult);
                    handleDialogMessage(
                        response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                    );
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setSelectedFile(null);
                setInputFileValue(Date.now);
                handleDialogMessage("Exception in upload");
            });
    }
    function getCustomTBodyForTotal(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_CustomerTestinomial, 0)
                            )}

                        </TableCell>


                    </TableRow>
                ))}
            </TableBody>
        );
    }
    
    

    return (
        <>
            <div>
                <Grid container spacing={2}>
                    {/*<Grid*/}
                    {/*    container*/}
                    {/*    spacing={2}*/}
                    {/*    item*/}
                    {/*    xs={12}*/}
                    {/*    className="d-flex jc-space-bt"*/}
                    {/*>*/}
                        {/*<Grid item xs={12} className="text-left ml-1">*/}
                        {/*    <h2>Reports</h2>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={3} md={2}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input" >
                                    Year
                                </InputLabel>


                                <Select
                                    label="closing_coil"
                                    id=""
                                    name=""
                                    className=""
                                    fullWidth
                                    value={yearValue}
                                    onChange={handleYear}
                                    input={<BootstrapInput />}
                                >
                                    {year.map((item) => (
                                        <MenuItem key={item.yearId} value={item.yearId}>
                                            {item.year}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid xs={12} sm={3} className="mr-btm">
                            <Button className="pt-button--primary button-submit"
                                variant="contained"
                                onClick={SetCustyear}
                            >
                                Search
                            </Button>
                        </Grid>

                   {/* </Grid>*/}
                 



                    <div className="marginTop">
                        <Grid>
                            <MaterialTable
                                title="Reports"
                                columns={columns}
                                data={data}
                                icons={tableIconsInputScreen}

                                options={{ headerStyle: { background: '#d3d3d3' }, tableOptionsUser, exportButton: { csv: true } }}
                                actions={[
                                    //{
                                    //    icon: "+",
                                    //    isFreeAction: true,
                                    //    onClick: (rowData) => {
                                    //       // setFormDialogTitle("Save");
                                    //        //setOpenDia(true);
                                            


                                    //    },
                                    //},


                                    {
                                        icon: GetEditButton,
                                        tisFreeAction: true,
                                        onClick: (event, rowData) => clickEditHardCodeMaster(rowData),
                                    },
                                ]}
                               

                            />
                        </Grid>
                    </div>
                </Grid>
                <Dialog fullWidth
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseDia();
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openDia}
                    maxWidth={"lg"}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="responsive-dialog-title">Upload Quotation</DialogTitle>
                    <div style={{ position: "absolute", right: "1%" }}>
                        <Tooltip title="Close">
                            <IconButton onClick={handleCloseDia}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="text-left ml-1">
                                    <h2>Upload Quotation Data</h2>
                                </Grid>
                                <Grid container spacing={2} xs={12}>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            id="EPQUploadFile"
                                            variant="filled"
                                            className="bulk-upload"
                                            type="file"
                                            name="bulkUpload"
                                            key={inputFileValue}
                                            onChange={updateExcelHandler}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button
                                            className="pt-button--primary bulk-upload-submit-btn"
                                            type="submit"
                                            // disabled={selectedFile ? false : true}
                                            onClick={submitValueDetails}
                                        >
                                            Upload
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} md={3} className="warningTextCont">
                                        <span>
                                            Note while uploading file:<br />

                                            All date format should be in "mm/dd/yyyy" format
                                        </span>
                                    </Grid>

                                    <Grid container xs={12}>
                                        <Grid item xs={12} md={3}>
                                            <a
                                                href={EPQuotation_Template}
                                                download="EPQuotation_Template"
                                                target="_blank"
                                            >
                                                <Button className="pt-button--primary bulk-upload-submit-btn">
                                                    Download Template
                                                </Button>
                                            </a>
                                        </Grid>
                                    </Grid>

                                   
                                </Grid> 


                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/*<Button*/}
                        {/*    type="submit"*/}
                        {/*    fullWidth*/}
                        {/*    className="pt-button--secondary"*/}
                        {/*    id="addUserSave"*/}
                        {/*    onClick={*/}
                        {/*        formDialogTitle === "Save" ? handleSaveForm : handleUpdateForm*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    {formDialogTitle}*/}
                        {/*</Button>*/}
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseDia();
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openDate}
                    maxWidth={"lg"}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="responsive-dialog-title">Date Picker</DialogTitle>
                    <div style={{ position: "absolute", right: "1%" }}>
                        <Tooltip title="Close">
                            <IconButton onClick={handleCloseDate}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container spacing={2}>
                                <Grid item xs={12} className="text-left ml-1">
                                    <h2>Service Completed On</h2>
                                </Grid>
                                <Grid container spacing={2} xs={12}>
                                    <Grid item xs={12} sm={4} >
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                // margin="normal"
                                                id="date-picker-dialog"
                                                label="PO Date"
                                                format="MM/dd/yyyy"
                                                placeholder="MM/dd/yyyy"
                                                InputProps={{ readOnly: true }}
                                                fullWidth
                                                // maxDate={new Date()}
                                                value={selectedDate}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>

                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <Button
                                            className="pt-button--primary bulk-upload-submit-btn"
                                            type="submit"
                                            // disabled={selectedFile ? false : true}
                                            onClick={submitDate}
                                        >
                                            Update
                                        </Button>
                                    </Grid>
                                    


                                </Grid>


                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {/*<Button*/}
                        {/*    type="submit"*/}
                        {/*    fullWidth*/}
                        {/*    className="pt-button--secondary"*/}
                        {/*    id="addUserSave"*/}
                        {/*    onClick={*/}
                        {/*        formDialogTitle === "Save" ? handleSaveForm : handleUpdateForm*/}
                        {/*    }*/}
                        {/*>*/}
                        {/*    {formDialogTitle}*/}
                        {/*</Button>*/}
                    </DialogActions>
                </Dialog>
                <div>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {" Data Upload Alert"}
                        </DialogTitle>
                        <DialogContent>
                            {dialogContent && typeof dialogContent === "object" ? (
                                <DialogContentText id="alert-dialog-description">
                                    <h5>
                                        {dialogContent
                                            ?.filter((x, index) => index === 0)
                                            ?.map((c) => (
                                                <>&emsp;&emsp;{c.join(" ")}</>
                                            ))}
                                    </h5>
                                    <ol className="ol-bulkUpload-Error">
                                        {dialogContent
                                            ?.filter((x, index) => index !== 0)
                                            ?.map((c) => (
                                                <li>
                                                    {c.map((x) =>
                                                        x.includes("--") && x.includes(":") ? (
                                                            x
                                                        ) : (
                                                            <ul className="ul-bulkUpload-Error">
                                                                <li>{x}</li>
                                                            </ul>
                                                        )
                                                    )}
                                                </li>
                                            ))}
                                    </ol>
                                </DialogContentText>
                            ) : (
                                <DialogContentText
                                    id="alert-dialog-description "
                                    className="dialog-center"
                                >
                                    {dialogContent?.includes("successfully.") ? (
                                        <>
                                            {dialogContent?.split("successfully.")[0] +
                                                " successfully."}
                                            {dialogContent?.split("successfully.")?.length > 0 ? (
                                                <>
                                                    <br />
                                                    {dialogContent?.split("successfully.")[1]}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        dialogContent
                                    )}
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                className="pt-button--secondary"
                                title="Close"
                            >
                                Close
                            </Button>
                            {errorData && errorData.length > 0 ? (
                                <Button
                                    onClick={exportErrorData}
                                    className="pt-button--secondary"
                                    title={
                                        "Export   upload error data to excel"
                                    }
                                >
                                    Export Error Data
                                </Button>
                            ) : (
                                <></>
                            )}
                        </DialogActions>
                    </Dialog>
                </div>
                {isLoader ? <Loader loaderText="Loading...!" /> : <></>}


            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleCloseDialog}
            />
        </>
    );
} 