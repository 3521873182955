import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../Screens/Home/Home";
import CreateQuotation from "../Screens/CreateQuotation/CreateQuotation";
import InsertQuotation from "../Screens/Index/Index";
import HomePage from "../Screens/Home/HomePage"
import Display from "../Screens/Display/Display"
import Report from "../Screens/Reports/Report"
import Customer from "../Screens/Customer/Customer";
import Price from "../Screens/Customer/Price";
import CustomerPage from "../Screens/CustomerPage/CustomerPage";
import POEntry from "../Screens/POEntryPage/POEntryPage";
import Offer from "../Screens/Offer/Offer";
import PrepareOffer from "../Screens/Offer/PrepareOffer";
//import EditPrepareOffer from "../Screens/Offer/EditPrepOffer";
import GenerateReport from "../Screens/GenerateReports/GenerateReport";
import UploadTab from "../Screens/Customer/UploadTab";
import UserManagement from "../Screens/Administrator/UserManagement/UserManagement";
import Termncondition from "../Screens/Customer/Termncondition";
import Error from "../Screens/Error/Error404";
import UnauthorizedAccess from "../Components/UnauthorizedAccess/UnauthorizedAccess"; 

import PreviewLetters from "../Screens/Offer/PreviewLetter";
import PreviewPage from "../Screens/Preview/PreviewPage";



export const MasterRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        {/* <Redirect from="/signin-oidc*" to="/" />*/}
        <Route exact path="/" render={() => <HomePage />} />
        <Route exact path="/createQuotation" render={() => <CreateQuotation />} />
        <Route exact path="/insertQuotation" render={() => <InsertQuotation />} />
        <Route exact path="/home" render={() => <Home />} />
        <Route exact path="/view" render={() => <Display />} />
        <Route exact path="/report" render={() => <Report />} />
        <Route exact path="/customer" render={() => <Customer />} />
        <Route exact path="/price" render={() => <Price />} />
        <Route exact path="/Termncondition" render={() => <Termncondition />} />
        <Route exact path="/upload" render={() => <UploadTab />} />
        <Route exact path="/customerTestimonal" render={() => <CustomerPage />} />
        <Route exact path="/poupload" render={() => <POEntry />} />
        <Route exact path="/offer" render={() => <Offer />} />
        <Route exact path="/PrepareOffer" render={() => <PrepareOffer />} />
        <Route exact path="/generateReport" render={() => <GenerateReport />} />
        <Route exact path="/userManagement" render={() => <UserManagement />} />
        <Route exact path="/PreviewLetters" render={() => <PreviewLetters />} />
        <Route exact path="/PreviewPage" render={() => <PreviewPage />} />
        <Route render={() => <Error />} />
    </Switch>
);
export const EngineerRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <HomePage />} />
        <Route exact path="/poupload" render={() => <POEntry />} />
        <Route exact path="/generateReport" render={() => <GenerateReport />} />
        <Route exact path="/view" render={() => <Display />} />
        <Route exact path="/customerTestimonal" render={() => <CustomerPage />} />
        <Route exact path="/offer" render={() => <Offer />} />
        <Route exact path="/PrepareOffer" render={() => <PrepareOffer />} />
        <Route exact path="/PreviewLetters" render={() => <PreviewLetters />} />
        <Route exact path="/PreviewPage" render={() => <PreviewPage />} />
        <Route render={() => <Error />} />
    </Switch>
);
export const RMRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <HomePage />} />
        <Route exact path="/poupload" render={() => <POEntry />} />
        <Route exact path="/generateReport" render={() => <GenerateReport />} />
        <Route exact path="/view" render={() => <Display />} />
        <Route exact path="/customerTestimonal" render={() => <CustomerPage />} />
        <Route exact path="/offer" render={() => <Offer />} />
        <Route exact path="/PrepareOffer" render={() => <PrepareOffer />} />
        <Route exact path="/PreviewPage" render={() => <PreviewPage />} />
        <Route render={() => <Error />} />
    </Switch>
);
export const CommercialRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <HomePage />} />
        <Route exact path="/poupload" render={() => <POEntry />} />
        <Route exact path="/generateReport" render={() => <GenerateReport />} />
        <Route exact path="/view" render={() => <Display />} />
        <Route exact path="/customerTestimonal" render={() => <CustomerPage />} />
        <Route exact path="/offer" render={() => <Offer />} />
        <Route exact path="/PrepareOffer" render={() => <PrepareOffer />} />
        <Route exact path="/PreviewLetters" render={() => <PreviewLetters />} />
        <Route exact path="/PreviewPage" render={() => <PreviewPage />} />
        <Route render={() => <Error />} />
    </Switch>
);
export const OtherRoute = (
    <Switch>
        <Redirect from="/?Code*" to="/" />
        <Route exact path="/" render={() => <HomePage />} />
        <Route
            exact
            path="/UnauthorizedAccess"
            render={() => <UnauthorizedAccess />}
        />
        <Route render={() => <Error />} />
    </Switch>
);

