import React, { useEffect, useState } from "react";
import axios from "axios";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import {
    TextField,
    Button,
    Grid,
    IconButton,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import {
    GetAddButton,

} from "../../Utilities/Utility";
import { DeleteOutlined } from "@material-ui/icons";

import TableCell from "@material-ui/core/TableCell";
import "./customerPage.scss"
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'; import CloseIcon from "@material-ui/icons/Close";
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import {
   
    makeStyles,
    TableBody,
    ThemeProvider,
} from "@material-ui/core";
import  {
    MTableBody,
    MTableGroupRow,
    MTableHeader,
    MTablePagination,
} from "material-table";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//import CloseIcon from "@material-ui/icons/Close";
import InputLabel from "@material-ui/core/InputLabel";
import MaterialTable from "material-table";
import Loader from "../../Components/Loader/Loader";
import { blue } from "@material-ui/core/colors";
import Customer_Template from "../../Common/CustomerMaster Template.xlsx";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector, useDispatch } from "react-redux";
import "../CreateQuotation/Create.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { GetAllCustomerList, GetCustomerList, GetCustomerYear, getSave, getUpdate, CustomerAttachmentsBasedonId, deletePOEntryAttachments,deleteCTEntry,getCountryMaster,getState,getEngineerMaster,getOfficeMaster,getRegionMaster} from "../../APIs/api_Customer";
import { getCountry, getCustomerDetailsMaster } from "../../APIs/api_PoEntry";
import { getProduct, getOrder, getSector,getYear } from "../../APIs/api_Insert";
import FormControl from "@material-ui/core/FormControl";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { BootstrapInput } from "../../Utilities/Utility";
import * as XLSX from "xlsx";
import Select from '@material-ui/core/Select';
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { MenuItem, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    getRoundUptoXDecimals,
    tableCellCurrentFY,
    tableCellPreviousFY,
    tableIcons,
    renderValueWithThousandSeparator,
    tdInputCurrentFY,
    tdTotalInPut,
} from "../../Utilities/Utility";

export default function CustomerPage() {
    const useStyles = makeStyles(() => ({
        paperSty: {
            padding: "40px",
            marginTop: "30px",
        },
        childGrid: {
            marginTop: "20px",
        },
        btnGrid: {
            justifyContent: "center",
            marginTop: "2rem",
        },
        tableCellPreviousFY: tableCellPreviousFY,
        tableCellCurrentFY: tableCellCurrentFY,
        tableCellTotal: tdTotalInPut,
    }));
    const classes = useStyles();
    const [fileValue, setfileValue] = useState("");
    let [errorData, setErrorData] = useState([]);
    const [rollHelper, setRollHelper] = useState("");
    const [poHelper, setPoHelper] = useState("");
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
   
    const [fileAttachments, setFileAttachments] = useState([]);
    const [epPoentryId, setEPPoentryId] = useState(null);
    const [data, setData] = useState([]);
    
    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [isLoader, setIsLoader] = useState(false);
    let [totals, setTotals] = useState([
        {
            poValue_CustomerTestinomial:0,
                
        },
    ]);
    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [product, setProduct] = useState([]);
    const [productValue, setProductValue] = useState("");
    const [order, setOrder] = useState([]);
    const [orderValue, setOrderValue] = useState("");
    const [sector, setSector] = useState([]);
    const [sectorValue, setSectorValue] = useState("");
    const [year, setYear] = useState([]);
    const [yearValue, setYearValue] = useState("");
    const [yearId, setYearId] = useState(null);
    //End - snackbar
    const [openDia, setOpenDia] = useState(false);

    //const handleClickOpen = () => {
    //    setOpenDia(true);
    //};
    const [customer, setCustomer] = useState("");
    const [customerObj, setCustomerObj] = useState("");
    const [customerText, setCustomerText] = useState("");
    const [itemsCustomer, setItemsCustomer] = useState([]);
    const [errorCustomer, setErrorCustomer] = useState(false);
    const [errorTextCustomer, setErrorTextCustomer] = useState("");

    const handleChangeCustomer = (event, newValue) => {
        setCustomerObj(newValue);
        setCustomer(newValue ? newValue.epCustomerId : 0);
        console.log("Customer", newValue.epCustomerId);
        setCodeObj(newValue);
        setCode(newValue ? newValue.epCustomerId : 0);
    };
    const handleChangeCustomerInput = (event, newInputValue) => {
        setCustomerText(newInputValue);
        //setCodeText(newInputValue);
        if (newInputValue.length >= 3) {
            //const token = localStorage.getItem("access_token");
            cust(newInputValue);
        }
    };
    const [code, setCode] = useState("");
    const [codeObj, setCodeObj] = useState("");
    const [codeText, setCodeText] = useState("");
    const [itemsCode, setItemsCode] = useState([]);
    const [errorCode, setErrorCode] = useState(false);
    const [errorTextCode, setErrorTextCode] = useState("");

    const handleChangeCode = (event, newValue) => {
        setCodeObj(newValue);
        setCode(newValue ? newValue.epCustomerId : 0);
        setCustomerObj(newValue);
        setCustomer(newValue ? newValue.epCustomerId : 0);
        console.log("Code", newValue.epCustomerId);
    };
    const handleChangeCodeInput = (event, newInputValue) => {
        setCodeText(newInputValue);
       // setCustomerText(newInputValue);
        if (newInputValue.length >= 3) {
            //const token = localStorage.getItem("access_token");
           cust(newInputValue);
        }
    };
    const handleCloseDia = () => {
        clear();
        setOpenDia(false);
    };
    //const [fileValue, setfileValue] = useState("");
    //const [selectedFile, setSelectedFile] = useState(null);
    //const [inputFileValue, setInputFileValue] = useState(Date.now);
    //string hooks
    //const [customer, setCustomer] = useState([]);
    const [customerValue, setCustomerValue] = useState("");
    const [customerId, setCustomerId] = useState(null);
    //const [code, setCode] = useState("");
    //const [country, setCountry] = useState("");
    const [countryName, setCountryName] = useState([]);
    const [countryVal, setCountryVal] = useState("");


    function countryValChange(e) {
        setCountryVal(e.target.value);
        state(e.target.value);

    }
    function engineerValChange(e) {
        setEng(e.target.value);
        getOffice(e.target.value);
        getRegion(e.target.value);

    }
   
    const [stateName, setStateName] = useState([]);
    function stateValChange(e) {
        setStateVal(e.target.value);

    }
    const [stateVal, setStateVal] = useState("");
    const [regionName, setRegionName] = useState([]);
    const [region, setRegion] = useState("");
    const [officeName, setOfficeName] = useState([]);
    const [office, setOffice] = useState("");
    const [poNo, setPoNo] = useState("");
    const [engineerName, setEngineerName] = useState([]);
    const [eng, setEng] = useState("");
    const [poVal, setPoVal] = useState(0);
    const [soNo, setSoNo] = useState(null);
    const [fromPeriod, setFromPeriod] = useState(null);
    const api = axios.create({
        baseURL: "api/Customer",
    });
    const [formDialogTitle, setFormDialogTitle] = useState("Save");
    //ONCHANGES
    //const handleChangeCustomer = e => {
    //    setCustomerValue(e.target.value)
    //    setCustomerId(e.target.value)
    //}
    //const handleChangeCode = e => {
    //    setCode(e.target.value)
    //}
    
    //const handleChangeState = e => {
    //    setState(e.target.value)
    //}
    const handleChangeRegion = e => {
        setRegion(e.target.value)

    }
    const handleChangeOffice = e => {
        setOffice(e.target.value)
    }
    const handleChangePoNo = e => {
        setPoNo(e.target.value)
    }
    //const handleChangeEng = e => {
    //    set(e.target.value)
    //}
    const handleChangePoVal = e => {
        setPoVal(e.target.value)
    }
    
    const handleChangeSoNo = e => {
        let valid;


        switch (e.target.id) {
            case "rollnumber":
                setSoNo(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setRollHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setRollHelper("");

                }
                break;
            case "po":
                setPoVal(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setPoHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setPoHelper("");

                }
                break;
            default:
                break;
        }
       
    }
    const handleToPeriod = (date) => {
        setFromPeriod(date);
    };
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    useEffect(() => {
        setYearValue(43);
        setYearId(43);
        GridData();
        Setproduct();
        Setsector();
        Setorder();
        Setyear();
        country();
        //handleClickOpen();
        //state();
        //cust();
        getEngineer();
    }, []);

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
   

    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    //const handleClose = (e, reason) => {
    //    if (reason === "backdropClick") {
    //        return;
    //    }
    //    if (
    //        typeof dialogContent === "string" &&
    //        dialogContent.toLowerCase().includes("success")
    //    ) {
    //        setInputFileValue(Date.now);
    //        setSelectedFile(null);
    //    }
    //    setDialogOpen(false);
    //    setInputFileValue(Date.now);
    //    setErrorData([]);
    //};
    const handleClose = (e) => {
        setOpen(false);
    };
    const exportErrorData = () => {
        ExportCustomerErrorDataToExcel();
    };
    function ExportCustomerErrorDataToExcel() {
        console.log("test");
    }
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const fileUploadHandler = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files);
        setSelectedFile(chosenFiles);
        console.log("SelectedFile", event.target.files);
    };
    var attachColumns = [
        {
            title: "File Name",
            field: "filename"
        },
        {
            title: "Download",
            render: rowData => renderFileDownload(rowData)
        },
        {
            title: "Delete",
            render: rowData => renderFileDelete(rowData)
        },
    ];
    function renderFileDownload(rowData) {
        return (
            <Button
                href={rowData.fileUrlWithSasToken}
                color="transparent"
                target="_blank"
                download
            >
                <Tooltip title="Download Attachment">
                    <DownloadIconOutlined />
                </Tooltip>
            </Button>);
    }
    function deleteAttachment(rowData) {
        deletePOEntryAttachments(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, rowData)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                getPOAttachments(siteToken,
                     rowData.custId, );
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    function renderFileDelete(rowData) {
        const rowD = rowData;
        return (
            <Button
                color="transparent"
               onClick={() => deleteAttachment(rowD)}
            >
                <Tooltip title="Delete Attachment">
                    <DeleteOutlineOutlinedIcon />
                </Tooltip>
            </Button>);
    }
    const columns = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.ctattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        { title: "Customer", field: "customerName" },
        {
            title: "Code", field: "code"
        },
        { title: "Country", field: "country" },
        { title: "State", field: "state" },
        { title: "Sector", field: "sectorName" },
        { title: "Region", field: "region" },
        { title: "Office", field: "office" },
        { title: "PO Number", field: "ponumber" },
        { title: "Engineer", field: "engineer" },
        {
            title: "PO Dated", field: "podated", type: "date",

            dateSetting: { locale: "en-GB" },
},
        { title: "Product", field: "productName" },
        { title: "PO Value", field: "povalue" },
        { title: "Order Type", field: "orderName" },
        { title: "SO Number", field: "sonumber" },
//        {
//            title: "Documents Uploads", field: "doc", editable: false,
//            render: (rowData) => (
//                <Button>
//                    <CloudUploadIcon
//                        className="checkIcon"
//                        //onClick={() => handleCertFileDownload(rowData)}
//                    />
//                </Button>
//            ),
//},
    ];
    const [custDialogTitle, setCustDialogTitle] = useState("Add Customer Testimonial");
    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertClose = () => {
        setSelectedFile(null);
        setOpenAlertDialog(false);
        setIsLoading(false);
    };
    const [alertMessage, setAlertMessage] = useState("");
    const handleAlertOpen = (message) => {
        setOpenAlertDialog(true);
        setAlertMessage(message);
    };
    const handleErrorClose = () => {
        setOpenErrorDialog(false);
    };
    const handleYear = (e) => {
        setYearValue(e.target.value);
        setYearId(e.target.value);

                    
    }
    
    
    //dropdowns
    const [id, setId] = useState(null);
    const [sectorId, setSectorId] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [productId, setProductId] = useState(null);
    const handleSector = (event) => {
        setSectorValue(event.target.value);
        setSectorId(event.target.value);

        

    };
    const handleProduct = (event) => {
        setProductValue(event.target.value);
        setProductId(event.target.value);


    };
    const handleOrder = (event) => {
        setOrderValue(event.target.value);
        setOrderId(event.target.value);


    };
    //function submitValueDetails() {
    //    const formData = new FormData();
    //    formData.append("file", selectedFile);
    //    formData.append("UserId", userDetails.userId);
    //    CustomerDataUpload(formData, token)
    //        .then((response) => {
    //            console.log(response);
    //            if (response.response) {
    //                setIsLoader(false);
    //                GridData();
    //                handleDialogMessage(response.responseMsg);
    //            } else {
    //                setIsLoader(false);
    //                setSelectedFile(null);
    //                setInputFileValue(Date.now);
    //                setErrorData(response.objResult);
    //                handleDialogMessage(
    //                    response.responseMsgs && response.responseMsgs.length > 0
    //                        ? response.responseMsgs
    //                        : response.responseMsg
    //                );
    //            }
    //        })
    //        .catch((error) => {
    //            setIsLoader(false);
    //            setSelectedFile(null);
    //            setInputFileValue(Date.now);
    //            handleDialogMessage("Exception in upload");
    //        });
    //}
    function country() {
        setIsLoading(true);
        getCountryMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("country", response);
                setCountryName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function state(countryid) {
        setIsLoading(true);
        getState(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,countryid)
            .then((response) => {
                console.log("state", response);
                setStateName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getEngineer() {
        setIsLoading(true);
        getEngineerMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("eng", response);
                setEngineerName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getOffice(EngineerId) {
        setIsLoading(true);
        getOfficeMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, EngineerId)
            .then((response) => {
                console.log("office", response);
                setOfficeName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getRegion(EngineerId) {
        setIsLoading(true);
        getRegionMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, EngineerId)
            .then((response) => {
                console.log("region", response);
                setRegionName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function Setproduct() {
        console.log("hi");
        setIsLoading(true);

        getProduct(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);
               
                setProduct(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function Setorder() {
        console.log("hi");
        setIsLoading(true);

        getOrder(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                setOrder(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function Setsector() {
        console.log("hi");
        setIsLoading(true);

        getSector(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                setSector(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function Setyear() {
        console.log("hi");
        setIsLoading(true);

        getYear(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                setYear(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function SetCustyear() {
        console.log("hi");
        setIsLoading(true);

        GetCustomerYear(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, yearId)
            .then((response) => {
                console.log(response);

                setData(response.listCustomerTestimonial);
                setTotals([{ ...response.totals }]);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function cust(value) {
        getCustomerDetailsMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,value)
            .then((response) => {
                console.log("customercode", response);
                setItemsCustomer(response);
                setItemsCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            });
    }
    function GridData() {
        setIsLoader(true);

        GetCustomerList(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("hi");
                console.log("grid data", response);
              
                setData(response.listCustomerTestimonial);
                setTotals([{ ...response.totals }]);
                console.log("totals", totals);
                setIsLoader(false)
            })
      
            .catch((error) => {
                setIsLoader(false)
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }
    //function handleCopyIconClick(rowData) {
    //    setIsLoading(true);
    //    copyPOEntryDetails(siteToken, rowData.yearId, rowData.epPoentryPageId, userDetails.userId).
    //        then((response) => {
    //            setIsLoading(false);
    //            handleSnackOpen(response.responseMsg, "success");
    //            getAllPoEntryDetails();
    //        })
    //        .catch((error) => {
    //            setIsLoading(false);
    //            handleSnackOpen(error, "error")
    //        })
    //}
    function GetEditButton(rowData) {
        return (
            <Tooltip title="Modify " placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetCopyButton() {
        return (
            <Tooltip title="Copy" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <FileCopyOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function getCustomTBodyForTotal(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_CustomerTestinomial, 0)
                            )}
                            
                        </TableCell>
                        
                       
                    </TableRow>
                ))}
            </TableBody>
        );
    }
    function convertDateFormat(selData) {
        var dateStr =
            selData.getFullYear() + "-" +
            ("00" + (selData.getMonth() + 1)).slice(-2) + "-" +
            ("00" + selData.getDate()).slice(-2) + " " +
            ("00" + selData.getHours()).slice(-2) + ":" +
            ("00" + selData.getMinutes()).slice(-2) + ":" +
            ("00" + selData.getSeconds()).slice(-2);

        return dateStr;
    }
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteRowData(null);
    };
    const handleDeleteIconClick = (rowData) => {
        setDeleteRowData(rowData);
        setDeleteDialogOpen(true);
    };
    function deletePoEntryRow() {
        setIsLoading(true);
        deleteCTEntry(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, deleteRowData.id)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                handleCloseDeleteDialog();
                //grid(deleteRowData.productId, deleteRowData.yearId);
                GridData();
                setIsLoading(false);

            })
            .catch((error) => {
                handleSnackOpen(error, "error");
                setIsLoading(false);
            });
    }
    const handleDateChange = (date) => {
        const selData = convertDateFormat(date);
        console.log(selData);

        setSelectedDate(selData);
    };
    function GetDeleteButton() {
        return (
            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function getPOAttachments(siteToken, id) {
        setIsLoading(true);
        CustomerAttachmentsBasedonId(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, id )
            .then((response) => {
                setFileAttachments(response);
                console.log("Attch Response", response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function clear() {
        //setYearValue(null);
        setCustomerObj("");
        setCustomer("");
        setCustomerText("");
        setCodeObj("");
        setCode("");
        setCodeText("");
        
       // setCode("");
        setCountryVal("");
        setStateVal("");
        setSectorValue(null);
        setRegion("");
        setOffice("");
        setPoNo("");
        setEng("");
        setSelectedDate(null);
        setProductValue(null);
        setOrderValue(null);
        setPoVal(0);
        setSoNo(null);
        setSelectedFile(null);
        setInputFileValue(Date.now);
        setEPPoentryId(null);
        setId(null);
        setFileAttachments([]);
        
    }
    
   
    const handleSaveForm = () => {
        setFormDialogTitle("Save");
      
        //if (yearId === "" || yearId === null || yearId === undefined) {
        //    handleSnackOpen("year is Empty", "error");
        //    return;
        //}
        if (customer=== "" || customer === null || customer === undefined) {
            handleSnackOpen("customer is Empty", "error");
            return;
        }
        if (code === "" || code === null || code === undefined) {
            handleSnackOpen("code is Empty", "error");
            return;
        }
        if (eng=== "" || eng === null || eng === undefined) {
            handleSnackOpen("engineer is Empty", "error");
            return;
        }
        if (region=== "" || region=== null || region=== undefined) {
            handleSnackOpen("region is Empty", "error");
            return;
        }
        if (office === "" || office === null || office === undefined) {
            handleSnackOpen("office is Empty", "error");
            return;
        }
        if (poNo === "" || poNo=== null || poNo=== undefined ) {
            handleSnackOpen("po number is Empty", "error");
            return;
        }
       
        
        if (poVal === "" || poVal === null || poVal=== undefined) {
            handleSnackOpen("po value is Empty", "error");
            return;
        }
        if (productValue === "" || productValue === null || productValue === undefined) {
            handleSnackOpen("product is Empty", "error");
            return;
        }
        if (orderValue === "" || orderValue === null || orderValue === undefined) {
            handleSnackOpen("order is Empty", "error");
            return;
        }
        if (sectorValue === "" || sectorValue === null || sectorValue === undefined) {
            handleSnackOpen("sector is Empty", "error");
            return;
        }
        if (stateVal=== "" || stateVal === null || stateVal=== undefined) {
            handleSnackOpen("state is Empty", "error");
            return;
        }
        if (selectedDate === "" || selectedDate=== null || selectedDate === undefined) {
            handleSnackOpen("po date is Empty", "error");
            return;
        }
        if (soNo=== "" || soNo === null || soNo === undefined) {
            handleSnackOpen("so number  is Empty", "error");
            return;
        }
        if (poHelper != "") {
            handleSnackOpen("check po value", "error");
            return;
        }
        if (rollHelper != "") {
            handleSnackOpen("check So number", "error");
            return;
        }
          setIsLoading(true);
        const formData = new FormData();
        if (selectedFile == null) {
            formData.append('file', selectedFile);
        }
        else {


            if (selectedFile.length === 0) {
                formData.append('file', selectedFile);
            }
            else {
                for (let i = 0; i < selectedFile.length; i++) {
                    formData.append("Document", selectedFile[i]);

                }
            }
        }
        formData.append('userId', userDetails.userId);
        formData.append('yearId', yearId);
        formData.append('productId', productId);
        formData.append('customerId', customer);
        formData.append('codeId', code);
        formData.append('countryId', countryVal);
        formData.append('stateId', stateVal);
        formData.append('regionId', region);
        formData.append('officeId', office);
        formData.append('sectorId', sectorId);
        formData.append('poNumber', poNo);


        formData.append('poDated', selectedDate);

        formData.append('poValue', poVal);
        formData.append('engineerId', eng);
        formData.append('orderTypeId', orderId);
        formData.append('sonumber',soNo);
       

        console.log("formData", formData);
        getSave(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                if (response.response) {
                    
                    handleCloseDia();
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    GridData();
                } else {
                   
                    handleCloseDia();
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                
                handleCloseDia();
                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    const handleUpdateForm = () => {
        //if (yearId === "" || yearId === null || yearId === undefined) {
        //    handleSnackOpen("year is Empty", "error");
        //    return;
        //}
      
        if (customer === "" || customer=== null || customer === undefined) {
            handleSnackOpen("customer is Empty", "error");
            return;
        }
        if (code === "" || code === null || code === undefined) {
            handleSnackOpen("code is Empty", "error");
            return;
        }
        if (eng === "" || eng === null || eng === undefined) {
            handleSnackOpen("engineer is Empty", "error");
            return;
        }
        if (region === "" || region === null || region === undefined) {
            handleSnackOpen("region is Empty", "error");
            return;
        }
        if (office === "" || office === null || office === undefined) {
            handleSnackOpen("office is Empty", "error");
            return;
        }
        if (poNo === "" || poNo === null || poNo === undefined) {
            handleSnackOpen("po number is Empty", "error");
            return;
        }
        if (poVal === "" || poVal === null || poVal === undefined) {
            handleSnackOpen("po value is Empty", "error");
            return;
        }
        if (productValue === "" || productValue === null || productValue === undefined) {
            handleSnackOpen("product is Empty", "error");
            return;
        }
        if (orderValue === "" || orderValue === null || orderValue === undefined) {
            handleSnackOpen("order is Empty", "error");
            return;
        }
        if (sectorValue === "" || sectorValue === null || sectorValue === undefined) {
            handleSnackOpen("sector is Empty", "error");
            return;
        }
        if (stateVal === "" || stateVal === null || stateVal === undefined) {
            handleSnackOpen("state is Empty", "error");
            return;
        }
        if (selectedDate === "" || selectedDate === null || selectedDate === undefined) {
            handleSnackOpen("po date is Empty", "error");
            return;
        }
        if (soNo === "" || soNo === null || soNo === undefined) {
            handleSnackOpen("so number  is Empty", "error");
            return;
        }
        if (poHelper != "") {
            handleSnackOpen("check po value", "error");
            return;
        }
        if (rollHelper != "") {
            handleSnackOpen("check So number", "error");
            return;
        }
         setIsLoading(true);
        console.log("id", id);
        const formData = new FormData();
        //formData.append('file', selectedFile);
        //for (let i = 0; i < selectedFile.length; i++) {
        //    formData.append("Document", selectedFile[i]);

        //}
        if (selectedFile == null) {
            formData.append('file', selectedFile);
        }
        else {


            if (selectedFile.length === 0) {
                formData.append('file', selectedFile);
            }
            else {
                for (let i = 0; i < selectedFile.length; i++) {
                    formData.append("Document", selectedFile[i]);

                }
            }
        }
        formData.append('userId', userDetails.userId);
        formData.append('yearId', yearId);
        formData.append('productId', productId);
        formData.append('customerId', customer);
        formData.append('codeId', code);
        formData.append('countryId', countryVal);
        formData.append('stateId', stateVal);
        formData.append('regionId', region);
        formData.append('officeId', office);
        formData.append('sectorId', sectorId);
        formData.append('poNumber', poNo);


        formData.append('poDated', selectedDate);

        formData.append('poValue', poVal);
        formData.append('engineerId', eng);
        formData.append('orderTypeId', orderId);
        formData.append('sonumber', soNo);
        formData.append('CustomerTestimonialId', id);


        console.log("formData", formData);
        
        getUpdate(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,formData)
            .then((response) => {
                console.log("update,response");
                if (response.response) {
                    
                    handleCloseDia();
                    
                    handleSnackOpen(response.responseMsg, "success");
                    setIsLoading(false);
                    GridData();
                } else {
                    handleCloseDia();
                    
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                handleCloseDia();
                
                handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });
    }
    async function setUpdate(rowData) {
       // handleClickOpen();
        console.log("rowdata",rowData);
        //setYearValue(rowData.yearId);
       // console.log("custobj",custObj);
        //const custObj = itemsCustomer.filter((item) => item.epCustomerId === rowData.custId);
        //setCustomerObj(custObj?.length > 0 ? custObj[0] : []);
        //setCustomer(rowData.custId);
        //setCustomerText(rowData.customerName);
        //const codeObj = itemsCode.filter((item) => item.epCustomerId === rowData.custId);
        //setCodeObj(codeObj?.length > 0 ? codeObj[0] : []);
        //setCode(rowData.custId);
        //setCodeText(rowData.customerCode);
        setCustomerObj(rowData.customerObject);
        setCustomer(rowData.customerObject ? rowData.customerObject.epCustomerId : 0);
        setCodeObj(rowData.customerObject);
        setCode(rowData.customerObject ? rowData.customerObject.epCustomerId : 0);
       setCustomerText(rowData.customerName);
       // setCustomerText(rowData.customerObject.customerName1);
        //setCodeText(rowData.customerObject.customerCode);
        setCodeText(rowData.code);
        //setEPPoentryId(rowData.Id);
        setId(rowData.id);
        setYearId(yearId);
       
        setYearValue(yearId);
        setCountryVal(rowData.countryId);
        state(rowData.countryId)
        
        setStateVal(rowData.stateId);
        setSectorValue(rowData.sectorId);
        setSectorId(rowData.sectorId);
        getOffice(rowData.engineerId);
        getRegion(rowData.engineerId)
        setRegion(rowData.regionId);
        setOffice(rowData.officeId);
        setPoNo(rowData.ponumber);
        setEng(rowData.engineerId);
        setSelectedDate(rowData.podated);
        setProductValue(rowData.productId);
        setProductId(rowData.productId);
        setOrderValue(rowData.orderTypeId);
        setOrderId(rowData.orderTypeId);
        setPoVal(rowData.povalue);
        setSoNo(rowData.sonumber);
        setFileAttachments(rowData. ctattachmentlist.length > 0 ? rowData.ctattachmentlist : []);
        //setFileAttachments(rowData.poattachmentlist.length > 0 ? rowData.poattachmentlist[0].fileUrlWithSasToken : []);
        // setSelectedFile(null);

        CustomerAttachmentsBasedonId(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, rowData.id)
            .then((response) => {
                setFileAttachments(response);
                console.log("Attach Response", response);
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            })
        setFormDialogTitle("Update");
        setOpenDia(true);
    }
    
    return (
        <>
            <div>
                <Grid container spacing={2}>
                    {/*<Grid*/}
                    {/*    container*/}
                    {/*    spacing={2}*/}
                    {/*    item*/}
                    {/*    xs={12}*/}
                    {/*    className="d-flex jc-space-bt"*/}
                    {/*>*/}
                        {/*<Grid item xs={12} className="text-left ml-1">*/}
                        {/*    <h2>Customer Testimonial</h2>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={3} md={2} style={{ marginTop:'10px'}}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="bootstrap-input" >
                                    Year
                                </InputLabel>


                                <Select
                                    label="closing_coil"
                                    id=""
                                    name=""
                                    className=""
                                    fullWidth
                                    value={yearValue}
                                    onChange={handleYear}
                                    input={<BootstrapInput />}
                                >
                                    {year.map((item) => (
                                        <MenuItem key={item.yearId} value={item.yearId}>
                                            {item.year}
                                        </MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                            
                        </Grid>
                        <Grid xs={12} sm={3} className="mr-btm">
                        <Button className="pt-button--primary button-submit bulk-upload-submit-btn"
                                variant="contained"
                                onClick={SetCustyear}
                            >
                                Search
                            </Button>
                        </Grid>
                     
                  {/*  </Grid>*/}
                    
                   
                   

                       <div className="marginTop">
                        <Grid>
                            <MaterialTable
                                title="Customer Testimonial"
                                columns={columns}
                                data={data}
                                icons={tableIconsInputScreen}

                              
                                options={{
                                    headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "370px", searchFieldAlignment: "left",
                                    toolbarButtonAlignment: "left", exportButton: { csv: true }, exportAllData: true,
                                }}
                                actions={[
                                    {
                                        icon: GetAddButton,
                                        isFreeAction: true,
                                        onClick: () => {
                                            setFormDialogTitle("Save");
                                            setCustDialogTitle("Add Customer Testimonial");
                                            setOpenDia(true);
                                            
                                            
                                        },
                                    },

                                    
                                    {
                                        icon: GetEditButton,
                                        tisFreeAction: true,
                                        onClick: (event, rowData) => {
                                            setCustDialogTitle("Update Customer Testimonial")
                                            setUpdate(rowData)
                                        }
                                    },
                                    {
                                        icon: GetDeleteButton,
                                        tisFreeAction: true,
                                        onClick: (e, rowData) => {
                                            console.log("Row Data", rowData);
                                            handleDeleteIconClick(rowData);
                                        },
                                    },
                                    
                                ]}
                                components={{
                                    //Header: (props) => (
                                    //    <>
                                           
                                    //        <MTableHeader {...props} />
                                    //        {getCustomTBodyForTotal(totals)}
                                    //    </>
                                    //),
                                    //Body: (props) => (
                                    //    <>
                                    //        <MTableBody {...props} />
                                    //    </>
                                    //),
                                    
                                }}
                                
                            />
                        </Grid>
                    </div>
                </Grid>
                <Dialog fullWidth
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseDia();
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openDia}
                    maxWidth={"lg"}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="responsive-dialog-title">{custDialogTitle}</DialogTitle>
                    <div style={{ position: "absolute", right: "1%" }}>
                        <Tooltip title="Close">
                            <IconButton onClick={handleCloseDia}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <Autocomplete
                                        className="lightInputFields hover-lightblue"
                                        id="combo-box-demo"
                                        fullWidth
                                        disableClearable
                                        value={customerObj}
                                        inputValue={customerText}
                                        options={itemsCustomer}
                                        getOptionLabel={(option) => (option.customerName1 ? option.customerName1 : "")}
                                        onChange={handleChangeCustomer}
                                        onInputChange={handleChangeCustomerInput}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Customer"
                                                variant="filled"
                                                // required
                                                error={errorCustomer}
                                                helperText={errorTextCustomer}
                                            />
                                        )}
                                    />
                                </Grid>

                            
                           
                                
                                    {/*<FormControl fullWidth>*/}
                                    {/*    <InputLabel shrink htmlFor="bootstrap-input">*/}
                                    {/*        Code*/}
                                    {/*    </InputLabel>*/}
                                    {/*    <BootstrapInput id="cutomer_po"*/}
                                    {/*        name="cutomerPo"*/}
                                    {/*    value={code}*/}
                                    {/*    onChange={handleChangeCode}*/}

                                    {/*    />*/}
                                    {/*</FormControl>*/}
                                    <Grid item xs={12} sm={3}>
                                        <Autocomplete
                                            className="lightInputFields hover-lightblue"
                                            id="combo-box-demo"
                                        fullWidth
                                        disableClearable
                                            value={codeObj}
                                            inputValue={codeText}
                                            options={itemsCode}
                                            getOptionLabel={(option) => (option.customerCode ? option.customerCode : "")}
                                            onChange={handleChangeCode}
                                            onInputChange={handleChangeCodeInput}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Code"
                                                    variant="filled"
                                                    // required
                                                    error={errorCode}
                                                    helperText={errorTextCode}
                                                />
                                            )}
                                        />
                                   
                                </Grid>

                          
                            
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Country
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={countryVal}
                                            onChange={countryValChange}
                                            input={<BootstrapInput />}
                                        >
                                            {countryName.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.countryName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                          
                            
                                {/*<Grid item xs={12}  sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            State*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*        value={state}*/}
                                {/*        onChange={handleChangeState}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            State
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={stateVal}
                                            onChange={stateValChange}
                                            input={<BootstrapInput />}
                                        >
                                            {stateName.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.stateName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            
                            
                                <Grid item xs={12}  sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            Sector
                                        </InputLabel>

                                        <Select
                                            label="Model"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                           value={sectorValue}
                                            onChange={handleSector}
                                            input={<BootstrapInput />}
                                        >
                                            {sector.map((item) => (
                                                <MenuItem key={item.epSectorId} value={item.epSectorId}>
                                                    {item.epSectorName}
                                                </MenuItem>
                                            ))}

                                        </Select>


                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Engineer
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={eng}
                                            onChange={engineerValChange}
                                            input={<BootstrapInput />}
                                        >
                                            {engineerName.map((item) => (
                                                <MenuItem key={item.userId} value={item.userId}>
                                                    {item.userFirstName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            Region*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*        value={region}*/}
                                {/*        onChange={handleChangeRegion}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Region
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={region}
                                            onChange={handleChangeRegion}
                                            input={<BootstrapInput />}
                                        >
                                            {regionName.map((item) => (
                                                <MenuItem key={item.regionMasterId} value={item.regionMasterId}>
                                                    {item.regionMasterName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Office
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={office}
                                            onChange={handleChangeOffice}
                                            input={<BootstrapInput />}
                                        >
                                            {officeName.map((item) => (
                                                <MenuItem key={item.officeMasterId} value={item.officeMasterId}>
                                                    {item.officeMasterName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            PO Number
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            type="number"
                                        value={poNo}
                                        onChange={handleChangePoNo}

                                        />
                                    </FormControl>
                                </Grid>
                                {/*<Grid item xs={12} sm={3}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                {/*            Engineer*/}
                                {/*        </InputLabel>*/}
                                {/*        <BootstrapInput id="cutomer_po"*/}
                                {/*            name="cutomerPo"*/}
                                {/*            value={eng}*/}
                                {/*        onChange={handleChangeEng}*/}

                                {/*        />*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={3} >
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            // margin="normal"
                                            id="date-picker-dialog"
                                            label="PO Date"
                                            format="MM/dd/yyyy"
                                            placeholder="MM/dd/yyyy"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                            // maxDate={new Date()}
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            Product
                                        </InputLabel>

                                        <Select
                                            label="Model"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={productValue}
                                             onChange={handleProduct}
                                            input={<BootstrapInput />}
                                        >
                                            {product.map((item) => (
                                                <MenuItem key={item.epProductTypeId} value={item.epProductTypeId}>
                                                    {item.epProductTypeName}
                                                </MenuItem>
                                            ))}

                                        </Select>


                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            PO Value
                                        </InputLabel>
                                        <BootstrapInput id="po"
                                            name="cutomerPo"
                                            type="number"
                                            helperText={poHelper}
                                        value={poVal}
                                        onChange={handleChangeSoNo}

                                        />
                                        <div class="form-helper">{poHelper}</div>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            Order Type
                                        </InputLabel>

                                        <Select
                                            label="Model"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                             value={orderValue}
                                            onChange={handleOrder}
                                            input={<BootstrapInput />}
                                        >
                                            {order.map((item) => (
                                                <MenuItem key={item.epOrderTypeId} value={item.epOrderTypeId}>
                                                    {item.epOrderTypeName}
                                                </MenuItem>
                                            ))}

                                        </Select>


                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            SO Number
                                        </InputLabel>
                                        <BootstrapInput id="rollnumber"
                                            name="cutomerPo"
                                            helperText={rollHelper}
                                            type="number"
                                            onChange={handleChangeSoNo}
                                            value={soNo}

                                        />
                                        <div class="form-helper">{rollHelper}</div>
                                    </FormControl>
                                </Grid>
                                {/*<Grid item xs={6} md={5} sm={4}>*/}
                                {/*    <TextField*/}
                                {/*        //className="lightInputFields hover-lightblue"*/}
                                {/*        id="rollnumber"*/}
                                {/*       // fullWidth*/}
                                {/*        label="So Number"*/}
                                {/*        variant="filled"*/}
                                {/*        value={soNo}*/}
                                {/*       // error={rollHelper.length !== 0}*/}
                                {/*        helperText={rollHelper}*/}
                                {/*        //required={true}*/}
                                {/*        //inputProps={{ maxLength: 8 }}*/}
                                {/*        onChange={handleChangeSoNo}*/}
                                {/*       // disabled={checked ? false : true}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                 </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1">
                                    <form
                                        className="file-upload-form"
                                    >
                                        <TextField
                                            className="file-upload"
                                            fullWidth
                                            variant="filled"
                                            name="fileUpload"
                                            label="Documents Upload"
                                            type="file"
                                            inputProps={{
                                                multiple: true,
                                            }}
                                            onChange={fileUploadHandler}
                                            key={inputFileValue}
                                        />
                                    </form>
                                </Grid>
                                {/*<Button*/}
                                {/*    href={fileAttachments}*/}
                                {/*    color="transparent"*/}
                                {/*    target="_blank"*/}
                                {/*    download*/}
                                {/*>*/}
                                {/*    <Tooltip title="Download Attachment">*/}
                                {/*        <DownloadIconOutlined />*/}
                                {/*    </Tooltip>*/}
                                {/*</Button>*/}
                                <Grid xs={12} alignItems="center">
                                    {fileAttachments.length > 0 ? <MaterialTable
                                        title="Attachments"
                                        fullWidth
                                        columns={attachColumns}
                                        data={fileAttachments}
                                        icons={tableIconsInputScreen}
                                        options={{ tableOptionsUser, search: false }}
                                    /> : <></>}
                                </Grid>

                                {/* <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1 btnCenter">
                                    <Button
                                        type="submit"
                                        className="pt-button--primary"
                                        id="fileUpload"
                                    >
                                        Upload
                                    </Button>
                                </Grid> */}
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            
                            className="pt-button--secondary"
                            id="addUserSave"
                            onClick={
                                formDialogTitle === "Save" ? handleSaveForm : handleUpdateForm
                            }
                        >
                            {formDialogTitle}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="dialogTitleTxt">Delete Customer Testimonial</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to Delete this Customer Testimonial Entry ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={deletePoEntryRow}
                            className="pt-button--primary"
                        >
                            Ok
                        </Button>
                        <Button
                            onClick={handleCloseDeleteDialog}
                            className="pt-button--secondary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

               
                {isLoader ? <Loader loaderText="Loading...!" /> : <></>}
                {isLoading? <Loader loaderText="Loading...!" /> : <></>}


            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
} 