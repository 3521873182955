import React from "react";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerMenuIcon from "../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../assets/images/siemens-logo-white.svg";
import HomeIcon from "@material-ui/icons/Home";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import StorageIcon from '@material-ui/icons/Storage';
//import TableChartIcon from "@material-ui/icons/TableChartIcon";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import TableChartIcon from '@material-ui/icons/TableChart';

import { useSelector, useDispatch } from "react-redux"
import "./SideDrawer.scss";
import { MasterRouteLanding } from "../../Utilities/Utility";
import { Container, Grid } from "@material-ui/core";
import Loader from "../../Components/Loader/Loader";
import { getAllRoles } from "../../APIs/api_Insert";
//import { ReactHtmlParser } from "react-html-parser";
import { storeLandingMenuItemsAction, storeMenuItemsAction } from "../../Redux/Actions/actions";


const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: "auto",
    },
});

export default function SideDrawer() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const token = useSelector((state) => state.saveTokenReducer.token);

    const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
    const [drawerState, setDrawerState] = React.useState({
        left: false,
    });
    const [routes, setRoutes] = useState([]);
    let userDetails = useSelector((state) => state.storeUserDetailsReducer.userData);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };
    let [sideDrawerItems, setSideDrawerItems] = useState([])

    const icons = {
        "<ListAltOutlinedIcon />": <ListAltOutlinedIcon />,
       
        "<HomeIcon />": <HomeIcon />,
    
        "<StorageIcon />": <StorageIcon />,
        "<TableChartIcon />": <TableChartIcon />,
        "<GroupAddIcon />": <GroupAddIcon />,
        //"<ListAltOutlinedIcon />": <ListItem.ListAltOutlinedIcon />,
        //"<ListAltOutlinedIcon />": <ListItem.ListAltOutlinedIcon />
    };

    let jsonIconData = {
        
        icon: "<ListAltOutlinedIcon />",
       
        
    };

    

    function createMarkup(inputIcon) { return { __html: inputIcon }}
    const icondata = '<span><StorageIcon /></span>'


    function logoutUser() {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });

        window.location.href = "/";
    }

    function homeClick() {
        window.location.href = "/";
    }
    function getMenus() {
        //console.log("menu", MasterRouteLanding)
        //let filtered = MasterRouteLanding.filter((c) => c.roleId === 1);
        //setRoutes(filtered);
        getAllRoles(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId
        )
            //.then((response) => {
            //    if (response) {
            //        console.log('land', response)
            //        setRoutes(response);
            //    } else {
            //        setRoutes([]);
            //    }
            //})
            .then((response) => {
                if (response) {
                    console.log(response)
                    // var resp = response.slice(1, response.length - 1);
                    // setRoutes(resp);
                    dispatch(storeLandingMenuItemsAction(response));
                    dispatch(storeMenuItemsAction(response));
                } else {
                    // setRoutes([]);
                    dispatch(storeMenuItemsAction([]));
                    dispatch(storeLandingMenuItemsAction(response));
                }
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
            });
    }
   

    useEffect(() => {
        //if (userDetails && userDetails.userId > 0 && siteToken.token !== "") {

        //    getMenus();
        //}
        // dispatch(isForgotPasswordAction(false));
        if (userDetails?.userId > 0 && token !== "" && landingMenuItems?.length <= 0) {
            getMenus();
        }
    }, [userDetails]);





    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Link to="/" className="d-flex jc-center side-drawer-logo">
                <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
            </Link>
            <Link style={{ marginTop: '-10px' }} >
                <ListItem button onClick={homeClick} >
                    <ListItemIcon className="side-drawer-icons">
                        < HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
            </Link>
            <List className="side-drawer-menu-list" style={{ marginTop: '-10px' }}>
                {landingMenuItems.map((item, index) =>
                (
                    <Link to={item.menuLink} key={index}>
                        <ListItem button>
                            <ListItemIcon className="side-drawer-icons">
                               {/* {Object.keys(jsonIconData).map((el) => {*/}
                               
                                {icons[item.menuIcon]}
                               {/* <ReactHtmlParser(item.menuIcon) />*/}
                                
                               {/* <div dangerouslySetInnerHTML={createMarkup(item.menuIcon)} />*/}
                            {/*    })}*/}
                                {/*{[item].menuIcon}*/}
                            </ListItemIcon>
                            <ListItemText primary={item.menuName} />
                        </ListItem>

                    </Link>

                )

                )}

                

            </List>
            <Link style={{ marginTop: '-10px',padding:'0px !important' }}>
                <ListItem button onClick={logoutUser} >
                    <ListItemIcon className="side-drawer-icons">
                        < PowerSettingsNewOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                </ListItem>
            </Link>
            <div className="sideDrawer-footer-block">
                <p>© 2021 Siemens Intranet</p>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {/*<div>{ReactHtmlParser(icondata)}</div>*/}
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <img
                        src={DrawerMenuIcon}
                        alt="Besys Menu"
                        className="besys-menu-icon"
                        onClick={toggleDrawer(anchor, true)}
                    />
                    <SwipeableDrawer
                        anchor={anchor}
                        open={drawerState[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                </React.Fragment>
            ))}

           
          
        </React.Fragment>
    );
}
