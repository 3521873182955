import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import "./LoginRegister.scss";
import SiemensLogo from "../../assets/images/siemens-logo-petrol.svg";
import IntraLogo from "../../assets/images/sie-intranet-black-rgb.svg";
import LoginRegisterTabs from "./LoginRegisterTabs";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "rgba(0, 204, 204, .05)",
        backdropFilter: "blur(1px)",
        minHeight: "111vh",
        overflow: "auto",
    },
    paper: {
        padding: theme.spacing(2),
    },
    errorMessage: {
        color: "var(--red)",
        fontSize: "16px",
    },
    timerDetails: {
        backgroundColor: "var(--bg-green-2)",
    },
    forgotPwd: {
        textAlign: "center",
        textDecoration: "underline",
    },
    loginTabs: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        borderRadius: "15px",
        //backgroundColor: "rgba(0, 204, 204, .05)",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: "0px",
    },
}));

export default function LoginRoot() {
    const classes = useStyles();

    return (
        <div className="bg-login-register">
            <div className={classes.root}>
                <Container>
                    <Grid item xs={12}>
                        <div className="ssiat-login-logo-block">
                            <img src={SiemensLogo} className="official-logo" alt="Siemens" />
                        </div>
                    </Grid>
                    <Grid container className="ssiat-lr-mobileContainer">
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className="ssiat-lr--project-block"
                        >
                            <div className="ssiat-lr--project">
                                <p className="ssiat-lr-project-name">
                                    EP Quotation Tool
                                    <sup>
                                        <img
                                            src={IntraLogo}
                                            alt="Intra Logo"
                                            className="ssiat-intra-logo"
                                        />
                                    </sup>
                                </p>
                                {/* <span className="ssiat-lr--project-description">
                  Blend of diverse business tools along with reporting based on
                  SAP data
                </span> */}
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={6}
                            className="ssiat-lr--master-block"
                        >
                            <LoginRegisterTabs />
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
}
