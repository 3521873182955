import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Grid,
    MenuItem,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { BootstrapInput } from "../../Utilities/Utility";
import MaterialTable from "material-table";
import Loader from "../../Components/Loader/Loader";
import Select from '@material-ui/core/Select';
import { lightTheme } from "../../../src/Themes/LightTheme";
import './alignment.scss';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { blue } from "@material-ui/core/colors";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector, useDispatch } from "react-redux";
//import "./Insert.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import {
    getShort, getItripping, getNtripping, getGct, getGtripping, getGalarm, getTimeDelay, getEtuStatus, getCtTest, getTripUnit, getFunctionTest, getContactErosion, getArc, getLamination, getGuideFrame, getContactPressure, getPoleSet, getLubrication, getConfirmation, getCoil, getReady, getBreaker, getSpring, getVoltage, getTime, getService, getMech, getSub, getMemory, getModel, getInt, getAux
} from "../../APIs/api_Insert";
import { QuotationDataUpload, GetAll, GetFilter, downloadPDFCertificate, GetQuotationReport, GetView, downloadPDF, downloadGold } from "../../APIs/api_Quotation";

export default function InsertQuotation(props) {
    //const classes = useStyles();
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    let [isLoader, setIsLoader] = useState(true);
    const [fromPeriod, setFromPeriod] = useState(null);
    const [customerPo, setCustomerPo] = useState("");
    const [ourRef, setOurRef] = useState("");
    const [customer, setCustomer] = useState("");
    const [location, setLocation] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [mobile, setMobile] = useState(0);
    const [eMail, setEMail] = useState("");
    const [panel, setPanel] = useState("");
    const [feederName, setFeederName] = useState("");
    const [mlfb, setMlfb] = useState("");
    const [zOptions, setZOptions] = useState("");
    const [idNo, setIdNo] = useState("");
    const [breakerRating, setBreakerRating] = useState(0);
    const [size, setSize] = useState(0);
    const [pole, setPole] = useState("");
    const [breaker, setBreaker] = useState("");
    const [etu, setEtu] = useState("");
    // const [iTrippingCurrent, setiTrippingCurrent] = useState("");
    const [etuSerialNo, setEtuSerialNo] = useState("");
    const [i, setI] = useState(0);
    const [ITrippingIr, setITrippingIr] = useState(0);
    const [longTimeCurrent, setLongTimeCurrent] = useState(0);
    const [shortTimeIsd, setShortTimeIsd] = useState(0);
    const [shortTimeCurrentIsd, setShortTimeCurrentIsd] = useState(0);
    const [iTrippingcurrent, setITrippingCurrent] = useState("");
    const [manadatorySpares, setManadatorySpares] = useState("");
    const [recommendedSpares, setRecommendedSpares] = useState("");
    const [comments, setComments] = useState("");
    const [openPoints, setOpenPoints] = useState("");
    const [overAllBreakerHealthines, setOverAllBreakerHealthines] = useState(0);
    const [testedBy, setTestedBy] = useState("");
    const [contactNo1, setContactNo1] = useState(0);
    const [reviewedBy, setReviewedBy] = useState("");
    const [contactNo2, setContactNo2] = useState(0);
    //dropdowns
    const [service, setService] = useState([]);
    const [serviceValue, setServiceValue] = useState("");
    const [aux, setAux] = useState([]);
    const [auxValue, setAuxValue] = useState("");
    const [model, setModel] = useState([]);
    const [modelValue, setModelValue] = useState("");
    const [timeLagTr, setTimeLagTr] = useState([]);
    const [timelagTrValue, setTimeLagTrValue] = useState("");
    const [memory, setMemory] = useState([]);
    const [memoryValue, setMemoryValue] = useState("");
    const [shortTimeDelayTsd, setShortTimeDelayTsd] = useState([]);
    const [shortTimeDelayTsdValue, setShortTimeDelayTsdValue] = useState("");
    const [tripping, setTripping] = useState([]);
    const [trippingValue, setTrippingValue] = useState("");
    const [nTrippingIn, setNTrippingIn] = useState([]);
    const [nTrippingInValue, setNTrippingInValue] = useState("");
    const [i_n, setI_n] = useState([]);
    const [i_nValue, setI_nValue] = useState("");
    const [int, setInt] = useState([]);
    const [intValue, setIntValue] = useState("");
    const [gct, setGct] = useState([]);
    const [gctValue, setGctValue] = useState("");
    const [gTrippingIg, setGTrippingIg] = useState([]);
    const [gTrippingIgValue, setGTrippingIgValue] = useState("");
    const [gAlaramIg, setGAlaramIg] = useState([]);
    const [gAlarmIgValue, setGAlaramIgValue] = useState("");
    const [timeDelayTgTrip, setTimeDelayTgTrip] = useState([]);
    const [timeDelayTgTripValue, setTimeDelayTgTripValue] = useState("");
    const [etuStatus, setEtuStatus] = useState([]);
    const [etuStatusValue, setEtuStatusValue] = useState("");
    const [ctTest, setCtTest] = useState([]);
    const [ctTestValue, setCtTestValue] = useState("");
    const [functionTest, setFunctionTest] = useState([]);
    const [functionTestValue, setFunctionTestValue] = useState("");
    const [mechanicalReclosingLogout, setMechanicalReclosingLogout] = useState([]);
    const [mechanicalReclosingLogoutValue, setMechanicalReclosingLogoutValue] = useState("");
    const [tripUnit, setTripUnit] = useState([]);
    const [tripUnitValue, setTripUnitValue] = useState("");
    const [mechanicalInterLock, setMechanicalInterLock] = useState([]);
    const [mechanicalInterLockValue, setMechanicalINterLockValue] = useState("");
    const [rackingHandle, setrackingHandle] = useState([]);
    const [RackingHandleValue, setRackingHandleValue] = useState("");
    const [rackingMechanism, setRackingmechanism] = useState([]);
    const [rackingMechanismValue, setRackingMechanismValue] = useState("");
    const [contactErosionIndicator, setContactErosionIndicator] = useState([]);
    const [contactErosionIndicatorValue, setContactErosionIndicatorValue] = useState("");
    const [arcChutes, setArcChutes] = useState([]);
    const [arcChutesValue, setArcChutesValue] = useState("");
    const [shutter, setShutter] = useState([]);
    const [shutterValue, setShutterValue] = useState("");
    const [front, setFront] = useState([]);
    const [frontValue, setFrontValue] = useState("");
    const [laminationContacts, setLaminationContacts] = useState([]);
    const [laminationContactsValue, setLaminationContactsValue] = useState("");
    const [guideFrameTerminals, setGuideFrameTerminals] = useState([]);
    const [guideFrameTerminalsValue, setGuideFrameTerminalsValue] = useState("");
    const [contactPressure, setContactPressure] = useState([]);
    const [contactPressureValue, setContactPressureValue] = useState("");
    const [poleSet, setPoleSet] = useState([]);
    const [poleSetValue, setPoleSetValue] = useState("");
    const [lubrication, setLubrication] = useState([]);
    const [lubricationValue, setLubricationValue] = useState("");
    const [auxiliaryContactBlock, setAuxiliaryContactBlock] = useState([]);
    const [auxiliaryContactBlockValue, setAuxiliaryContactBlockValue] = useState("");
    const [springChargingManual, setSpringChargingManual] = useState([]);
    const [springChargingManualValue, setSpringChargingManualValue] = useState("");
    const [springChargingMotor, setSpringChargingMotor] = useState([]);
    const [springChargingMotorValue, setSpringChargingMotorValue] = useState("");
    const [underVoltage, setUnderVoltage] = useState([]);
    const [underVoltageValue, setUnderVoltageValue] = useState("");
    const [closingCoil, setClosingCoil] = useState([]);
    const [closingCoilValue, setClosingCoilValue] = useState("");
    const [shuntCoil, setShuntCoil] = useState([]);
    const [shuntCoilValue, setShuntCoilValue] = useState("");
    const [readyToCloseInterlock, setReadyToCloseInterlock] = useState([]);
    const [readyToCloseInterlockValue, setReadyToCloseInterlockValue] = useState("");
    const [breakerOperationsManual, setBreakerOperationsManual] = useState([]);
    const [breakerOperationsManualValue, setBreakerOperationsManualValue] = useState("");
    const [breakerOperationsElectrical, setbreakerOperationsElectrical] = useState([]);
    const [breakerOperationsElectricalValue, setBreakerOperationsElectricalValue] = useState("");







    const [fileValue, setfileValue] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const api = axios.create({
        baseURL: "api/Quotation",
    });

    const [disable, setDisable] = useState(true);

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    //console.log(userDetails);
    useEffect(() => {
        
            console.log("props", props);
            if (props.Mode == "edit") {
                
                 setState(props.editData);
               

            }
        short();
        iTripping();
        nTripping();
        gc();
        gTripping();
        gAlarm();
        timeDelay();
        status();
        test();
        unit();
        funcTest();
        contErosion();
        arc();
        lamination();
        guide()
        press();
        pol();
        lubri();
        confirm();
        coil();
        volt();
        ready();
        breakers();
        spring();
        time();
        serv();
        mech();
        mem();
        mod();
        intt();
        auxi();

        
    }, []);

    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);



    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [lastService, setLastService] = useState(null);
    const [manufacture, setManufacture] = useState(null);
    //id
    //const [serviceId, setServiceId] = useState("");
    //const serviceId=0;
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    //for error handling 
    const [iserror, setIserror] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [severity, setSeverity] = useState('success');
    const [canShowHelperText, setCanShowHelperText] = useState(false);
    //dropdown onclicks

    const [serviceId, setServiceId] = useState(null);
    const [auxValueId, setAuxValueId] = useState(null);
    const [memoryId, setMemoryId] = useState(null);
    const [modelId, setModelId] = useState(null);
    const [gctValueId, setGctValueId] = useState(null);
    const [intId, setIntId] = useState(null);
    const [timeLagTrValueId, setTimeLagTrValueId] = useState(null);
    const [shortTimeDelayTsdValueId, setShortTimeDelayTsdValueId] = useState(null);
    const [trippingValueId, setTrippingValueId] = useState(null);
    const [nTrippingInValueId, setNTrippingInValueId] = useState(null);
    const [i_nValueId, setI_nValueId] = useState(null);
    const [gTrippingIgValueId, setGTrippingIgValueId] = useState(null);
    const [gAlaramIgValueId, setGAlaramIgValueId] = useState(null);
    const [timeDelayTgTripValueId, setTimeDelayTgTripValueId] = useState(null);
    const [etuStatusValueId, setEtuStatusValueId] = useState(null);
    const [ctTestValueId, setCtTestValueId] = useState(null);
    const [functionTestValueId, setFunctionTestValueId] = useState(null);
    const [mechanicalReclosingLogoutValueId, setMechanicalReclosingLogoutValueId] = useState(null);
    const [tripUnitValueId, setTripUnitValueId] = useState(null);
    const [mechanicalINterLockValueId, setMechanicalINterLockValueId] = useState(null);
    const [rackingHandleValueId, setRackingHandleValueId] = useState(null);
    const [rackingMechanismValueId, setRackingMechanismValueId] = useState(null);
    const [contactErosionIndicatorValueId, setContactErosionIndicatorValueId] = useState(null);
    const [arcChutesValueId, setArcChutesValueId] = useState(null);
    const [shutterValueId, setShutterValueId] = useState(null);
    const [frontValueId, setFrontValueId] = useState(null);
    const [laminationContactsValueId, setLaminationContactsValueId] = useState(null);
    const [guideFrameTerminalsValueId, setGuideFrameTerminalsValueId] = useState(null);
    const [contactPressureValueId, setContactPressureValueId] = useState(null);
    const [poleSetValueId, setPoleSetValueId] = useState(null);
    const [lubricationValueId, setLubricationValueId] = useState();
    const [auxiliaryContactBlockValueId, setAuxiliaryContactBlockValueId] = useState(null);
    const [springChargingManualValueId, setSpringChargingManualValueId] = useState(null);
    const [springChargingMotorValueId, setSpringChargingMotorValueId] = useState(null);
    const [underVoltageValueId, setUnderVoltageValueId] = useState(null);
    const [closingCoilValueId, setClosingCoilValueId] = useState(null);
    const [shuntCoilValueId, setShuntCoilValueId] = useState(null);
    const [readyToCloseInterlockValueId, setReadyToCloseInterlockValueId] = useState(null);
    const [breakerOperationsManualValueId, setBreakerOperationsManualValueId] = useState(null);
    const [breakerOperationsElectricalValueId, setBreakerOperationsElectricalValueId] = useState(null);
    //const [longId,setLongId] = useState(0);
    //const [shortId,setShortId]= useState(0);
    let shortId = 0;
    shortId = i * shortTimeIsd;
    //shortId=shortTimeIsd * i;
    let longId = 0;
    // longId = i / ITrippingIr;
    longId = Math.trunc((i) * ITrippingIr / 100);

    //const serviceId = null;



    const handleService = (event) => {
        setServiceValue(event.target.value);

        setServiceId(event.target.value);

    };
    const handleModel = (event) => {
        setModelValue(event.target.value);

        setModelId(event.target.value);

    };
    const handleTimeLagTr = (event) => {
        setTimeLagTrValue(event.target.value);
        setTimeLagTrValueId(event.target.value);
    };
    const handleMemory = (event) => {
        setMemoryValue(event.target.value);
        setMemoryId(event.target.value);

    };
    const handleShortTimeDelayTsd = (event) => {
        setShortTimeDelayTsdValue(event.target.value);
        setShortTimeDelayTsdValueId(event.target.value);
    };
    const handleTripping = (event) => {
        setTrippingValue(event.target.value);
        setTrippingValueId(event.target.value);
    };
    const handleNTrippingIn = (event) => {
        setNTrippingInValue(event.target.value);
        setNTrippingInValueId(event.target.value);
    };
    const handleI_n = (event) => {
        setI_nValue(event.target.value);
        setI_nValueId(event.target.value);
    };
    const handleGct = (event) => {
        setGctValue(event.target.value);
        setGctValueId(event.target.value);
    };
    const handleGTrippingIg = (event) => {
        setGTrippingIgValue(event.target.value);
        setGTrippingIgValueId(event.target.value);
    };
    const handleGAlaramIg = (event) => {
        setGAlaramIgValue(event.target.value);
        setGAlaramIgValueId(event.target.value);
    };
    const handleTimeDelayTgTrip = (event) => {
        setTimeDelayTgTripValue(event.target.value);
        setTimeDelayTgTripValueId(event.target.value);
    };
    const handleEtuStatus = (event) => {
        setEtuStatusValue(event.target.value);
        setEtuStatusValueId(event.target.value);
    };
    const handleCtTest = (event) => {
        setCtTestValue(event.target.value);
        setCtTestValueId(event.target.value);
    };
    const handleFunctionTest = (event) => {
        setFunctionTestValue(event.target.value);
        setFunctionTestValueId(event.target.value);
    };
    const handleInt = (event) => {
        setIntValue(event.target.value);
        setIntId(event.target.value);
    };
    const handleMechanicalReclosingLogout = (event) => {
        setMechanicalReclosingLogoutValue(event.target.value);
        setMechanicalReclosingLogoutValueId(event.target.value);
    };
    const handleTripUnit = (event) => {
        setTripUnitValue(event.target.value);
        setTripUnitValueId(event.target.value);
    };
    const handleMechanicalINterLock = (event) => {
        setMechanicalINterLockValue(event.target.value);
        setMechanicalINterLockValueId(event.target.value);
    };
    const handleRackingHandle = (event) => {
        setRackingHandleValue(event.target.value);
        setRackingHandleValueId(event.target.value);
    };
    const handleAux = (event) => {
        setAuxValue(event.target.value);
        setAuxValueId(event.target.value);
    };
    const handleRackingMechanism = (event) => {
        setRackingMechanismValue(event.target.value);
        setRackingMechanismValueId(event.target.value);
    };
    const handleContactErosionIndicator = (event) => {
        setContactErosionIndicatorValue(event.target.value);
        setContactErosionIndicatorValueId(event.target.value);
    };
    const handleArcChutes = (event) => {
        setArcChutesValue(event.target.value);
        setArcChutesValueId(event.target.value);
    };
    const handleShutter = (event) => {
        setShutterValue(event.target.value);
        setShutterValueId(event.target.value);
    };
    const handleFront = (event) => {
        setFrontValue(event.target.value);
        setFrontValueId(event.target.value);
    }
    const handleLaminationContacts = (event) => {
        setLaminationContactsValue(event.target.value);
        setLaminationContactsValueId(event.target.value);
    };
    const handleGuideFrameTerminals = (event) => {
        setGuideFrameTerminalsValue(event.target.value);
        setGuideFrameTerminalsValueId(event.target.value);
    };
    const handleContactPressure = (event) => {
        setContactPressureValue(event.target.value);
        setContactPressureValueId(event.target.value);
    };
    const handlePoleSet = (event) => {
        setPoleSetValue(event.target.value);
        setPoleSetValueId(event.target.value);
    };
    const handleLubrication = (event) => {
        setLubricationValue(event.target.value);
        setLubricationValueId(event.target.value);
    };
    const handleAuxiliaryContactBlock = (event) => {
        setAuxiliaryContactBlockValue(event.target.value);
        setAuxiliaryContactBlockValueId(event.target.value);
    };
    const handleSpringChargingManual = (event) => {
        setSpringChargingManualValue(event.target.value);
        setSpringChargingManualValueId(event.target.value);
    };
    const handleSpringChargingMotor = (event) => {
        setSpringChargingMotorValue(event.target.value);
        setSpringChargingMotorValueId(event.target.value);
    };
    const handleUnderVoltage = (event) => {
        setUnderVoltageValue(event.target.value);
        setUnderVoltageValueId(event.target.value);
    };
    const handleClosingCoil = (event) => {
        setClosingCoilValue(event.target.value);
        setClosingCoilValueId(event.target.value);
    };
    const handleShuntCoil = (event) => {
        setShuntCoilValue(event.target.value);
        setShuntCoilValueId(event.target.value);
    };
    const handleReadyToCloseInterlock = (event) => {
        setReadyToCloseInterlockValue(event.target.value);
        setReadyToCloseInterlockValueId(event.target.value);

    };
    const handleBreakerOperationsManual = (event) => {
        setBreakerOperationsManualValue(event.target.value);
        setBreakerOperationsManualValueId(event.target.value);

    };
    const handleBreakerOperationsElectrical = (event) => {
        setBreakerOperationsElectricalValue(event.target.value);
        setBreakerOperationsElectricalValueId(event.target.value);

    };




    const [yearString, setYearString] = useState("");
    //onchanges
    const handleChangeCustomerPo = e => {
        setCustomerPo(e.target.value)
    }

    const handleChangeOurRef = e => {
        setOurRef(e.target.value)
    }
    const handleChangeCustomer = e => {
        setCustomer(e.target.value)
    }
    const handleChangeLocation = e => {
        setLocation(e.target.value)
    }
    const handleChangeContactPerson = e => {
        setContactPerson(e.target.value)
    }
    const handleChangeMobile = e => {
        setMobile(e.target.value)
    }
    const handleChangeEMail = e => {
        setEMail(e.target.value)
    }
    const handleChangePanel = e => {
        setPanel(e.target.value)
    }
    const handleChangeFeederName = e => {
        setFeederName(e.target.value)
    }
    const handleChangeMlfb = e => {
        setMlfb(e.target.value)
    }
    const handleChangeZOptions = e => {
        setZOptions(e.target.value)
    }
    const handleChangeIdNo = e => {
        setIdNo(e.target.value)
    }
    const handleChangeBreakerRating = e => {
        setBreakerRating(e.target.value)
    }
    const handleChangeSize = e => {
        setSize(e.target.value)
    }
    const handleChangePole = e => {
        setPole(e.target.value)
    }
    const handleChangeBreaker = e => {
        setBreaker(e.target.value)
    }
    const handleChangeEtu = e => {
        setEtu(e.target.value)
    }
    const handleChangeEtuSerialNo = e => {
        setEtuSerialNo(e.target.value)
    }
    const handleChangeI = e => {
        setI(e.target.value)


    }
    const handleChangeITrippingIr = e => {
        setITrippingIr(e.target.value)
    }
    const handleChangeLongTimeCurrent = e => {
        setLongTimeCurrent(e.target.value)
    }
    const handleChangeShortTimeIsd = e => {
        setShortTimeIsd(e.target.value)
    }
    const handleChangeShortCurrentTimeIsd = e => {
        setShortTimeCurrentIsd(e.target.value)
    }
    const handleChangeITrippingCurrent = e => {
        setITrippingCurrent(e.target.value)
    }
    const handleChangeManadatorySpares = e => {
        if (e.target.value.length <= 460) {
            setManadatorySpares(e.target.value)
        }
        
    }
    const handleChangeRecommendedSpares = e => {
        if (e.target.value.length <= 460) {
            setRecommendedSpares(e.target.value)
        }
    }
    const handleChangeComments = e => {
        if (e.target.value.length <= 500) {
            setComments(e.target.value)
        }
    }
    const handleChangeOpenPoints = e => {
        if (e.target.value.length <= 460) {
            setOpenPoints(e.target.value)
        }
    }
    const handleChangeOverAllBreakerHealthines = e => {
        setOverAllBreakerHealthines(e.target.value)
    }
    const handleChangeTestedBy = e => {
        setTestedBy(e.target.value)
    }
    const handleChangeContactNo1 = e => {
        setContactNo1(e.target.value)
    }
    const handleChangeReviewedBy = e => {
        setReviewedBy(e.target.value)
    }
    function convertDateFormat(selData) {
        var dateStr =
            selData.getFullYear() + "-" +
            ("00" + (selData.getMonth() + 1)).slice(-2) + "-" +
            ("00" + selData.getDate()).slice(-2) + " " +
            ("00" + selData.getHours()).slice(-2) + ":" +
            ("00" + selData.getMinutes()).slice(-2) + ":" +
            ("00" + selData.getSeconds()).slice(-2);

        return dateStr;
    }
    function user(value) {
        if (value != null && value != undefined) {
            const dateStr = new Date(value);
            const options = {
                year: "numeric",
                month: "numeric",
                day: "numeric"
                //day: '2-digit',
                //month: '2-digit',
                //year: 'numeric' 
                //hour: "numeric",
                //minute: "numeric",
                //second: "numeric",
                //hour12: true,
            };
            const retDateStr = dateStr.toLocaleDateString("en-US", options);
            return retDateStr;
        }

    }
    
    const handleChangeContactNo2 = e => {
        setContactNo2(e.target.value)
    }
    const handleLastService = (date) => {
        date.toISOString();
        setLastService(date)
    }
    const handleManufacture = (date) => {
        date.toISOString();
        setManufacture(date)
    }

    const handleToPeriod = (date) => {
        //const rt = convertDateFormat(date);
        // const rt= user(date)
        date.toISOString();
       setFromPeriod(date);
    };




    const columns = [
        { title: "sl_no", field: "machine_No" },
        { title: "customer_po", field: "client" },
        { title: "our_ref", field: "purchase_Order" },
        { title: "date", field: "pO_Item" },
        { title: "service", field: "efficiency_Class" },
        { title: "customer", field: "sales_Order" },
        { title: "location", field: "sales_Order_item" },
        { title: "contact_person", field: "product_Code" },
        { title: "mobile", field: "output_KW" },
        { title: "e_mail", field: "current_A" },
        { title: "panel", field: "voltage_V" },
        { title: "feeder_name", field: "tolerance_Of_Voltage%" },
        { title: "model", field: "speed_RPM" },
        { title: "mlfb", field: "frequency_Hz" },
        { title: "z_options", field: "tolerance_Of_Frequency%" },
        { title: "id_no", field: "efficiency%" },
        { title: "breaker_rating", field: "power_Factor" },
        { title: "size", field: "connection" },
        { title: "pole", field: "protection" },
        { title: "breaker", field: "duty" },
        { title: "etu", field: "insulation_Class" },
        { title: "etu_serial_no", field: "frame_Size" },
        { title: "last_serviced_on", field: "ambient_Temp." },
        { title: "in", field: "ref.std." },
        { title: "l_tripping_ir", field: "direction" },
        { title: "long_time_current", field: "dE_Bearing" },
        { title: "l_time_lag_tr", field: "ndE_Bearing" },
        { title: "memory", field: "construction" },
        { title: "short_time_isd", field: "t.Box_Position" },
        { title: "short_time_current_isd", field: "motor_Type" },
        { title: "short_time_delay_tsd", field: "y82_/Aux_Plate" },
        { title: "i_tripping_ii", field: "vfd" },
        { title: "i_tripping_current_ii", field: "btd" },
        { title: "n_tripping_in", field: "ach" },
        { title: "i_n", field: "ptc" },
        { title: "g_ct", field: "rtd" },
        { title: "g_tripping_ig", field: "z_Code" },
        { title: "g_alarm_ig", field: "polarity" },
        { title: "time_delay_tg_trip", field: "connection2" },
        { title: "etu_status", field: "avg.Res." },
        { title: "ct_test", field: "amb.Temp." },
    ];

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertClose = () => {
        setSelectedFile(null);
        setOpenAlertDialog(false);
        setIsLoading(false);
    };
    const [alertMessage, setAlertMessage] = useState("");
    const handleAlertOpen = (message) => {
        setOpenAlertDialog(true);
        setAlertMessage(message);
    };
    const handleErrorClose = () => {
        setOpenErrorDialog(false);
    };

    const sample1 = [
        { macNo: 1256, place: "Chennai" },
        { macNo: 12567, place: "Delhi" },
    ];

    function short() {
        console.log("hi");
        setIsLoader(true);

        getShort(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //console.log(response);
                setShortTimeDelayTsd(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function iTripping() {
        console.log("hi");
        setIsLoader(true);

        getItripping(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setTripping(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function nTripping() {
        console.log("hi");
        setIsLoader(true);

        getNtripping(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setNTrippingIn(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function gc() {
        console.log("hi");
        setIsLoader(true);

        getGct(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //console.log(response);
                setGct(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function gTripping() {
        console.log("hi");
        setIsLoader(true);

        getGtripping(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setGTrippingIg(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function gAlarm() {
        console.log("hi");
        setIsLoader(true);

        getGalarm(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setGAlaramIg(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    console.log(serviceId);
    function timeDelay() {
        console.log("hi");
        setIsLoader(true);

        getTimeDelay(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,)
            .then((response) => {
                //console.log(response);
                setTimeDelayTgTrip(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function status() {
        console.log("hi");
        setIsLoader(true);

        getEtuStatus(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,)
            .then((response) => {
                // console.log(response);
                setEtuStatus(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function test() {
        console.log("hi");
        setIsLoader(true);

        getCtTest(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setCtTest(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function unit() {
        console.log("hi");
        setIsLoader(true);

        getTripUnit(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //console.log(response);
                setTripUnit(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function funcTest() {
        console.log("hi");
        setIsLoader(true);

        getFunctionTest(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setFunctionTest(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function contErosion() {
        console.log("hi");
        setIsLoader(true);

        getContactErosion(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //console.log(response);
                setContactErosionIndicator(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function arc() {
        console.log("hi");
        setIsLoader(true);

        getArc(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //console.log(response);
                setArcChutes(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function lamination() {
        console.log("hi");
        setIsLoader(true);

        getLamination(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //console.log(response);
                setLaminationContacts(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function guide() {
        console.log("hi");
        setIsLoader(true);

        getGuideFrame(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setGuideFrameTerminals(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function press() {
        console.log("hi");
        setIsLoader(true);

        getContactPressure(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setContactPressure(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function pol() {
        // console.log("hi");
        setIsLoader(true);

        getPoleSet(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setPoleSet(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function lubri() {
        // console.log("hi");
        setIsLoader(true);

        getLubrication(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setLubrication(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function confirm() {
        // console.log("hi");
        setIsLoader(true);

        getConfirmation(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setShutter(response);
                setFront(response);
                setMechanicalReclosingLogout(response);
                setrackingHandle(response);
                setRackingmechanism(response);
                setSpringChargingManual(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function coil() {
        console.log("hi");
        setIsLoader(true);

        getCoil(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setClosingCoil(response);
                setShuntCoil(response);

                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function volt() {
        console.log("hi");
        setIsLoader(true);

        getVoltage(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setUnderVoltage(response);


                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function ready() {
        console.log("hi");
        setIsLoader(true);

        getReady(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setReadyToCloseInterlock(response);


                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function breakers() {
        console.log("hi");
        setIsLoader(true);

        getBreaker(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {

                setbreakerOperationsElectrical(response);
                setBreakerOperationsManual(response);


                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function spring() {
        console.log("hi");
        setIsLoader(true);

        getSpring(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {

                setSpringChargingMotor(response);


                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function time() {
        console.log("hi");
        setIsLoader(true);

        getTime(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);
                setTimeLagTr(response);


                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function serv() {
        console.log("hello");
        setIsLoader(true);

        getService(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setService(response);


                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function mech() {
        console.log("hello");
        setIsLoader(true);

        getMech(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                // console.log(response);
                setMechanicalInterLock(response);


                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function mem() {
        console.log("hi");
        setIsLoader(true);

        getMemory(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //console.log(response);
                setMemory(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function mod() {
        console.log("hi");
        setIsLoader(true);

        getModel(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                //console.log(response);
                setModel(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function intt() {
        console.log("hi");
        setIsLoader(true);

        getInt(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);
                setInt(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function auxi() {
        console.log("hi");
        setIsLoader(true);

        getAux(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);
                console.log("hi2");
                setAux(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function backClick() {
        props.clickgoback(null);
    }
    async function setState(rowData) {
        console.log(rowData);
        setCustomerPo(rowData.cutomerPo);
        setOurRef(rowData.ourRef);
        setFromPeriod(rowData.date);
        setServiceValue(rowData.serviceId);
        setCustomer(rowData.customer);
        setLocation(rowData.location);
        setContactPerson(rowData.contactPerson);
        setMobile(rowData.mobile);
        setEMail(rowData.eMail);
        setPanel(rowData.panel);
        setFeederName(rowData.feederName);
        setModelValue(rowData.modelId);
        setMlfb(rowData.mlfb);
        setZOptions(rowData.zOptions);
        setIdNo(rowData.idNo);
        setBreakerRating(rowData.breakerRating);
        setSize(rowData.size);
        setPole(rowData.pole);
        setBreaker(rowData.breaker);
        setEtu(rowData.etu);
        setEtuSerialNo(rowData.etuSerialNo);
        setLastService(rowData.lastServicedOn);
        setManufacture(rowData.manufacturedon);
        setI(rowData.in);
        setITrippingIr(rowData.lTrippingIr);
        setLongTimeCurrent(rowData.longTimeCurrent);
        setMemoryValue(rowData.memoryId);
        setTimeLagTrValue(rowData.ltimelagtrId)
        setShortTimeIsd(rowData.shortTimeIsd);
        setShortTimeCurrentIsd(rowData.shortTimeCurrentIsd);
        setShortTimeDelayTsdValue(rowData.shorttimedelaytsdId);

        setTrippingValue(rowData.itrippingiiId);
        setNTrippingInValue(rowData.ntrippinginId);
        setIntValue(rowData.in1Id);
        setGctValue(rowData.gctId);
        setGTrippingIgValue(rowData.gtrippingIgId);
        setGAlaramIgValue(rowData.galarmIgId);
        setTimeDelayTgTripValue(rowData.timeDelayTgTripId);
        setEtuStatusValue(rowData.etuStatusId);
        setCtTestValue(rowData.ctTestId);
        setFunctionTestValue(rowData.functionTestId);
        setMechanicalReclosingLogoutValue(rowData.mechanicalReclosingLockoutId);
        setRackingMechanismValue(rowData.rackingMechanismId);
        setTripUnitValue(rowData.tripUnitId);
        setMechanicalINterLockValue(rowData.mechanicalInterlockId);
        setRackingHandleValue(rowData.rackingHandleId);
        setContactErosionIndicatorValue(rowData.contactErosionIndicatorId);
        setArcChutesValue(rowData.arcChutesId);
        setShutterValue(rowData.shutterId);
        setFrontValue(rowData.frontfaciaId);
        setLaminationContactsValue(rowData.laminationContactsId);
        setGuideFrameTerminalsValue(rowData.guideFrameTerminalsId);
        setPoleSetValue(rowData.poleSetId);
        setContactPressureValue(rowData.contactPressureId);
        setLubricationValue(rowData.lubricationId);
        setAuxValue(rowData.auxiliaryContactBlock);
        setSpringChargingManualValue(rowData.springChargingManualId);
        setSpringChargingMotorValue(rowData.springChargingMotor);
        setUnderVoltageValue(rowData.undervoltage);
        setClosingCoilValue(rowData.closingCoil);
        setShuntCoilValue(rowData.shuntCoil);
        setReadyToCloseInterlockValue(rowData.readyToCloseInterlockId);
        setBreakerOperationsElectricalValue(rowData.breakerOperationsElectricalId);
        setBreakerOperationsManualValue(rowData.breakerOperationsManualId);
        setManadatorySpares(rowData.mandatorySpares);
        setRecommendedSpares(rowData.recommendedSpares);
        setComments(rowData.comments);
        setOpenPoints(rowData.openPoints);
        setTestedBy(rowData.testedBy);
        setReviewedBy(rowData.reviewedBy);
        setContactNo1(rowData.contactNo1);
        setContactNo2(rowData.contactNo2);
        setOverAllBreakerHealthines(rowData.overallBreakerHealthiness);
        setITrippingCurrent(rowData.iTrippingCurrentIi);

        
    }
    function clear() {

        setCustomerPo("");
        setOurRef("");
        setFromPeriod(null);
        setServiceValue(null);
        setCustomer("");
        setLocation("");
        setContactPerson("");
        setMobile(0);
        setEMail("");
        setPanel("");
        setFeederName("");
        setModelValue(null);
        setMlfb("");
        setZOptions("");
        setIdNo("");
        setBreakerRating(0);
        setSize(0);
        setPole("");
        setBreaker("");
        setEtu("");
        setEtuSerialNo("");
        setLastService(null);
        setManufacture(null);
        setI(0);
        setITrippingIr(0);
        setLongTimeCurrent(0);
        setMemoryValue(null);
        setTimeLagTrValue(null);
        setShortTimeIsd(0);
        setShortTimeCurrentIsd(0);
        setShortTimeDelayTsdValue(null);

        setTrippingValue(null);
        setNTrippingInValue(null);
        setIntValue(null);
        setGctValue(null);
        setGTrippingIgValue(null);
        setGAlaramIgValue(null);
        setTimeDelayTgTripValue(null);
        setEtuStatusValue(null);
        setCtTestValue(null);
        setFunctionTestValue(null);
        setMechanicalReclosingLogoutValue(null);
        setRackingMechanismValue(null);
        setTripUnitValue(null);
        setMechanicalINterLockValue(null);
        setRackingHandleValue(null);
        setContactErosionIndicatorValue(null);
        setArcChutesValue(null);
        setShutterValue(null);
        setFrontValue(null);
        setLaminationContactsValue(null);
        setGuideFrameTerminalsValue(null);
        setPoleSetValue(null);
        setContactPressureValue(null);
        setLubricationValue(null);
        setAuxValue("");
        setSpringChargingManualValue(null);
        setSpringChargingMotorValue("");
        setUnderVoltageValue("");
        setClosingCoilValue("");
        setShuntCoilValue("");
        setReadyToCloseInterlockValue(null);
        setBreakerOperationsElectricalValue(null);
        setBreakerOperationsManualValue(null);
        setManadatorySpares("");
        setRecommendedSpares("");
        setComments("");
        setOpenPoints("");
        setTestedBy("");
        setReviewedBy("");
        setContactNo1(0);
        setContactNo2(0);
        setOverAllBreakerHealthines(0);
        setITrippingCurrent("");






    }


    function submit() {
        setIsLoader(true);

        if (serviceValue === "" || serviceValue === null || serviceValue === undefined) {
            handleSnackOpen("Service is Empty", "error");
            return;
        }
        if (customerPo === "" || customerPo === null || customerPo === undefined) {
            handleSnackOpen("customerPo is Empty", "error");
            return;
        }
        if (ourRef === "" || ourRef === null || ourRef === undefined) {
            handleSnackOpen("ourRef is Empty", "error");
            return;
        }
        if (customer === "" || customer === null || customer === undefined) {
            handleSnackOpen("customer is Empty", "error");
            return;
        }
        if (location === "" || location === null || location === undefined) {
            handleSnackOpen("location is Empty", "error");
            return;
        }
        if (eMail === "" || eMail === null || eMail === undefined) {
            handleSnackOpen("email is Empty", "error");
            return;
        }
        if (panel === "" || panel === null || panel === undefined) {
            handleSnackOpen("panel is Empty", "error");
            return;
        }
        if (feederName === "" || feederName === null || feederName === undefined) {
            handleSnackOpen("feedername is Empty", "error");
            return;
        }
        if (model === "" || model === null || model === undefined) {
            handleSnackOpen("model is Empty", "error");
            return;
        }
        if (mlfb === "" || mlfb === null || mlfb === undefined) {
            handleSnackOpen("mlfb is Empty", "error");
            return;
        }
        if (idNo === "" || idNo === null || idNo === undefined) {
            handleSnackOpen("idno is Empty", "error");
            return;
        }
        if (i === "" || i === null || i === undefined) {
            handleSnackOpen("i is Empty", "error");
            return;
        }
        if (ITrippingIr === "" || ITrippingIr === null || ITrippingIr === undefined) {
            handleSnackOpen("ITrippingIr is Empty", "error");
            return;
        }
        if (shortTimeIsd === "" || shortTimeIsd === null || shortTimeIsd === undefined) {
            handleSnackOpen("ShortTimeIsd is Empty", "error");
            return;
        }
        if (iTrippingcurrent === "" || iTrippingcurrent === null || iTrippingcurrent === undefined) {
            handleSnackOpen("iTrippingCurrentIi is Empty", "error");
            return;
        }
        if (manadatorySpares === "" || manadatorySpares === null || manadatorySpares === undefined) {
            handleSnackOpen("mandatory spares is Empty", "error");
            return;
        }
        if (recommendedSpares === "" || recommendedSpares === null || recommendedSpares === undefined) {
            handleSnackOpen("recommended spares is Empty", "error");
            return;
        }
        if (openPoints === "" || openPoints === null || openPoints === undefined) {
            handleSnackOpen("open points is Empty", "error");
            return;
        }
        if (testedBy === "" || testedBy === null || testedBy === undefined) {
            handleSnackOpen("testedby is Empty", "error");
            return;
        }
        if (reviewedBy === "" || reviewedBy === null || reviewedBy === undefined) {
            handleSnackOpen("reviewedby is Empty", "error");
            return;
        }
        if (contactNo1 === "" || contactNo1 === null || contactNo1 === undefined) {
            handleSnackOpen("contactNo1 is Empty", "error");
            return;
        }
        if (contactNo2 === "" || contactNo2 === null || contactNo2 === undefined) {
            handleSnackOpen("contactNo2 is Empty", "error");
            return;
        }

        if (shortTimeDelayTsdValue === "" || shortTimeDelayTsdValue=== null || shortTimeDelayTsdValue === undefined) {
            handleSnackOpen("short time delay tsd is Empty", "error");
            return;
        }
        if (trippingValue === "" || trippingValue === null || trippingValue === undefined) {
            handleSnackOpen("itrippingii is Empty", "error");
            return;
        }
        if (nTrippingInValue === "" || nTrippingInValue === null || nTrippingInValue === undefined) {
            handleSnackOpen("ntrippingin is Empty", "error");
            return;
        }
        if (int === "" || int === null || int === undefined) {
            handleSnackOpen("i_n is Empty", "error");
            return;
        }
        if (gctValue === "" || gctValue === null || gctValue === undefined) {
            handleSnackOpen("gct is Empty", "error");
            return;
        }
        if (gTrippingIgValue === "" || gTrippingIgValue === null || gTrippingIgValue === undefined) {
            handleSnackOpen("gtrippingig is Empty", "error");
            return;
        }
        if (gAlarmIgValue === "" || gAlarmIgValue === null || gAlarmIgValue === undefined) {
            handleSnackOpen("galarm is Empty", "error");
            return;
        }
        if (timeDelayTgTripValue === "" || timeDelayTgTripValue === null || timeDelayTgTripValue === undefined) {
            handleSnackOpen("time delay tg trip is Empty", "error");
            return;
        }
        if (etuStatusValue === "" || etuStatusValue === null || etuStatusValue === undefined) {
            handleSnackOpen("etuStatus is Empty", "error");
            return;
        }
        if (ctTestValue === "" || ctTestValue === null || ctTestValue=== undefined) {
            handleSnackOpen("ctTest is Empty", "error");
            return;
        }

        if (functionTestValue === "" || functionTestValue === null || functionTestValue === undefined) {
            handleSnackOpen("functiontest is Empty", "error");
            return;
        }
        if (mechanicalReclosingLogoutValue === "" || mechanicalReclosingLogoutValue === null || mechanicalReclosingLogoutValue === undefined) {
            handleSnackOpen("mechanical reclosing logout is Empty", "error");
            return;
        }

        if (tripUnitValue === "" || tripUnitValue === null || tripUnitValue=== undefined) {
            handleSnackOpen("tripunit is Empty", "error");
            return;
        }
        if (mechanicalInterLockValue === "" || mechanicalInterLockValue === null || mechanicalInterLockValue === undefined) {
            handleSnackOpen("mechanicalinterlock is Empty", "error");
            return;
        }
        if (RackingHandleValue === "" || RackingHandleValue === null || RackingHandleValue === undefined) {
            handleSnackOpen("rackinghandle is Empty", "error");
            return;
        }
        if (rackingMechanismValue === "" || rackingMechanismValue === null || rackingMechanismValue === undefined) {
            handleSnackOpen("racking mechanism is Empty", "error");
            return;
        }
        if (contactErosionIndicatorValue === "" || contactErosionIndicatorValue === null || contactErosionIndicatorValue === undefined) {
            handleSnackOpen("contacterosion is Empty", "error");
            return;
        }
        if (arcChutesValue === "" || arcChutesValue === null || arcChutesValue=== undefined) {
            handleSnackOpen("arcChutes is Empty", "error");
            return;
        }

        if (shutterValue === "" || shutterValue === null || shutterValue === undefined) {
            handleSnackOpen("shutter is Empty", "error");
            return;
        }
        if (laminationContactsValue=== "" || laminationContactsValue === null || laminationContactsValue === undefined) {
            handleSnackOpen("lamination contacts is Empty", "error");
            return;
        }

        if (guideFrameTerminalsValue === "" || guideFrameTerminalsValue === null || guideFrameTerminalsValue === undefined) {
            handleSnackOpen("guide frame is Empty", "error");
            return;
        }
        if (contactPressureValue === "" || contactPressureValue=== null || contactPressureValue === undefined) {
            handleSnackOpen("contact pressure is Empty", "error");
            return;
        }
        if (poleSetValue === "" || poleSetValue === null || poleSetValue
            === undefined) {
            handleSnackOpen("poleset is Empty", "error");
            return;

        }
        if (springChargingManualValue === "" || springChargingManualValue === null || springChargingManualValue === undefined) {
            handleSnackOpen("spring charging manual is Empty", "error");
            return;
        }
        if (springChargingMotorValue === "" || springChargingMotorValue=== null || springChargingMotorValue=== undefined) {
            handleSnackOpen("spring charging motor is Empty", "error");
            return;
        }

        if (underVoltageValue=== "" || underVoltageValue === null || underVoltageValue === undefined) {
            handleSnackOpen("undervoltage is Empty", "error");
            return;
        }

        if (closingCoilValue === "" || closingCoilValue === null || closingCoilValue === undefined) {
            handleSnackOpen("closingcoil is Empty", "error");
            return;
        }
        if (shuntCoilValue === "" || shuntCoilValue === null || shuntCoilValue === undefined) {
            handleSnackOpen("shunt coil is Empty", "error");
            return;
        }
        if (readyToCloseInterlockValue=== "" || readyToCloseInterlockValue=== null || readyToCloseInterlockValue === undefined) {
            handleSnackOpen("readytochoseinterlock is Empty", "error");
            return;
        }
        if (breakerOperationsManualValue === "" || breakerOperationsManualValue=== null || breakerOperationsManualValue === undefined) {
            handleSnackOpen("breakermanual is Empty", "error");
            return;
        }
        if (breakerOperationsElectricalValue === "" || breakerOperationsElectricalValue=== null || breakerOperationsElectricalValue === undefined) {
            handleSnackOpen("breakerelectrical is Empty", "error");
            return;
        }
        if (auxValue === "" || auxValue === null || auxValue === undefined) {
            handleSnackOpen("auxiliary is Empty", "error");
            return;
        }

       
        let slipData = {
            CutomerPo: customerPo,
            OurRef: ourRef,
            date: fromPeriod,
            // ServiceId: serviceId,
            ServiceId: serviceValue,
            Service: "null",
            Customer: customer,
            Location: location,
            ContactPerson: contactPerson,
            Mobile: mobile,
            EMail: eMail,
            Panel: panel,
            FeederName: feederName,
            // modelId: modelId,
            modelId: modelValue,
            model: "",
            Mlfb: mlfb,
            ZOptions: zOptions,
            IdNo: idNo,
            BreakerRating: breakerRating,
            Size: size,
            Pole: pole,
            Breaker: breaker,
            Etu: etu,
            EtuSerialNo: etuSerialNo,
            lastServicedOn: lastService,
            In: i,
            LTrippingIr: ITrippingIr,
            //LongTimeCurrent: longId,
            LongTimeCurrent: longId,
            LtimelagtrId: timeLagTrValueId,
            //LtimelagtrId: timeDelayTgTripValue,
            LTimeLagTr: "null",
            //memoryId: memoryId,
            memoryId: memoryValue,
            memory: "null",
            ShortTimeIsd: shortTimeIsd,
            ShortTimeCurrentIsd: shortId,
            //ShorttimedelaytsdId: shortTimeDelayTsdValueId,
            ShorttimedelaytsdId: shortTimeDelayTsdValue,
            ShortTimeDelayTsd: "null",
            //ItrippingiiId: trippingValueId,
            ItrippingiiId: trippingValue,
            ITrippingIi: "null",
            iTrippingCurrentIi: iTrippingcurrent,
            //NtrippinginId: nTrippingInValueId,
            NtrippinginId: nTrippingInValue,
            NTrippingIn: "null",
            //in1Id: intId,
            in1Id: intValue,
            iN1: "null",
            //GctId: gctValueId,
            GctId: gctValue,
            GCt: "null",
            //GtrippingIgId: gTrippingIgValueId,
            GtrippingIgId: gTrippingIgValue,
            GTrippingIg: "null",
            GalarmIgId: gAlarmIgValue,
            GAlarmIg: "null",
            TimeDelayTgTripId: timeDelayTgTripValue,
            TimeDelayTgTrip: "null",
            EtuStatusId: etuStatusValue,
            EtuStatus: "null",
            CtTestId: ctTestValue,
            CtTest: "null",
            FunctionTestId: functionTestValue,
            FunctionTest: "null",
            MechanicalReclosingLockoutId: mechanicalReclosingLogoutValue,
            MechanicalReclosingLockout: "null",
            TripUnitId: tripUnitValue,
            TripUnit: "null",
            MechanicalInterlockId: mechanicalInterLockValue,
            MechanicalInterlock: "null",
            RackingHandleId: RackingHandleValue,
            RackingHandle: "null",
            RackingMechanismId: rackingMechanismValue,
            RackingMechanism: "null",
            ContactErosionIndicatorId: contactErosionIndicatorValue,
            ContactErosionIndicator: "null",
            ArcChutesId: arcChutesValue,
            ArcChutes: "null",
            ShutterId: shutterValue,
            FrontfaciaId:frontValue,
            Shutter: "null",
            LaminationContactsId: laminationContactsValue,
            LaminationContacts: "null",
            GuideFrameTerminalsId: guideFrameTerminalsValue,
            GuideFrameTerminals: "null",
            ContactPressureId: contactPressureValue,
            ContactPressure: "null",
            PoleSetId: poleSetValue,
            PoleSet: "null",
            LubricationId: lubricationValue,
            Lubrication: "null",
            AuxiliaryContactBlockId: 0,
            AuxiliaryContactBlock: auxValue,
            SpringChargingManualId: springChargingManualValue,
            SpringChargingManual: "null",
            SpringChargingMotorId: 0,
            SpringChargingMotor: springChargingMotorValue,
            UnderVoltageId: 0,
            Undervoltage: underVoltageValue, 
            ClosingCoilId:0,
            ClosingCoil: closingCoilValue,
            ShuntCoilId:0,
            ShuntCoil: shuntCoilValue,
            ReadyToCloseInterlockId: readyToCloseInterlockValue,
            ReadyToCloseInterlock: "null",
            BreakerOperationsManualId: breakerOperationsManualValue,
            BreakerOperationsManual: "null",
            BreakerOperationsElectricalId: breakerOperationsElectricalValue,
            BreakerOperationsElectrical: "null",
            MandatorySpares: manadatorySpares,
            RecommendedSpares: recommendedSpares,
            Comments: comments,
            OpenPoints: openPoints,
            OverallBreakerHealthiness: overAllBreakerHealthines,
            TestedBy: testedBy,
            ContactNo1: contactNo1,
            ReviewedBy: reviewedBy,
            ContactNo2: contactNo2,
            Manufacturedon: manufacture,
            // "isActive": true,

            CreatedBy: userDetails.userId,
            ModifiedBy: userDetails.userId,


            //"createdDate": "2023-03-20T18:16:24.85"
        }
        console.log("slipData", slipData);
        //let errorList = [];
        //await api.post("/PostQuotationData/", slipData) 
        getSub(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, slipData)
            .then(response => {
                setIsLoader(false);
                console.log("savedata", response);
                //handleSnackOpen(
                //    "Insert done Successfully: ",

                //    "success"
                //);
                //handleSnackOpen(
                //    response.responseMsg,

                //    "success"
                //);
               // props.clickgoback(null);
                clear();
                handleSnackOpen(
                    response.responseMsg,

                    "success"
                );
                //props.clickgoback();
            })
            .catch(error => {
                handleSnackOpen("Error in hitting Api", "error");
                
                setIsLoader(false);
            })

    }

    return (
        <>
            <div className="main-block">
                <Grid xs={12} className="textCenter">
                    <h3>Update Quotation Data</h3>
                </Grid>
                <Grid container className="complaint-id" >

                    <Grid className="mt-10">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={7} md={10} className="gridMargin" >
                                <h3>Customer Details</h3>
                            </Grid>
                            <Grid container className='separator' spacing={2} >
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            customer
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={customer}
                                            onChange={handleChangeCustomer}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            contact-person
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={contactPerson}
                                            onChange={handleChangeContactPerson}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            mobile
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={mobile}
                                            onChange={handleChangeMobile}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            e-mail
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={eMail}
                                            onChange={handleChangeEMail}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            location
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={location}
                                            onChange={handleChangeLocation}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            customer_po
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={customerPo}
                                            onChange={handleChangeCustomerPo}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            our_ref
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={ourRef}
                                            onChange={handleChangeOurRef}

                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id="toPeriod"
                                            className="datepicker"
                                            label="date"
                                           // format="dd/MM/yyyy"
                                            format="MM/dd/yyyy"
                                            placeholder="MM/dd/yyyy"
                                            fullWidth
                                            //maxDate={new Date()}
                                            value={fromPeriod}
                                            onChange={handleToPeriod}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} sm={7} md={10} className="gridMargin">
                                <h3>Know your breaker</h3>
                            </Grid>
                            <Grid container className='separator' spacing={2} >
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        Model
                                    </InputLabel>

                                    <Select
                                        label="Model"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={modelValue}
                                        onChange={handleModel}
                                        input={<BootstrapInput />}
                                    >
                                        {model.map((item) => (
                                            <MenuItem key={item.modelId} value={item.modelId}>
                                                {item.modelName}
                                            </MenuItem>
                                        ))}

                                    </Select>


                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        mlfb
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={mlfb}
                                        onChange={handleChangeMlfb}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        z-options
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={zOptions}
                                        onChange={handleChangeZOptions}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        ID No
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={idNo}
                                        onChange={handleChangeIdNo}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        breaker-rating
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={breakerRating}
                                        onChange={handleChangeBreakerRating}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        size
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={size}
                                        onChange={handleChangeSize}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        pole
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={pole}
                                        onChange={handleChangePole}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        breaker
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={breaker}
                                        onChange={handleChangeBreaker}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        etu
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={etu}
                                        onChange={handleChangeEtu}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        panel
                                    </InputLabel>
                                    <BootstrapInput id=""
                                        name=""
                                        value={panel}
                                        onChange={handleChangePanel}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        feeder-name
                                    </InputLabel>
                                    <BootstrapInput id=""
                                        name=""
                                        value={feederName}
                                        onChange={handleChangeFeederName}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        etu_serial_no
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={etuSerialNo} onChange={handleChangeEtuSerialNo}

                                    />
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            in
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={i} onChange={handleChangeI}

                                        />
                                    </FormControl>
                                </Grid>
                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                            {/*            in*/}
                            {/*        </InputLabel>*/}
                            {/*        <BootstrapInput id="cutomer_po"*/}
                            {/*            name="cutomerPo"*/}
                            {/*            value={i} onChange={handleChangeI}*/}

                            {/*        />*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} sm={6} md={3}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        id="toPeriod"
                                        className="datepicker"
                                        label="last serviced-on"
                                            //format="dd/MM/yyyy"
                                            format="MM/dd/yyyy"
                                        placeholder="MM/dd/yyyy"
                                        fullWidth
                                       // maxDate={new Date()}
                                        value={lastService}
                                        onChange={handleLastService}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>

                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            id="toPeriod"
                                            className="datepicker"
                                            label="Manufactured on"
                                            format="dd/MM/yyyy"
                                            placeholder="MM/dd/yyyy"
                                            fullWidth
                                           // maxDate={new Date()}
                                            value={manufacture}
                                            onChange={handleManufacture}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                </Grid>
                            </Grid>
                            
                            <Grid item xs={12} sm={7} md={10} className="gridMargin">
                                <h3>These are protecting against faults</h3>
                            </Grid>
                            <Grid container className='separator' spacing={2} >
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        long_time_current
                                    </InputLabel>
                                    <BootstrapInput
                                        id="cutomer_po"
                                        name="cutomerPo"
                                        value={longId}
                                    // onChange={handleLongId}


                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        short_time_current_isd
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={shortId}
                                    // onChange={handleShortId}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        time_delay_tg_trip
                                    </InputLabel>


                                    <Select
                                        label="  time_delay_tg_trip"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={timeDelayTgTripValue}
                                        onChange={handleTimeDelayTgTrip}
                                        input={<BootstrapInput />}
                                    >
                                        {timeDelayTgTrip.map((item) => (
                                            <MenuItem key={item.timeDelayTgTripId} value={item.timeDelayTgTripId}>
                                                {item.timeDelayTgTripName}
                                            </MenuItem>
                                        ))}
                                    </Select></FormControl> </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        i_tripping_ii
                                    </InputLabel>
                                    <Select
                                        label=" i_tripping_ii"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={trippingValue}
                                        onChange={handleTripping}
                                        input={<BootstrapInput />}
                                    >
                                        {tripping.map((item) => (
                                            <MenuItem key={item.itrippingiiId} value={item.itrippingiiId}>
                                                {item.itrippingiiName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        n_tripping_in
                                    </InputLabel>
                                    <Select
                                        label="  n_tripping_in"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={nTrippingInValue}
                                        onChange={handleNTrippingIn}
                                        input={<BootstrapInput />}
                                    >
                                        {nTrippingIn.map((item) => (
                                            <MenuItem key={item.ntrippinginId} value={item.ntrippinginId}>
                                                {item.ntrippinginName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            i_n
                                        </InputLabel>
                                        <Select
                                            label="i_n"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={intValue}
                                            onChange={handleInt}
                                            input={<BootstrapInput />}
                                        >
                                            {int.map((item) => (
                                                <MenuItem key={item.in1Id} value={item.in1Id}>
                                                    {item.in1Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        g_ct
                                    </InputLabel>
                                    <Select
                                        label="i_n"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                            value={gctValue}
                                            onChange={handleGct}
                                        input={<BootstrapInput />}
                                    >
                                        {gct.map((item) => (
                                            <MenuItem key={item.gctId} value={item.gctId}>
                                                {item.gctName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        g_tripping_ig
                                    </InputLabel>
                                    <Select
                                        label="  g_tripping_ig"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={gTrippingIgValue}
                                        onChange={handleGTrippingIg}
                                        input={<BootstrapInput />}
                                    >
                                        {gTrippingIg.map((item) => (
                                            <MenuItem key={item.gtrippingIgId} value={item.gtrippingIgId}>
                                                {item.gtrippingIgName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        g_alarm_ig
                                    </InputLabel>
                                    <Select
                                        label="  g_alarm_ig"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={gAlarmIgValue}
                                        onChange={handleGAlaramIg}
                                        input={<BootstrapInput />}
                                    >
                                        {gAlaramIg.map((item) => (
                                            <MenuItem key={item.galarmIgId} value={item.galarmIgId}>
                                                {item.galarmIgName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        memory
                                    </InputLabel>
                                    <Select
                                        label="Memory"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={memoryValue}
                                        onChange={handleMemory}
                                        input={<BootstrapInput />}
                                    >
                                        {memory.map((item) => (
                                            <MenuItem key={item.memoryId} value={item.memoryId}>
                                                {item.memoryName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            l_time_lag_tr
                                        </InputLabel>
                                        <Select
                                            label=" "
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={timelagTrValue}
                                            onChange={handleTimeLagTr}
                                            input={<BootstrapInput />}
                                        >
                                            {timeLagTr.map((item) => (
                                                <MenuItem key={item.ltimelagtrId} value={item.ltimelagtrId}>
                                                    {item.ltimelagtrName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            I_tripping_ir
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={ITrippingIr}
                                            onChange={handleChangeITrippingIr}

                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            service
                                        </InputLabel>

                                        <Select

                                            label="service"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={serviceValue}
                                            onChange={handleService}
                                            input={<BootstrapInput />}
                                        >
                                            {service.map((item) => (
                                                <MenuItem key={item.serviceId} value={item.serviceId}>
                                                    {item.serviceName}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>





                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            short_time_isd
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={shortTimeIsd} onChange={handleChangeShortTimeIsd}

                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            short_time_delay_tsd
                                        </InputLabel>

                                        <Select
                                            label="short_time_delay_tsd"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={shortTimeDelayTsdValue}
                                            onChange={handleShortTimeDelayTsd}

                                            input={<BootstrapInput />}
                                        >
                                            {shortTimeDelayTsd.map((item) => (
                                                <MenuItem key={item.shorttimedelaytsdId} value={item.shorttimedelaytsdId}>
                                                    {item.shorttimedelaytsdName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            I_tripping_current_ii
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={iTrippingcurrent}
                                            onChange={handleChangeITrippingCurrent}

                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            trip_unit
                                        </InputLabel>

                                        <Select
                                            label="   trip_unit"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={tripUnitValue}
                                            onChange={handleTripUnit}
                                            input={<BootstrapInput />}
                                        >

                                            {tripUnit.map((item) => (
                                                <MenuItem key={item.tripUnitId} value={item.tripUnitId}>
                                                    {item.tripUnitName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                           
                            </Grid>
                            <Grid item xs={12} sm={7} md={10} className="gridMargin">
                                <h3>Testing breaker heart-Tripping unit</h3>
                            </Grid>
                            <Grid container className='separator' spacing={2} >
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        etu_status
                                    </InputLabel>


                                    <Select
                                        label="  etu_status"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={etuStatusValue}
                                        onChange={handleEtuStatus}
                                        input={<BootstrapInput />}
                                    >
                                        {etuStatus.map((item) => (
                                            <MenuItem key={item.etuStatusId} value={item.etuStatusId}>
                                                {item.etuStatusName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        ct_test
                                    </InputLabel>


                                    <Select
                                        label="   ct_test"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={ctTestValue}
                                        onChange={handleCtTest}
                                        input={<BootstrapInput />}
                                    >
                                        {ctTest.map((item) => (
                                            <MenuItem key={item.ctTestId} value={item.ctTestId}>
                                                {item.ctTestName}
                                            </MenuItem>
                                        ))}


                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        function_test
                                    </InputLabel>

                                    <Select
                                        label="   function_test"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={functionTestValue}
                                        onChange={handleFunctionTest}
                                        input={<BootstrapInput />}
                                    >
                                        {functionTest.map((item) => (
                                            <MenuItem key={item.functionTestId} value={item.functionTestId}>
                                                {item.functionTestName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        mechanical_reclosing_lockout
                                    </InputLabel>


                                    <Select
                                        label="   mechanical_reclosing_lockout"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={mechanicalReclosingLogoutValue}
                                        onChange={handleMechanicalReclosingLogout}
                                        input={<BootstrapInput />}
                                    >
                                        {mechanicalReclosingLogout.map((item) => (
                                            <MenuItem key={item.confirmationId} value={item.confirmationId}>
                                                {item.confirmationName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                            {/*            feeder-name*/}
                            {/*        </InputLabel>*/}
                            {/*        <BootstrapInput id=""*/}
                            {/*            name=""*/}
                            {/*            value={feederName}*/}
                            {/*            onChange={handleChangeFeederName}*/}

                            {/*        />*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                            {/*            ID No*/}
                            {/*        </InputLabel>*/}
                            {/*        <BootstrapInput id="cutomer_po"*/}
                            {/*            name="cutomerPo"*/}
                            {/*            value={idNo}*/}
                            {/*            onChange={handleChangeIdNo}*/}

                            {/*        />*/}
                            {/*    </FormControl>*/}
                            {/*    </Grid>*/}
                            </Grid>
                            <Grid item xs={12} sm={7} md={10} className="gridMargin">
                                <h3>It's about mechanism</h3>
                            </Grid>
                            <Grid container className='separator' spacing={2} >
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        mechanical_interlock
                                    </InputLabel>


                                    <Select
                                        label="  mechanical_interlock"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={mechanicalInterLockValue}
                                        onChange={handleMechanicalINterLock}
                                        input={<BootstrapInput />}
                                    >
                                        {mechanicalInterLock.map((item) => (
                                            <MenuItem key={item.mechanicalInterlockId} value={item.mechanicalInterlockId}>
                                                {item.mechanicalInterlockName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        contact_erosion_indicator
                                    </InputLabel>


                                    <Select
                                        label="contact_erosion_indicator"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={contactErosionIndicatorValue}
                                        onChange={handleContactErosionIndicator}
                                        input={<BootstrapInput />}
                                    >
                                        {contactErosionIndicator.map((item) => (
                                            <MenuItem key={item.contactErosionIndicatorId} value={item.contactErosionIndicatorId}>
                                                {item.contactErosionIndicatorName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        racking_handle
                                    </InputLabel>


                                    <Select
                                        label=" racking_handle"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={RackingHandleValue}
                                        onChange={handleRackingHandle}
                                        input={<BootstrapInput />}
                                    >
                                        {rackingHandle.map((item) => (
                                            <MenuItem key={item.confirmationId} value={item.confirmationId}>
                                                {item.confirmationName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        racking_mechanism
                                    </InputLabel>

                                    <Select
                                        label=" racking_mechanism"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={rackingMechanismValue}
                                        onChange={handleRackingMechanism}
                                        input={<BootstrapInput />}
                                    >
                                        {rackingMechanism.map((item) => (
                                            <MenuItem key={item.confirmationId} value={item.confirmationId}>
                                                {item.confirmationName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                            {/*            auxiliary_contact_block*/}
                            {/*        </InputLabel>*/}

                            {/*        <Select*/}
                            {/*            label=" auxiliary_contact_block"*/}
                            {/*            id=""*/}
                            {/*            name=""*/}
                            {/*            className=""*/}
                            {/*            fullWidth*/}
                            {/*            value={auxValue}*/}
                            {/*            onChange={handleAux}*/}
                            {/*            input={<BootstrapInput />}*/}
                            {/*        >*/}
                            {/*            {aux.map((item) => (*/}
                            {/*                <MenuItem key={item.auxiliaryContactBlockId} value={item.auxiliaryContactBlockId}>*/}
                            {/*                    {item.auxiliaryContactBlockName}*/}
                            {/*                </MenuItem>*/}
                            {/*            ))}*/}
                            {/*        </Select>*/}
                            {/*    </FormControl>*/}
                            {/*    </Grid>*/}
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            auxiliary_contact_block
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={auxValue}
                                            onChange={handleAux}
                                            
                                        />
                                    </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        lamination_contacts
                                    </InputLabel>

                                    <Select
                                        label=" lamination_contacts"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={laminationContactsValue}
                                        onChange={handleLaminationContacts}
                                        input={<BootstrapInput />}
                                    >
                                        {laminationContacts.map((item) => (
                                            <MenuItem key={item.laminationContactsId} value={item.laminationContactsId}>
                                                {item.laminationContactsName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        pole_set
                                    </InputLabel>

                                    <Select
                                        label="pole_set"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={poleSetValue}
                                        onChange={handlePoleSet}
                                        input={<BootstrapInput />}
                                    >
                                        {poleSet.map((item) => (
                                            <MenuItem key={item.poleSetId} value={item.poleSetId}>
                                                {item.poleSetName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        lubrication
                                    </InputLabel>

                                    <Select
                                        label=" lubrication"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={lubricationValue}
                                        onChange={handleLubrication}
                                        input={<BootstrapInput />}
                                    >
                                        {lubrication.map((item) => (
                                            <MenuItem key={item.lubricationId} value={item.lubricationId}>
                                                {item.lubricationName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        contact_pressure
                                    </InputLabel>

                                    <Select
                                        label="contact_pressure"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={contactPressureValue}
                                        onChange={handleContactPressure}
                                        input={<BootstrapInput />}
                                    >
                                        {contactPressure.map((item) => (
                                            <MenuItem key={item.contactPressureId} value={item.contactPressureId}>
                                                {item.contactPressureName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        arc_chutes
                                    </InputLabel>


                                    <Select
                                        label=" arc_chutes"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={arcChutesValue}
                                        onChange={handleArcChutes}
                                        input={<BootstrapInput />}
                                    >
                                        {arcChutes.map((item) => (
                                            <MenuItem key={item.arcChutesId} value={item.arcChutesId}>
                                                {item.arcChutesName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        shutter
                                    </InputLabel>
                                    <Select
                                        label=" shutter"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={shutterValue}
                                        onChange={handleShutter}
                                        input={<BootstrapInput />}
                                    >
                                        {shutter.map((item) => (
                                            <MenuItem key={item.confirmationId} value={item.confirmationId}>
                                                {item.confirmationName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Front Facia
                                        </InputLabel>
                                        <Select
                                            label=" shutter"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={frontValue}
                                            onChange={handleFront}
                                            input={<BootstrapInput />}
                                        >
                                            {front.map((item) => (
                                                <MenuItem key={item.confirmationId} value={item.confirmationId}>
                                                    {item.confirmationName}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        guide_frame_terminals
                                    </InputLabel>

                                    <Select
                                        label="guide_frame_terminals"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={guideFrameTerminalsValue}
                                        onChange={handleGuideFrameTerminals}
                                        input={<BootstrapInput />}
                                    >
                                        {guideFrameTerminals.map((item) => (
                                            <MenuItem key={item.guideFrameTerminalsId} value={item.guideFrameTerminalsId}>
                                                {item.guideFrameTerminalsName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={7} md={10} className="gridMargin">
                                <h3>Final tests - Better carry in TEST Position</h3>
                            </Grid>
                            <Grid container className='separator' spacing={2} >
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        spring_charging_manual
                                    </InputLabel>

                                    <Select
                                        label=" spring_charging_manual"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={springChargingManualValue}
                                        onChange={handleSpringChargingManual}
                                        input={<BootstrapInput />}
                                    >
                                        {springChargingManual.map((item) => (
                                            <MenuItem key={item.confirmationId} value={item.confirmationId}>
                                                {item.confirmationName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                            {/*            spring_charging_motor*/}
                            {/*        </InputLabel>*/}


                            {/*        <Select*/}
                            {/*            label=" spring_charging_motor"*/}
                            {/*            id=""*/}
                            {/*            name=""*/}
                            {/*            className=""*/}
                            {/*            fullWidth*/}
                            {/*            value={springChargingMotorValue}*/}
                            {/*            onChange={handleSpringChargingMotor}*/}
                            {/*            input={<BootstrapInput />}*/}
                            {/*        >*/}
                            {/*            {springChargingMotor.map((item) => (*/}
                            {/*                <MenuItem key={item.springChargingMotorId} value={item.springChargingMotorId}>*/}
                            {/*                    {item.springChargingMotorName}*/}
                            {/*                </MenuItem>*/}
                            {/*            ))}*/}

                            {/*        </Select>*/}
                            {/*    </FormControl>*/}
                            {/*    </Grid>*/}
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            spring_charging_motor
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={springChargingMotorValue}
                                            onChange={handleSpringChargingMotor}
                                        />
                                    </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        breaker_operations_manual
                                    </InputLabel>


                                    <Select
                                        label=" breaker_operations_manual"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={breakerOperationsManualValue}
                                        onChange={handleBreakerOperationsManual}
                                        input={<BootstrapInput />}
                                    >
                                        {breakerOperationsManual.map((item) => (
                                            <MenuItem key={item.breakerOperationsId} value={item.breakerOperationsId}>
                                                {item.breakerOperationsName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        breaker_operations_electrical
                                    </InputLabel>

                                    <Select
                                        label=" breaker_operations_electrical"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={breakerOperationsElectricalValue}
                                        onChange={handleBreakerOperationsElectrical}
                                        input={<BootstrapInput />}

                                    >
                                        {breakerOperationsElectrical.map((item) => (
                                            <MenuItem key={item.breakerOperationsId} value={item.breakerOperationsId}>
                                                {item.breakerOperationsName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        ready_to_close_interlock
                                    </InputLabel>


                                    <Select
                                        label="ready_to_close_interlock"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={readyToCloseInterlockValue}
                                        onChange={handleReadyToCloseInterlock}
                                        input={<BootstrapInput />}
                                    >
                                        {readyToCloseInterlock.map((item) => (
                                            <MenuItem key={item.readyToCloseInterlockId} value={item.readyToCloseInterlockId}>
                                                {item.readyToCloseInterlockName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>
                            </Grid>
                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                            {/*            closing_coil*/}
                            {/*        </InputLabel>*/}


                            {/*        <Select*/}
                            {/*            label="closing_coil"*/}
                            {/*            id=""*/}
                            {/*            name=""*/}
                            {/*            className=""*/}
                            {/*            fullWidth*/}
                            {/*            value={closingCoilValue}*/}
                            {/*            onChange={handleClosingCoil}*/}
                            {/*            input={<BootstrapInput />}*/}
                            {/*        >*/}
                            {/*            {closingCoil.map((item) => (*/}
                            {/*                <MenuItem key={item.coilId} value={item.coilId}>*/}
                            {/*                    {item.coilName}*/}
                            {/*                </MenuItem>*/}
                            {/*            ))}*/}

                            {/*        </Select>*/}
                            {/*    </FormControl>*/}
                            {/*    </Grid>*/}
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            closing_coil
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={closingCoilValue}
                                            onChange={handleClosingCoil}
                                        />
                                    </FormControl>
                                </Grid>

                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                            {/*            shunt_coil*/}
                            {/*        </InputLabel>*/}


                            {/*        <Select*/}
                            {/*            label=" shunt_coil"*/}
                            {/*            id=""*/}
                            {/*            name=""*/}
                            {/*            className=""*/}
                            {/*            fullWidth*/}
                            {/*            value={shuntCoilValue}*/}
                            {/*            onChange={handleShuntCoil}*/}
                            {/*            input={<BootstrapInput />}*/}
                            {/*        >*/}
                            {/*            {shuntCoil.map((item) => (*/}
                            {/*                <MenuItem key={item.coilId} value={item.coilId}>*/}
                            {/*                    {item.coilName}*/}
                            {/*                </MenuItem>*/}
                            {/*            ))}*/}
                            {/*        </Select>*/}
                            {/*    </FormControl>*/}
                            {/*    </Grid>*/}
                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input">
                                            shunt_coil
                                        </InputLabel>
                                        <BootstrapInput id="cutomer_po"
                                            name="cutomerPo"
                                            value={shuntCoilValue}
                                            onChange={handleShuntCoil} 

                                        />
                                    </FormControl>
                                </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        overall_breaker_healthiness
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={overAllBreakerHealthines}
                                        onChange={handleChangeOverAllBreakerHealthines}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                     mandatory_spares(max_allowed_460_characters)
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={manadatorySpares} onChange={handleChangeManadatorySpares}


                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                            comments(max_allowed_500_characters
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={comments} onChange={handleChangeComments}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                            recommended_spares(max_allowed_460_characters
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={recommendedSpares} onChange={handleChangeRecommendedSpares}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                            open_points(max_allowed_500_characters)
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={openPoints} onChange={handleChangeOpenPoints}

                                    />
                                </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={7} md={10} className="gridMargin">
                                <h3>Tested by</h3>
                            </Grid>
                            <Grid container className='separator' spacing={2} >
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        tested_by
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={testedBy} onChange={handleChangeTestedBy}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        contact_no_1
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={contactNo1} onChange={handleChangeContactNo1}

                                    />
                                </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={7} md={10} className="gridMargin">
                                <h3>Reviewed by</h3>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        reviewed_by
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo" value={reviewedBy} onChange={handleChangeReviewedBy}

                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        contact_no_2
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={contactNo2} onChange={handleChangeContactNo2}

                                    />
                                </FormControl>
                            </Grid>








                           
                           







                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                            {/*            model*/}
                            {/*        </InputLabel>*/}
                            {/*        <BootstrapInput id="cutomer_po"*/}
                            {/*            name="cutomerPo"*/}

                            {/*        />*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}
















                           

                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                            {/*            undervoltage*/}
                            {/*        </InputLabel>*/}


                            {/*        <Select*/}
                            {/*            label="undervoltage"*/}
                            {/*            id=""*/}
                            {/*            name=""*/}
                            {/*            className=""*/}
                            {/*            fullWidth*/}
                            {/*            value={underVoltageValue}*/}
                            {/*            onChange={handleUnderVoltage}*/}
                            {/*            input={<BootstrapInput />}*/}
                            {/*        >*/}
                            {/*            {underVoltage.map((item) => (*/}
                            {/*                <MenuItem key={item.underVoltageId} value={item.underVoltageId}>*/}
                            {/*                    {item.underVoltageName}*/}
                            {/*                </MenuItem>*/}
                            {/*            ))}*/}
                            {/*        </Select>*/}
                            {/*    </FormControl>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        undervoltage
                                    </InputLabel>
                                    <BootstrapInput id="cutomer_po"
                                        name="cutomerPo"
                                        value={underVoltageValue}
                                        onChange={handleUnderVoltage}

                                    />
                                </FormControl>
                            </Grid>

                        </Grid>

                    </Grid>

                </Grid>
                
                <Grid align="center" className="margonTop">
                    <Button
                        type="
                        "
                        className="pt-button--primary mt-1"
                        id="SupplierLoginBtn"
                        onClick={submit}
                    >
                        Update
                    </Button>
                   
                        <Button
                            type="submit"
                            className="pt-button--primary "
                            id="SupplierLoginBtn"
                            onClick={backClick}
                        >
                            Back
                        </Button>
                        
                    
                </Grid>
               
                {/*<Grid align="center" className="margonTop">*/}
                {/*    <Button*/}
                {/*        type="submit"*/}
                {/*        className="pt-button--primary"*/}
                {/*        id="SupplierLoginBtn"*/}
                {/*        onClick={backClick}*/}
                {/*    >*/}
                {/*        Back*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={2} className="d-flex jc-center width-100">*/}
                {/*    */}{/*<Link to={"/ModifyInventory"}>*/}
                {/*    <Button className="ns-btn-primary mt-1 mr-1" onClick={backClick}  >*/}
                {/*        <span>Back</span>*/}
                {/*    </Button>*/}
                {/*    */}{/*        </Link>*/}
                {/*</Grid>*/}

            </div>
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </>
    );
}
