import React, { useEffect, useState } from "react";
import {
    TextField,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import {
    GetAddButton,
    
} from "../../Utilities/Utility";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { BootstrapInput } from "../../Utilities/Utility";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'; import CloseIcon from "@material-ui/icons/Close";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { DeleteOutlined } from "@material-ui/icons";
import "./POEntryPage.scss";
import axios from "axios";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { GetAllPoEntryDetails, copyPOEntryDetails, deletePOEntryAttachments, getCommercialStatusMaster, getCustomerDetailsMaster, getProductMaster, getSerialNoYearMaster, getTechnicalStatusMaster, poEntryAttachmentsBasedonId, postPOEntryDetails, updatePOEntryDetails, getNewMake, getOldMake, getReason, getCountry,GetSearch ,getTypeId,deletePOEntry,GetObject} from "../../APIs/api_PoEntry";
import { getOrder } from "../../APIs/api_Insert";
import {  getCountryMaster, getState, getEngineerMaster, getOfficeMaster, getRegionMaster } from "../../APIs/api_Customer";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Loader from "../../Components/Loader/Loader";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import DownloadIconOutlined from "@material-ui/icons/GetApp";
import {
    getService
} from "../../APIs/api_Insert";


import {
    getRoundUptoXDecimals,
    tableCellCurrentFY,
    tableCellPreviousFY,
    tableIcons,
    renderValueWithThousandSeparator,
    tdInputCurrentFY,
    tdTotalInPut,
} from "../../Utilities/Utility";
import {
    MTableBody,
    MTableGroupRow,
    MTableHeader,
    MTablePagination,
} from "material-table";
import {

    makeStyles,
    TableBody,
    ThemeProvider,
} from "@material-ui/core";
import { baseApiURL } from "../../Utilities/Utility";
import parse from "html-react-parser";
const tableHeaderStyle = {
    backgroundColor: "var(--light-sand)",
    color: "var(--dark-blue)",
};

export default function POEntry() {
    const useStyles = makeStyles(() => ({
        paperSty: {
            padding: "40px",
            marginTop: "30px",
        },
        childGrid: {
            marginTop: "20px",
        },
        btnGrid: {
            justifyContent: "center",
            marginTop: "2rem",
        },
        tableCellPreviousFY: tableCellPreviousFY,
        tableCellCurrentFY: tableCellCurrentFY,
        tableCellTotal: tdTotalInPut,
    }));
    const classes = useStyles();
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [isLoading, setIsLoading] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [yearFilter, setYearFilter] = useState("");
    const yearFilterChange = (e) => {
        setYearFilter(e.target.value);
    }
    const d1 = new Date();
    const [prodFilter, setProdFilter] = useState("");
    const prodFilterChange = (e) => {
        setProdFilter(e.target.value);
    }
    let [totals, setTotals] = useState([
        {
            nOofBreaker_ACB: 0,
            poValue_ACB: 0,
            poValue_EMS:0,
            poValue_Panel:0,
            poValue_Retrofit:0,
            totalFronts_Panel:0,
            breakerQty_Retrofit:0,
                   


           
        },
    ]);
    const [custObj, setCustObj] = useState([]);
    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const [obj, setObj] = useState([]);
    const [prodMasterData, setProdMasterData] = useState([]);
    function getProdMaster() {
        setIsLoading(true);
        getProductMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("Response", response)
                setProdMasterData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    const [uniqueSerialMaster, setUniqueSerialMaster] = useState([]);
    function getSerialNoYear() {
        setIsLoading(true);
        getSerialNoYearMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                setUniqueSerialMaster(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function country() {
        setIsLoading(true);
        console.log("dated1",d1)
        getCountryMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("countryid", response);
                setCountryName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function state(countryid) {
        setIsLoading(true);
        getState(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, countryid)
            .then((response) => {
                console.log("state", response);
                setStateName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getOffice(userId,EngineerId) {
        setIsLoading(true);
        getOfficeMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, EngineerId )
            .then((response) => {
                console.log("office", response);
                setOfficeName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function getRegion(userId, EngineerId) {
        setIsLoading(true);
        getRegionMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, EngineerId)
            .then((response) => {
                console.log("region", response);
                setRegionName(response);
                //setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function SetCustyear() {
        console.log("hi");
        setIsLoading(true);

        GetSearch(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,prodFilter,yearFilter)
            .then((response) => {
                console.log("search", response);
                
                setAllPOEntry(response.list); 
                setTotals([{ ...response.totals }]);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    function grid(userId,prodFilter,yearFilter) {
        //console.log("hi");
        //setProdFilter(1);
        setIsLoader(true);
       
        console.log("prod", prodFilter);
        GetSearch(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, prodFilter, yearFilter)
            .then((response) => {
                console.log("search", response);
               
                setAllPOEntry(response.list);
                setTotals([{ ...response.totals }]);
                
                setIsLoader(false);
                
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function object(PoEntryId) {
        GetObject(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,PoEntryId)
            .then((response) => {
                console.log("objectresp", response);

               setObj(response);
                
            })
            .catch((error) => {
               
                handleSnackOpen(error, "error");
            })

    }
    function codeName() {
        setIsLoading(true);
        getCountry(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("country", response);
               
                setCode(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }

    function reason() {
        setIsLoading(true);
        getReason(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("country", response);
                setRes(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    
    function typeId() {
        setIsLoading(true);
        getTypeId(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("typeid", response);
                setTypes(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function newMake() {
        setIsLoading(true);
        getNewMake(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("country", response);
                setNewBreak(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function oldMake() {
        setIsLoading(true);
        getOldMake(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("country", response);
                setOld(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }


    const [techStatusMaster, setTechStatusMaster] = useState([]);
    const [techStatus, setTechStatus] = useState("");
    const techStatusChange = (e) => {
        setTechStatus(e.target.value);
    }
    function getTechStatusMaster() {
        setIsLoading(true);
        getTechnicalStatusMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("Tech Status", response);
                setTechStatusMaster(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }

    const [commercialStatusMaster, setCommercialStatusMaster] = useState([]);
    const [commercialStatus, setCommercialStatus] = useState("");
    const commercialStatusChange = (e) => {
        setCommercialStatus(e.target.value);
    }
    function getCommStatusMaster() {
        setIsLoading(true);
        getCommercialStatusMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("Comm Status", response);
                setCommercialStatusMaster(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    const [order, setOrder] = useState([]);
    const [orderValue, setOrderValue] = useState("");
    const handleOrder = (event) => {
        setOrderValue(event.target.value);
        //setOrderId(event.target.value);


    };
    function Setorder() {
        console.log("hi");
        //setIsLoader(true);

        getOrder(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log(response);

                setOrder(response);
                //setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                //setIsLoader(false);
            });
    }
    // AutoComplete
    const [customer, setCustomer] = useState("");
    const [customerObj, setCustomerObj] = useState("");
    const [customerText, setCustomerText] = useState("");
    const [itemsCustomer, setItemsCustomer] = useState([]);
    const [errorCustomer, setErrorCustomer] = useState(false);
    const [errorTextCustomer, setErrorTextCustomer] = useState("");

    const handleChangeCustomer = (event, newValue) => {
        setCustomerObj(newValue);
        setCustomer(newValue ? newValue.epCustomerId : 0);
        setCodeObj(newValue);
        setCode(newValue ? newValue.epCustomerId : 0);
        console.log("Customer", newValue.epCustomerId);
    };
    const handleChangeCustomerInput = (event, newInputValue) => {
        setCustomerText(newInputValue);
        //setCodeText(newInputValue);
    };
    const [code, setCode] = useState("");
    const [codeObj, setCodeObj] = useState("");
    const [codeText, setCodeText] = useState("");
    const [itemsCode, setItemsCode] = useState([]);
    const [errorCode, setErrorCode] = useState(false);
    const [errorTextCode, setErrorTextCode] = useState("");

    const handleChangeCode = (event, newValue) => {
        setCodeObj(newValue);
        setCode(newValue ? newValue.epCustomerId : 0);
        setCustomerObj(newValue);
        setCustomer(newValue ? newValue.epCustomerId : 0);
        console.log("Code", newValue.epCustomerId);
        

    };
    const handleChangeCodeInput = (event, newInputValue) => {
        setCodeText(newInputValue);
        //setCustomerText(newInputValue);
        if (newInputValue.length >= 3) {
            //const token = localStorage.getItem("access_token");
            getCustDetailsMaster(newInputValue);
        }
       
       
    };
    function getCustDetailsMaster(value) {
        //setIsLoading(true);
        getCustomerDetailsMaster(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, value)
            .then((response) => {
                setItemsCustomer(response);
               setItemsCode(response);
                //setOptions(response);
                console.log("cst code",response);
               // setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    //const [code, setCode] = useState([]);
    const [codeVal, setCodeVal] = useState(null);
    function codeValChange(e) {
        setCodeVal(e.target.value);
    }
    const [countryName, setCountryName] = useState([]);
    const [countryVal, setCountryVal] = useState("");
    
    const [types, setTypes] = useState([]);

    const [typeVal, setTypeVal] = useState("");

    const handleType = (event) => {
        setTypeVal(event.target.value);



    };
    function countryValChange(e) {
        setCountryVal(e.target.value);
        state(e.target.value);
    }
    //const count = countryName.filter((item) => item.id === countryVal);
    
       // setCodeVal(count[0].countryId);
   
    const [stateVal, setStateVal] = useState("");
    const [stateName, setStateName] = useState([]);
    function stateValChange(e) {
        setStateVal(e.target.value);
    }
    const [regionVal, setRegionVal] = useState("");
    const [regionName, setRegionName] = useState([]);
    function regionValChange(e) {
        setRegionVal(e.target.value);
    }
    const [officeVal, setOfficeVal] = useState("");
    const[officeName, setOfficeName] = useState([]);
    function officeValChange(e) {
        setOfficeVal(e.target.value);
    }
    const [service, setService] = useState([]);
    const [serviceTypeVal, setServiceTypeVal] = useState("");
    function serviceTypeChange(e) {
        setServiceTypeVal(e.target.value);
    }
    const [poNumberVal, setPONumberVal] = useState("");
    function poNumberValChange(e) {
        setPONumberVal(e.target.value);
    }
    const [poValueVal, setPOValueVal] = useState("");
    function poValueValChange(e) {
        setPOValueVal(e.target.value);
    }
    // const [poValidityVal, setPOValidityVal] = useState("");
    // function poValidityValChange(e) {
    //     setPOValidityVal(e.target.value);
    // }
    const [noOfBreakersVal, setNoOfBreakersVal] = useState("");
    function noOfBreakersValChange(e) {
        setNoOfBreakersVal(e.target.value);
    }
    const [ticketNumberVal, setTicketNumberVal] = useState("");
    function ticketNumberValChange(e) {
        setTicketNumberVal(e.target.value);
    }
    const [soNumberVal, setSONumberVal] = useState("");
    function soNumberValChange(e) {
        setSONumberVal(e.target.value);
    }
    const [assignedResourceVal, setAssignedResourceVal] = useState("");
    function assignedResourceValChange(e) {
        setAssignedResourceVal(e.target.value);
    }

    const [allPOEntry, setAllPOEntry] = useState([]);
    //retrofit onchnange
    const [breakVal, setBreakVal] = useState("");
    function breakValChange(e) {
        setBreakVal(e.target.value);
    }
    const [res, setRes] = useState([]);
    const [resVal, setResVal] = useState("");
    function resValChange(e) {
        setResVal(e.target.value);
    }
    const [old, setOld] = useState([]);
    const [oldVal, setOldVal] = useState("");
    function oldValChange(e) {
        setOldVal(e.target.value);
    }
    const [newBreak, setNewBreak] = useState([]);
    const [newVal, setNewVal] = useState("");
    function newValChange(e) {
        setNewVal(e.target.value);
    }
    const [notVal, setNotVal] = useState("");
    function notValChange(e) {
        setNotVal(e.target.value);
    }
    const [retroVal, setRetroVal] = useState("");
    function retroValChange(e) {
        setRetroVal(e.target.value);
    }
    //ems
    const [orderVal, setOrderVal] = useState("");
    function orderValChange(e) {
        setOrderVal(e.target.value);
    }
    const [meterVal, setMeterVal] = useState("");
    function meterValChange(e) {
        setMeterVal(e.target.value);
    }
    const [licenseVal, setLicenseVal] = useState("");
    function licenseValChange(e) {
        setLicenseVal(e.target.value);
    }
    const [digiVal, setDigiVal] = useState("");
    function digiValChange(e) {
        setDigiVal(e.target.value);
    }
    //panel
    const [totalVal, setTotalVal] = useState("");
    function totalValChange(e) {
        setTotalVal(e.target.value);
    }
    const [overallVal, setOverallVal] = useState("");
    function overallValChange(e) {
        setOverallVal(e.target.value);
    }
    function getAllPoEntryDetails() {
        setIsLoading(true);
        GetAllPoEntryDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("material", response);
                setAllPOEntry(response);
                console.log("po", response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function Service() {
        console.log("hi");
        setIsLoading(true);

        getService(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("service",response);
                setService(response);
                setIsLoading(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoading(false);
            });
    }
    const [rollHelper, setRollHelper] = useState("");
    const [poHelper, setPoHelper] = useState("");
    const handleChangeSoNo = e => {
        let valid;


        switch (e.target.id) {
            case "rollnumber":
                setSONumberVal(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setRollHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setRollHelper("");

                }
                break;
            case "po":
                setPOValueVal(e.target.value)
                valid = /^[0-9]*$/.test(e.target.value);
                if (!valid) {
                    setPoHelper("please enter numbers only");
                    /*console.log("so",rollHelper)*/
                }
                else {
                    setPoHelper("");

                }
                break;
            default:
                break;
        }

    }

    useEffect(() => {
        setIsLoading(true);
        console.log("userDetails",userDetails.userId)
        setYearFilter(43);
        setProdFilter(1);
        //if (grid.length==0) {
        //    setIsLoading(true)
        //    //
        //}
        //else { setIsLoading(false) }
        //getAllPoEntryDetails();
        typeId();
        getProdMaster();
        getSerialNoYear();
        getTechStatusMaster();
        getCommStatusMaster();
       // getCustDetailsMaster();
        country();
        codeName();
        reason();
        newMake();
        Setorder();
        oldMake();
        Service();
        //grid(userDetails.userId,1, 43);
        //getOffice(userDetails.userId,userDetails.userId);
       // getRegion(userDetails.userId, userDetails.userId);
        //console.log("date",inputFileValue)
    }, []);
    useEffect(() => {
        setIsLoading(true);
        console.log("userDetails", userDetails.userId)
        if (userDetails.userId > 0) {
            grid(userDetails.userId, 1, 43);
            
            getOffice(userDetails.userId, userDetails.userId);
            
            getRegion(userDetails.userId, userDetails.userId);
            //console.log("date",inputFileValue)
        }
    }, [userDetails]);
    

    const [selectedFile, setSelectedFile] = useState(null);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const fileUploadHandler = (event) => {
        const chosenFiles = Array.prototype.slice.call(event.target.files);
        setSelectedFile(chosenFiles);
        console.log("SelectedFile", event.target.files);
    };

    var columns = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.poattachmentlist.length === 0 ? "" : <InsertDriveFileIcon/>,

        },
        { title: "Customer", field: "customerName" },
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Country',
            field: 'countryName'
        },
        {
            title: 'State',
            field: 'state'
        },
        {
            title: "Region",
            field: "region"
        },
        {
            title: 'Office',
            field: 'office'
        },
        {
            title: 'Service Type',
            field: 'serviceType'
        },
        {
            title: 'PO Number',
            field: 'ponumber'
        },
        {
            title: 'PO Dated',
            field: 'podated',
              type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Validity',
            field: 'povalidity',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Value',
            field: 'povalue'
        },
        
        {
            title: 'No. Of Breakers',
            field: 'noOfBreaker'
        },
        {
            title: 'Ticket Number',
            field: 'ticketNumber'
        },
        {
            title: 'SO Number',
            field: 'sonumber'
        },
        {
            title: 'Assigned Resources',
            field: 'assignedResource'
        },
        {
            title: 'Technical Status',
            field: 'technicalStatus'
        },
        {
            title: 'Commercial Status',
            field: 'commercialStatus'
        },
        // {
        //     title: 'Documents Upload',
        //     field: 'documentsUpload',
        //     render: rowData => renderFileUploadElem(rowData)
        // },
    ];
    var columnsRetro = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.poattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        { title: "Customer", field: "customerName" },
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Country',
            field: 'countryName'
        },
        {
            title: 'State',
            field: 'state'
        },
        {
            title: "Region",
            field: "region"
        },
        {
            title: 'Office',
            field: 'office'
        },
        {
            title: 'Reason For Order Win',
            field: 'reasonForOrderWin'
        },
        {
            title: 'PO Number',
            field: 'ponumber'
        },
        {
            title: 'PO Dated',
            field: 'podated',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Validity',
            field: 'povalidity',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Value',
            field: 'povalue'
        },
       
        {
            title: 'Breaker Qty',
            field: 'breakerQty'
        },
        {
            title: 'Old Make Breaker',
            field: 'oldmakeBreaker'
        },
        {
            title: 'New Breaker Model',
            field: 'newBreakerModel'
        },
        {
            title: 'Ticket Number',
            field: 'ticketNumber'
        },
        {
            title: 'Notification Number',
            field: 'notificationNumber'
        },
        {
            title: 'SO Number',
            field: 'sonumber'
        },

        {
            title: 'Assigned Resources',
            field: 'assignedResource'
        },
        {
            title: 'Retrofit Partner',
            field: 'retrofitPartner'
        },
        {
            title: 'Technical Status',
            field: 'technicalStatus'
        },
        {
            title: 'Commercial Status',
            field: 'commercialStatus'
        },
        // {
        //     title: 'Documents Upload',
        //     field: 'documentsUpload',
        //     render: rowData => renderFileUploadElem(rowData)
        // },
    ];
    var columnsEms = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.poattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        {title:"Type", field:"typeName"},
        { title: "Customer", field: "customerName" },
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Country',
            field: 'countryName'
        },
        {
            title: 'State',
            field: 'state'
        },
        {
            title: "Region",
            field: "region"
        },
        {
            title: 'Office',
            field: 'office'
        },

        {
            title: 'PO Number',
            field: 'ponumber'
        },
        {
            title: 'PO Dated',
            field: 'podated',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'PO Value',
            field: 'povalue'
        },
        {
            title: 'PO Validity',
            field: 'povalidity',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        {
            title: 'OrderType',
            field: 'orderTypeName'
        },
        {
            title: 'Meters Make',
            field: 'metersMake'
        },

        {
            title: 'Ticket Number',
            field: 'ticketNumber'
        },

        {
            title: 'SO Number',
            field: 'sonumber'
        },
        {
            title: 'License Quantity',
            field: 'licenseQuantity'
        },

        {
            title: 'Assigned Resources',
            field: 'assignedResource'
        },
        {
            title: 'Digi Partner',
            field: 'digiPartner'
        },
        {
            title: 'Technical Status',
            field: 'technicalStatus'
        },
        {
            title: 'Commercial Status',
            field: 'commercialStatus'
        },
        // {
        //     title: 'Documents Upload',
        //     field: 'documentsUpload',
        //     render: rowData => renderFileUploadElem(rowData)
        // },
    ];
    var columnsPanel = [
        {

            title: "File Status",

            field: "filestat",

            //hidden: selectedModule === 1 ? true : false,

            render: (rowData) =>

                rowData.poattachmentlist.length === 0 ? "" : <InsertDriveFileIcon />,

        },
        { title: "Customer", field: "customerName" },
        {
            title: 'Code',
            field: 'code'
        },
        {
            title: 'Country',
            field: 'countryName'
        },
        {
            title: 'State',
            field: 'state'
        },
        {
            title: "Region",
            field: "region"
        },
        {
            title: 'Office',
            field: 'office'
        },

        {
            title: 'PO Number',
            field: 'ponumber'
        },
        {
            title: 'PO Dated',
            field: 'podated',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        
        {
            title: 'PO Validity',
            field: 'povalidity',
            type: "date",

            dateSetting: { locale: "en-GB" },
        },
        
        {
            title: 'OrderType',
            field: 'orderTypeId'
        },
        {
            title: 'PO Value',
            field: 'povalue'
        },
        {
            title: 'Total Fronts',
            field: 'totalFronts'
        },

        {
            title: 'Ticket Number',
            field: 'ticketNumber'
        },

        {
            title: 'SO Number',
            field: 'sonumber'
        },


        {
            title: 'Assigned Resources',
            field: 'assignedResource'
        },

        {
            title: 'Technical Status',
            field: 'technicalStatus'
        },
        {
            title: 'Commercial Status',
            field: 'commercialStatus'
        },
        {
            title: 'Overall Qty Services',
            field: 'overallQtyServices'
        },
        // {
        //     title: 'Documents Upload',
        //     field: 'documentsUpload',
        //     render: rowData => renderFileUploadElem(rowData)
        // },
    ];


    function renderFileDownload(rowData) {
        return (
            <Button
                href={rowData.fileUrlWithSasToken}
                color="transparent"
                target="_blank"
                download
            >
                <Tooltip title="Download Attachment">
                    <DownloadIconOutlined />
                </Tooltip>
            </Button>);
    }
    function deleteAttachment(rowData) {
        deletePOEntryAttachments(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, rowData)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                getPOAttachments(siteToken, rowData.poEntryId, userDetails.userId);
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    function deletePoEntryRow() {
        deletePOEntry(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, deleteRowData.epPoentryPageId)
            .then((response) => {
                handleSnackOpen(response.responseMsg, "success");
                handleCloseDeleteDialog();
                grid(userDetails.userId,deleteRowData.productId, deleteRowData.yearId);
                
            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    function renderFileDelete(rowData) {
        const rowD = rowData;
        return (
            <Button
                color="transparent"
                onClick={() => deleteAttachment(rowD)}
            >
                <Tooltip title="Delete Attachment">
                    <DeleteOutlineOutlinedIcon />
                </Tooltip>
            </Button>);
    }

    var attachColumns = [
        {
            title: "File Name",
            field: "filename"
        },
        {
            title: "Download",
            render: rowData => renderFileDownload(rowData)
        },
        {
            title: "Delete",
            render: rowData => renderFileDelete(rowData)
        },
    ];

    // Dialog Part
    const [openDialog, setOpenDialog] = useState(false);
    const [custDialogTitle, setCustDialogTitle] = useState("Add PO Entry");
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [dialogSubmitBtn, setDialogSubmitBtn] = useState("Save");

    function clearFormValues() {
        setCustomerObj("");
        setCustomer("");
        setCustomerText("");
        setCodeVal("");
        setCountryVal("");
        setCodeObj("");
        setCode("");
        setCodeText("");
       
        setStateVal("");
        setRegionVal("");
        setOfficeVal("");
        setServiceTypeVal("");
        setPONumberVal("");
        setSelectedDate(null);
        setPOValidityDate(null);
        setPOValueVal("");
        setNoOfBreakersVal("");
        setTicketNumberVal("");
        setSONumberVal("");
        setAssignedResourceVal("");
        setTechStatus("");
        setCommercialStatus("");
        setSelectedFile(null);
        setInputFileValue(Date.now);
        setEPPoentryId(null);
        setFileAttachments([]);
        //retrofit
        setBreakVal("");
        setResVal("");
        setOldVal("");
        setNewVal("");
        setNotVal("");
        setRetroVal("");
        //ems
        setOrderVal("");
        setMeterVal("");
        setLicenseVal("");
        setDigiVal("");
        setTypeVal("");
        //panel
        setTotalVal("");
        setOverallVal("");
    }
    const handleCloseForm = () => {
        clearFormValues();
        setOpenDialog(false);
    };
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteRowData(null);
    };
    const handleDeleteIconClick = (rowData) => {
        setDeleteRowData(rowData);
        setDeleteDialogOpen(true);
    };
    function handleCopyIconClick(rowData) {
        setIsLoading(true);
        copyPOEntryDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, rowData.yearId, rowData.epPoentryPageId, userDetails.userId).
            then((response) => {
                setIsLoading(false);
                handleSnackOpen(response.responseMsg, "success");
                getAllPoEntryDetails();
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error")
            })
    }
    //function GetAddButton() {
    //    return (
    //        <Tooltip title="Add" placement="bottom">
    //            <span>
    //                <Button href="javascript:void(0)">
    //                    <span>
    //                        <AddBoxOutlinedIcon fontSize="small" className="addFileIcon" />
    //                    </span>
    //                </Button>
    //            </span>
    //        </Tooltip>
    //    );
    //}
    function GetEditButton() {
        return (
            <Tooltip title="Edit" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetFileButton() {
        return (
            <Tooltip title="file" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <InsertDriveFileIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetDeleteButton() {
        return (
            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetCloseButton() {
        return (
            <Tooltip title="close" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <CloseIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetCopyButton() {
        return (
            <Tooltip title="Copy" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <FileCopyOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    const [epPoentryId, setEPPoentryId] = useState(null);
    const [fileAttachments, setFileAttachments] = useState([]);
    const [flag, setFlag] = useState(false);
    function getPOAttachments(siteToken, epPoentryPageId, userId) {
        setIsLoading(true);
        poEntryAttachmentsBasedonId(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, epPoentryPageId)
            .then((response) => {
                setFileAttachments(response);

                console.log("Attch Response", response);
                if (response.length > 0) {
                    setFlag(true)
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    const dialogDraftValuesFn = (rowData) => {
        console.log("Row Data", rowData);
        setDialogSubmitBtn("Update");
        setOpenDialog(true);

        // setCustomerObj({
        //     "epCustomerId": 1,
        //     "customerName": "TataTest",
        //     "customerCodeWithName": "123-TataTest",
        //     "customerCode": "123",
        //     "customerLocation": "YP",
        //     "city": "Mumbai",
        //     "addess": "Pokhran road",
        //     "pincode": 400610,
        //     "contactPerson": "Internal tester",
        //     "emailId": "sakshi.dabade.ext@siemens.com",
        //     "mobile": 9791216027,
        //     "isActive": true,
        //     "createdBy": 1,
        //     "createdDate": "2023-06-13T12:32:54.707",
        //     "modifiedBy": 1,
        //     "modifiedDate": "2023-06-13T17:06:15.373",
        //     "remarks": null,
        //     "loggedInUserId": 0,
        //     "loggedInUserName": null,
        //     "response": null,
        //     "loggedInUser": 0
        // });
        setCustomerObj(rowData.customerObject[0]);
        setCustomer(rowData.customerObject[0] ? rowData.customerObject[0].epCustomerId : 0);
        setCodeObj(rowData.customerObject[0]);
        setCode(rowData.customerObject[0]? rowData.customerObject[0].epCustomerId : 0);

        //const custObj = itemsCustomer.filter((item) => item.epCustomerId === rowData.customerId);
        //setCustomerObj(custObj?.length > 0 ? custObj[0] : []);
        //setCustomerObj(rowData.customerId)
        //const custObj = itemsCustomer.filter((item) => item.epCustomerId === rowData.customerId);
        //setCustomerObj(custObj?.length > 0 ? custObj[0] : []);
        //setCustomer(rowData.customerId);
        setCustomerText(rowData.customerName);
        //const codeObj = itemsCode.filter((item) => item.epCustomerId === rowData.customerId);
        //setCodeObj(codeObj?.length > 0 ? codeObj[0] : []);
        //setCodeObj(obj)
        //setCode(rowData.customerId);
        setCodeText(rowData.code);
        //setCodeVal(rowData.code);
        setCountryVal(rowData.countryId);
        state(rowData.countryId)
        setStateVal(rowData.stateId);
        setRegionVal(rowData.regionId);
        setOfficeVal(rowData.officeId);
        setServiceTypeVal(rowData.serviceid);
        setPONumberVal(rowData.ponumber);
        setSelectedDate(rowData.podated);
        setPOValueVal(rowData.povalue);
        setPOValidityDate(rowData.povalidity);
        setNoOfBreakersVal(rowData.noOfBreaker);
        setTicketNumberVal(rowData.ticketNumber);
        setSONumberVal(rowData.sonumber);
        setAssignedResourceVal(rowData.assignedResource);
        setTechStatus(rowData.technicalStatusId);
        setCommercialStatus(rowData.commercialStatusId);
        setEPPoentryId(rowData.epPoentryPageId);
        setFileAttachments(rowData.poattachmentlist.length > 0 ? rowData.poattachmentlist : []);
        // setSelectedFile(null);
        //retrofit
        setBreakVal(rowData.breakerQty);
        setResVal(rowData.reasonForOrderWinId);
        setOldVal(rowData.oldmakeBreakerId);
        setNewVal(rowData.newBreakerModelId);
        setNotVal(rowData.notificationNumber);
        setRetroVal(rowData.retrofitPartner);
        //ems
        setOrderValue(rowData.orderTypeId);
        setMeterVal(rowData.metersMake);
        setLicenseVal(rowData.licenseQuantity);
        setDigiVal(rowData.digiPartner);
        setTypeVal(rowData.typeId);
       

        //panel
        setTotalVal(rowData.totalFronts);
        setOverallVal(rowData.overallQtyServices);


        poEntryAttachmentsBasedonId(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, rowData.epPoentryPageId)
            .then((response) => {
                setFileAttachments(response);
                console.log("Attch Response", response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
        //getPOAttachments(siteToken, rowData.epPoentryPageId, userDetails.userId);
    };

    ///const siteToken = useSelector((state) => state.saveTokenReducer.token);
    function handleSaveForm() {
        const formData = new FormData();
        if (customer === "" || customer === null || customer === undefined) {
            handleSnackOpen("customer is Empty", "error");
            return;
        }
        if (code === "" || code === null || code === undefined) {
            handleSnackOpen("code is Empty", "error");
            return;
        }
        if (stateVal === "" || stateVal === null || stateVal === undefined) {
            handleSnackOpen("state is Empty", "error");
            return;
        }
        if (countryVal === "" || countryVal === null || countryVal === undefined) {
            handleSnackOpen("country is Empty", "error");
            return;
        }
        if (regionVal === "" || regionVal === null || regionVal === undefined) {
            handleSnackOpen("region is Empty", "error");
            return;
        }
        if (officeVal === "" || officeVal === null || officeVal === undefined) {
            handleSnackOpen("office is Empty", "error");
            return;
        }
        if (prodFilter === 1) {
            if (serviceTypeVal === "" || serviceTypeVal === null || serviceTypeVal === undefined) {
                handleSnackOpen("serviceType is Empty", "error");
                return;
            }
        }
        if (poNumberVal === "" || poNumberVal === null || poNumberVal === undefined) {
            handleSnackOpen("poNumber is Empty", "error");
            return;
        }
        if (poValidityDate === "" || poValidityDate === null || poValidityDate === undefined) {
            handleSnackOpen("poValidityDate is Empty", "error");
            return;
        }
        if (selectedDate === "" || selectedDate === null || selectedDate === undefined) {
            handleSnackOpen("poDate is Empty", "error");
            return;
        }
        if (poValueVal === "" || poValueVal === null || poValueVal === undefined) {
            handleSnackOpen("poValue is Empty", "error");
            return;
        }
        if (prodFilter === 1) {
            if (noOfBreakersVal === "" || noOfBreakersVal === null || noOfBreakersVal === undefined) {
                handleSnackOpen("NoOfBreaker is Empty", "error");
                return;
            }
        }
        if (soNumberVal === "" || soNumberVal === null || soNumberVal === undefined) {
            handleSnackOpen("SoNumber is Empty", "error");
            return;
        }
        if (assignedResourceVal === "" || assignedResourceVal === null || assignedResourceVal  === undefined) {
            handleSnackOpen("AssignedResource is Empty", "error");
            return;
        }
        if (techStatus === "" || techStatus === null || techStatus === undefined) {
            handleSnackOpen("TechStatus is Empty", "error");
            return;
        }

        if (commercialStatus === "" || commercialStatus === null || commercialStatus === undefined) {
            handleSnackOpen("commercialStatus is Empty", "error");
            return;
        }
        if (selectedFile === "" || selectedFile === null || selectedFile === undefined) {
            handleSnackOpen("File is Empty", "error");
            return;
        }
        if (prodFilter === 2) {
            if (breakVal === "" || breakVal === null || breakVal === undefined) {
                handleSnackOpen("breakerQty is Empty", "error");
                return;
            }
            if (resVal === "" || resVal === null || resVal === undefined) {
                handleSnackOpen("reasonForOrderWin is Empty", "error");
                return;
            }
            if (oldVal === "" || oldVal === null || oldVal === undefined) {
                handleSnackOpen("oldMakeBreaker is Empty", "error");
                return;
            }
            if (newVal === "" || newVal === null || newVal === undefined) {
                handleSnackOpen("newBreakerModel is Empty", "error");
                return;
            }

        }
        if (prodFilter === 3) {
            if (orderValue === "" || orderValue === null || orderValue === undefined) {
                handleSnackOpen("orderType is Empty", "error");
                return;
            }
            if (meterVal=== "" || meterVal === null || meterVal === undefined) {
                handleSnackOpen("metersMake is Empty", "error");
                return;
            }
            
            if (licenseVal === "" || licenseVal === null || licenseVal === undefined) {
                handleSnackOpen("licenseQty is Empty", "error");
                    return;
                }
            if (digiVal === "" || digiVal === null || digiVal === undefined) {
                    handleSnackOpen("digiPartner is Empty", "error");
                    return;
                }
            if (typeVal === "" || typeVal === null || typeVal === undefined) {
                    handleSnackOpen("typeId is Empty", "error");
                    return;
                }

        }
        if (prodFilter === 4) {
            if (orderValue === "" || orderValue === null || orderValue === undefined) {
                handleSnackOpen("orderType is Empty", "error");
                return;
            }
            if (totalVal === "" || totalVal === null || totalVal === undefined) {
                handleSnackOpen("totalFronts is Empty", "error");
                return;
            }
            if (overallVal === "" || overallVal === null || overallVal === undefined) {
                handleSnackOpen("overallQty is Empty", "error");
                return;
            }

           

        }
        if (selectedFile == null) {
            formData.append('file', selectedFile);
        }
        else {


            if (selectedFile.length === 0) {
                formData.append('file', selectedFile);
            }
            else {
                for (let i = 0; i < selectedFile.length; i++) {
                    formData.append("Document", selectedFile[i]);

                }
            }
        }
        formData.append('userId', userDetails.userId);
        formData.append('yearId', yearFilter);
        formData.append('productId', prodFilter);
        formData.append('customerId', customer);
        formData.append('codeId', code);
        formData.append('countryId', countryVal);
        formData.append('state', stateVal);
        formData.append('region', regionVal);
        formData.append('office', officeVal);
        formData.append('serviceTypeId', serviceTypeVal);
        formData.append('poNumber', poNumberVal);

        formData.append('poValidity', poValidityDate);
        formData.append('poDated', selectedDate);

        formData.append('poValue', poValueVal);
        formData.append('noOfBreaker', noOfBreakersVal);
        formData.append('sonumber', soNumberVal);
        formData.append('ticketNumber', ticketNumberVal);
        formData.append('assignedResource', assignedResourceVal);
        formData.append('technicalStatusId', techStatus);
        formData.append('commercialStatusId', commercialStatus);

        //Retrofit
        formData.append('breakerQty', breakVal);
        formData.append('reasonForOrderWin', resVal);
        formData.append('oldMakeBreaker', oldVal);
        formData.append('newBreakerModel', newVal);
        formData.append('notificationNumber', notVal);
        formData.append('retrofitPartner', retroVal);
        //ems
        formData.append('orderTypeId', orderValue);
        formData.append('metersMake', meterVal);
        formData.append('licenseQuantity', licenseVal);
        formData.append('digiPartner', digiVal);
        formData.append('typeId', typeVal);
        
        //panel
        formData.append('totalFronts', totalVal);
        formData.append('overallQtyServices', overallVal);
        console.log("formData", formData);

        setIsLoading(true);
        postPOEntryDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,formData)
            .then((response) => {
                console.log("Resp", response);
                setIsLoading(false);
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    handleCloseForm();
                    // getAllPoEntryDetails();
                    grid(userDetails.userId, prodFilter, yearFilter)
                }
                else {
                    handleSnackOpen(response.responseMsg, "error");
                    handleCloseForm();
                }
            })
            .catch((error) => {
                setIsLoading(false);;
                handleSnackOpen(error, "error");
            });

        // const options = {
        //     method: "post",
        //     mode: "cors",
        //     headers: {
        //         Authorization: "Bearer " + siteToken,
        //     },
        //     body: formData,
        // };
        // return fetch(baseApiURL + "/POEntry/PostPOEntryDetails", options)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data.result);
        //     })
        //     .catch((error) => {
        //         console.log("Error : ", error);
        //     });



        // const options = {
        //     method: "post",
        //     mode: "cors",
        //     headers: {
        //         Accept: "application/json, text/plain, */*",
        //         "Content-Type": "application/json",
        //         Authorization: "Bearer " + siteToken,
        //     },
        //     body: formData
        // };

        // fetch(baseApiURL + "/POEntry/PostPOEntryDetails", options)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         console.log(data);
        //     })
        //     .catch((error) => {
        //         console.log("Error : ", error);
        //     });

        // const options = {
        //     method: "post",
        //     url: "https://localhost:44342/api/POEntry/PostPOEntryDetails",
        //     headers: {
        //         Authorization: "Bearer " + siteToken,
        //     },
        //     body: formData,
        // };
        // api.post(options)
        //     .then(res => {
        //         console.log("ee", res);
        //     })
        //     .catch(error => {
        //         console.log("ff", error);
        //     })

    }
    function handleUpdateForm() {
        console.log("Update Clicked");
        console.log("reason",resVal)
        const formData = new FormData();
        if (customer === "" || customer === null || customer === undefined) {
            handleSnackOpen("customer is Empty", "error");
            return;
        }
        if (code === "" || code === null || code === undefined) {
            handleSnackOpen("code is Empty", "error");
            return;
        }
        if (stateVal === "" || stateVal === null || stateVal === undefined) {
            handleSnackOpen("state is Empty", "error");
            return;
        }
        if (countryVal === "" || countryVal === null || countryVal === undefined) {
            handleSnackOpen("country is Empty", "error");
            return;
        }
        if (regionVal === "" || regionVal === null || regionVal === undefined) {
            handleSnackOpen("region is Empty", "error");
            return;
        }
        if (officeVal === "" || officeVal === null || officeVal === undefined) {
            handleSnackOpen("office is Empty", "error");
            return;
        }
        if (prodFilter === 1) {
            if (serviceTypeVal === "" || serviceTypeVal === null || serviceTypeVal === undefined) {
                handleSnackOpen("serviceType is Empty", "error");
                return;
            }
            if (poNumberVal === "" || poNumberVal === null || poNumberVal === undefined) {
                handleSnackOpen("poNumber is Empty", "error");
                return;
            }
        }
        if (poValidityDate === "" || poValidityDate === null || poValidityDate === undefined) {
            handleSnackOpen("poValidityDate is Empty", "error");
            return;
        }
        if (selectedDate === "" || selectedDate === null || selectedDate === undefined) {
            handleSnackOpen("poDate is Empty", "error");
            return;
        }
        if (poValueVal === "" || poValueVal === null || poValueVal === undefined) {
            handleSnackOpen("poValue is Empty", "error");
            return;
        }
        if (prodFilter === 1) {
            if (noOfBreakersVal === "" || noOfBreakersVal === null || noOfBreakersVal === undefined) {
                handleSnackOpen("NoOfBreaker is Empty", "error");
                return;
            }
        }
        if (soNumberVal === "" || soNumberVal === null || soNumberVal === undefined) {
            handleSnackOpen("SoNumber is Empty", "error");
            return;
        }
        if (assignedResourceVal === "" || assignedResourceVal === null || assignedResourceVal === undefined) {
            handleSnackOpen("AssignedResource is Empty", "error");
            return;
        }
        if (techStatus === "" || techStatus === null || techStatus === undefined) {
            handleSnackOpen("TechStatus is Empty", "error");
            return;
        }

        if (commercialStatus === "" || commercialStatus === null || commercialStatus === undefined) {
            handleSnackOpen("commercialStatus is Empty", "error");
            return;
        }
        //if (selectedFile === "" || selectedFile === null || selectedFile === undefined) {
        //    handleSnackOpen("File is Empty", "error");
        //    return;
        //}
        if (prodFilter === 2) {
            if (breakVal === "" || breakVal === null || breakVal === undefined) {
                handleSnackOpen("breakerQty is Empty", "error");
                return;
            }
            if (resVal === "" || resVal === null || resVal === undefined) {
                handleSnackOpen("reasonForOrderWin is Empty", "error");
                return;
            }
            if (oldVal === "" || oldVal === null || oldVal === undefined) {
                handleSnackOpen("oldMakeBreaker is Empty", "error");
                return;
            }
            if (newVal === "" || newVal === null || newVal === undefined) {
                handleSnackOpen("newBreakerModel is Empty", "error");
                return;
            }

        }
        if (prodFilter === 3) {
            if (orderValue === "" || orderValue === null || orderValue === undefined) {
                handleSnackOpen("orderType is Empty", "error");
                return;
            }
            if (meterVal === "" || meterVal === null || meterVal === undefined) {
                handleSnackOpen("metersMake is Empty", "error");
                return;
            }

            if (licenseVal === "" || licenseVal === null || licenseVal === undefined) {
                handleSnackOpen("licenseQty is Empty", "error");
                return;
            }
            if (digiVal === "" || digiVal === null || digiVal === undefined) {
                handleSnackOpen("digiPartner is Empty", "error");
                return;
            }
            if (typeVal === "" || typeVal === null || typeVal === undefined) {
                handleSnackOpen("typeId is Empty", "error");
                return;
            }

        }
        if (prodFilter === 4) {
            if (orderValue === "" || orderValue === null || orderValue === undefined) {
                handleSnackOpen("orderType is Empty", "error");
                return;
            }
            if (totalVal === "" || totalVal === null || totalVal === undefined) {
                handleSnackOpen("totalFronts is Empty", "error");
                return;
            }
            if (overallVal === "" || overallVal === null || overallVal === undefined) {
                handleSnackOpen("overallQty is Empty", "error");
                return;
            }



        }
        if (selectedFile == null) {
            formData.append('file', selectedFile);
        }
        else {


            if (selectedFile.length === 0) {
                formData.append('file', selectedFile);
            }
            else {
                for (let i = 0; i < selectedFile.length; i++) {
                    formData.append("Document", selectedFile[i]);

                }
            }
        }
        
        formData.append('userId', userDetails.userId);
        formData.append('yearId', yearFilter);
        formData.append('productId', prodFilter);
        formData.append('customerId', customer);
        formData.append('codeId', code);
        formData.append('countryId', countryVal);
        formData.append('state', stateVal);
        formData.append('region', regionVal);
        formData.append('office', officeVal);
        formData.append('serviceTypeId', serviceTypeVal);
        formData.append('poNumber', poNumberVal);

        formData.append('poValidity', poValidityDate);
        formData.append('poDated', selectedDate);

        formData.append('poValue', poValueVal);
        formData.append('noOfBreaker', noOfBreakersVal);
        formData.append('sonumber', soNumberVal);
        formData.append('ticketNumber', ticketNumberVal);
        formData.append('assignedResource', assignedResourceVal);
        formData.append('technicalStatusId', techStatus);
        formData.append('commercialStatusId', commercialStatus);
        formData.append('EpPoentryPageId', epPoentryId);
        //Retrofit
        formData.append('breakerQty', breakVal);
        formData.append('reasonForOrderWin', resVal);
        formData.append('oldMakeBreaker', oldVal);
        formData.append('newBreakerModel', newVal);
        formData.append('notificationNumber', notVal);
        formData.append('retrofitPartner', retroVal);
        //ems
        formData.append('orderTypeId', orderValue);
        formData.append('metersMake', meterVal);
        formData.append('licenseQuantity', licenseVal);
        formData.append('digiPartner', digiVal);
        formData.append('typeId', typeVal);
        //panel
        formData.append('totalFronts', totalVal);
        formData.append('overallQtyServices', overallVal);

        console.log("formData", formData);

        setIsLoading(true);
        updatePOEntryDetails(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, formData)
            .then((response) => {
                console.log("Resp", response);
                setIsLoading(false);
                if (response.response) {
                    handleSnackOpen(response.responseMsg, "success");
                    handleCloseForm();
                    //getAllPoEntryDetails();
                    grid(userDetails.userId, prodFilter, yearFilter)
                
                }
                else {
                    handleSnackOpen(response.responseMsg, "error");
                    handleCloseForm();
                }
            })
            .catch((error) => {
                setIsLoading(false);;
                handleSnackOpen(error, "error");
            });


    }
    function convertDateFormat(selData) {
        var dateStr =
            selData.getFullYear() + "-" +
            ("00" + (selData.getMonth() + 1)).slice(-2) + "-" +
            ("00" + selData.getDate()).slice(-2) + " " +
            ("00" + selData.getHours()).slice(-2) + ":" +
            ("00" + selData.getMinutes()).slice(-2) + ":" +
            ("00" + selData.getSeconds()).slice(-2);

        return dateStr;
    }
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (date) => {
        const selData = convertDateFormat(date);
        console.log(selData);

        setSelectedDate(selData);
    };
    const [poValidityDate, setPOValidityDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const handlePOValDateChange = (date) => {
        const selData = convertDateFormat(date);
        const d2 = convertDateFormat(d1);
        console.log(selData);
        setPOValidityDate(selData);
        setMaxDate(d2);
        console.log("dated",maxDate)
    };
    const [options, setOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    function handleInputChange(event, value) {
        console.log("value", value);
        setSearchValue(value);
        setCustomerText(value);
        console.log("searchval",searchValue)
        //console.log("search", value);
        if (value.length >= 3) {
            //const token = localStorage.getItem("access_token");
            getCustDetailsMaster(value);
        }
        
    }
    function getCustomTBodyForTotal(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_ACB, 0)
                            )}

                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.nOofBreaker_ACB, 0)
                            )}

                        </TableCell>
                        


                    </TableRow>
                ))}
            </TableBody>
        );
    }
    function getCustomTBodyForTotalRetrofit(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_Retrofit, 0)
                            )}

                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.breakerQty_Retrofit, 0)
                            )}

                        </TableCell>



                    </TableRow>
                ))}
            </TableBody>
        );
    }
    function getCustomTBodyForTotalEms(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={9}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_EMS, 0)
                            )}

                        </TableCell>
                        



                    </TableRow>
                ))}
            </TableBody>
        );
    }
    function getCustomTBodyForTotalPanel(data) {
        return (
            <TableBody style={{ position: "sticky", top: 60 }}>
                {data.map((row) => (
                    <TableRow className={classes.tableCellTotal}>
                        <TableCell
                            align="center"
                            colSpan={12}
                            style={{ fontWeight: "bold" }}
                        >
                            TOTAL
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.poValue_Panel, 0)
                            )}

                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                            {renderValueWithThousandSeparator(
                                getRoundUptoXDecimals(row.totalFronts_Panel, 0)
                            )}

                        </TableCell>




                    </TableRow>
                ))}
            </TableBody>
        );
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} className="text-left ml-1">
              
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            FY Filter
                        </InputLabel>
                        <Select
                            label="Year Filter"
                            id=""
                            name=""
                            className=""
                            fullWidth
                            value={yearFilter}
                            onChange={yearFilterChange}
                            input={<BootstrapInput />}
                        >
                            {uniqueSerialMaster.map((item) => (
                                <MenuItem key={item.yearId} value={item.yearId}>
                                    {item.year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="bootstrap-input" >
                            Product
                        </InputLabel>
                        <Select
                            label="Product Filter"
                            id=""
                            name=""
                            className=""
                            fullWidth
                            value={prodFilter}
                            onChange={prodFilterChange}
                            input={<BootstrapInput />}
                        >
                            {prodMasterData.map((item) => (
                                <MenuItem key={item.epProductId} value={item.epProductId}>
                                    {item.epProductName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={3} className="mr-btm">
                    <Button className="pt-button--primary button-submit"
                        variant="contained"
                        onClick={SetCustyear}
                    >
                        Search
                    </Button>
                </Grid>
                {/*<Grid xs={12} sm={3} className="mr-btm">*/}
                {/*    <Button className="pt-button--primary button-submit"*/}
                {/*        variant="contained"*/}
                {/*        link='/generateReport'*/}
                {/*    >*/}
                {/*        Generate Report*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
                
                <Grid item xs={12} sm={3}  >
                    <Link to="/generateReport" >
                        <div className="menu-item-container">
                            <div className="menu-item-text-block">
                                <span className="align-center ml-1">
                                    Generate Report
                                </span>
                            </div>
                        </div>
                    </Link>
                </Grid>
               
                <Grid>
                    {prodFilter == 1 ? <MaterialTable
                        title="PO Entry"
                        columns={columns}
                        data={allPOEntry}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}
                        
                        options={{
                            rowStyle: rowData => new Date(rowData.povalidity).getTime() > new Date(d1).getTime() ? ({ backgroundColor: "rgba(255,84,84,0.5019607843137255)" }) : ({ backgroundColor: "#FFFFFF", }),
                            headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "360px", searchFieldAlignment: "left",
                            toolbarButtonAlignment: "left",
}}
                        actions={[
                            {
                                icon: GetAddButton,
                                isFreeAction: true,
                                onClick: () => {
                                    setDialogSubmitBtn("Save");
                                    setCustDialogTitle("Add PO Entry");
                                    setOpenDialog(true);
                                },
                            },
                            {
                                icon: GetEditButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                   
                                    setDialogSubmitBtn("Update");
                                    setCustDialogTitle("Update PO Entry");
                                    //object(rowData.epPoentryPageId);
                                    //getCustDetailsMaster(rowData.customerName)
                                    //handleInputChange(e, rowData.customerName)
                                    //getCustDetailsMaster(rowData.customerName)
                                    dialogDraftValuesFn(rowData);
                                },
                            },
                            {
                                icon: GetDeleteButton,
                                tisFreeAction: false,
                                onClick: (e, rowData) => {
                                    console.log("Row Data", rowData);
                                    //deletePoEntryRow(rowData);
                                    handleDeleteIconClick(rowData);
                                },


                            },
                            
                           
                            {
                                icon: GetCopyButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    handleCopyIconClick(rowData);
                                },
                            },
                        ]}
                        components={{
                            Header: (props) => (
                                <>

                                    <MTableHeader {...props} />
                                    {getCustomTBodyForTotal(totals)}
                                </>
                            ),
                            Body: (props) => (
                                <>
                                    <MTableBody {...props} />
                                </>
                            ),

                        }}
                    /> : <></>}
                </Grid>
                <Grid>
                    {prodFilter == 2 ? <MaterialTable
                        title="PO Entry"
                        columns={columnsRetro}
                        data={allPOEntry}
                        icons={tableIconsInputScreen}
                        options={{
                            rowStyle: rowData => new Date(rowData.povalidity).getTime() > new Date(d1).getTime() ? ({ backgroundColor: "rgba(255,84,84,0.5019607843137255)" }) : ({ backgroundColor: "#FFFFFF", }),
                            headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "300px", searchFieldAlignment: "left",
                            toolbarButtonAlignment: "left", }}
                       
                        actions={[
                            {
                                icon: GetAddButton,
                                isFreeAction: true,
                                onClick: () => {
                                    setDialogSubmitBtn("Save");
                                    setCustDialogTitle("Add PO Entry");
                                    setOpenDialog(true);
                                },
                            },
                            {
                                icon: GetEditButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    setDialogSubmitBtn("Update");
                                    setCustDialogTitle("Update Row");
                                    dialogDraftValuesFn(rowData);
                                },
                            },
                            {
                                icon: GetDeleteButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    console.log("Row Data", rowData);
                                    handleDeleteIconClick(rowData);
                                },
                            },
                            {
                                icon: GetCopyButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    handleCopyIconClick(rowData);
                                },
                            },
                        ]}
                        components={{
                            Header: (props) => (
                                <>

                                    <MTableHeader {...props} />
                                    {getCustomTBodyForTotalRetrofit(totals)}
                                </>
                            ),
                            Body: (props) => (
                                <>
                                    <MTableBody {...props} />
                                </>
                            ),

                        }}
                    /> : <></>}
                </Grid>
                <Grid>
                    {prodFilter == 3 ? <MaterialTable
                        title="PO Entry"
                        columns={columnsEms}
                        data={allPOEntry}
                        icons={tableIconsInputScreen}
                        options={{
                            rowStyle: rowData => new Date(rowData.povalidity).getTime() > new Date(d1).getTime() ? ({ backgroundColor: "rgba(255,84,84,0.5019607843137255)" }) : ({ backgroundColor: "#FFFFFF", }),
                            headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "300px", searchFieldAlignment: "left",
                            toolbarButtonAlignment: "left", }}
                        actions={[
                            {
                                icon: GetAddButton,
                                isFreeAction: true,
                                onClick: () => {
                                    setDialogSubmitBtn("Save");
                                    setCustDialogTitle("Add PO Entry");
                                    setOpenDialog(true);
                                },
                            },
                            {
                                icon: GetEditButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    setDialogSubmitBtn("Update");
                                    setCustDialogTitle("Update Row");
                                    dialogDraftValuesFn(rowData);
                                },
                            },
                            {
                                icon: GetDeleteButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    console.log("Row Data", rowData);
                                    handleDeleteIconClick(rowData);
                                },
                            },
                            {
                                icon: GetCopyButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    handleCopyIconClick(rowData);
                                },
                            },
                        ]}
                        components={{
                            Header: (props) => (
                                <>

                                    <MTableHeader {...props} />
                                    {getCustomTBodyForTotalEms(totals)}
                                </>
                            ),
                            Body: (props) => (
                                <>
                                    <MTableBody {...props} />
                                </>
                            ),

                        }}
                    /> : <></>}
                </Grid>
                <Grid>
                    {prodFilter == 4 ? <MaterialTable
                        title="PO Entry"
                        columns={columnsPanel}
                        data={allPOEntry}
                        icons={tableIconsInputScreen}
                        options={{
                            rowStyle: rowData => new Date(rowData.povalidity).getTime() > new Date(d1).getTime() ? ({ backgroundColor: "rgba(255,84,84,0.5019607843137255)" }) : ({ backgroundColor: "#FFFFFF", }),
                            headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "300px", searchFieldAlignment: "left",
                            toolbarButtonAlignment: "left", }}
                        actions={[
                            {
                                icon: GetAddButton,
                                isFreeAction: true,
                                onClick: () => {
                                    setDialogSubmitBtn("Save");
                                    setCustDialogTitle("Add PO Entry");
                                    setOpenDialog(true);
                                },
                            },
                            {
                                icon: GetEditButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    setDialogSubmitBtn("Update");
                                    setCustDialogTitle("Update Row");
                                    dialogDraftValuesFn(rowData);
                                },
                            },
                            {
                                icon: GetDeleteButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    console.log("Row Data", rowData);
                                    handleDeleteIconClick(rowData);
                                },
                            },
                            {
                                icon: GetCopyButton,
                                tisFreeAction: true,
                                onClick: (e, rowData) => {
                                    handleCopyIconClick(rowData);
                                },
                            },
                        ]}
                        components={{
                            Header: (props) => (
                                <>

                                    <MTableHeader {...props} />
                                    {getCustomTBodyForTotalPanel(totals)}
                                </>
                            ),
                            Body: (props) => (
                                <>
                                    <MTableBody {...props} />
                                </>
                            ),

                        }}
                    /> : <></>}
                </Grid>

                <Dialog
                    onClose={(event, reason) => {
                        if (reason !== "backdropClick") {
                            handleCloseForm();
                        }
                    }}
                    aria-labelledby="litigation-form--registration"
                    open={openDialog}
                    maxWidth={"lg"}
                    disableEscapeKeyDown
                >
                    <DialogTitle id="dialogTitleTxt">{custDialogTitle}</DialogTitle>
                    <div style={{ position: "absolute", right: "1%" }}>
                        <Tooltip title="Close">
                            <IconButton onClick={handleCloseForm}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container spacing={2}>
                                {/*<Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1">*/}
                                {/*    <Autocomplete*/}
                                {/*        className="lightInputFields hover-lightblue"*/}
                                {/*        id="combo-box-demo"*/}
                                {/*        fullWidth*/}
                                {/*        value={customerObj}*/}
                                {/*        inputValue={customerText}*/}
                                {/*        options={itemsCustomer}*/}
                                {/*        getOptionLabel={(option) => (option.customerCodeWithName ? option.customerCodeWithName : "")}*/}
                                {/*        onChange={handleChangeCustomer}*/}
                                {/*        onInputChange={handleChangeCustomerInput}*/}
                                {/*        renderInput={(params) => (*/}
                                {/*            <TextField*/}
                                {/*                {...params}*/}
                                {/*                label="Customer"*/}
                                {/*                variant="filled"*/}
                                {/*                // required*/}
                                {/*                error={errorCustomer}*/}
                                {/*                helperText={errorTextCustomer}*/}
                                {/*            />*/}
                                {/*        )}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1">
                                    <Autocomplete
                                        //freeSolo={options && options.length > 0 ? true : false}
                                        className="lightInputFields hover-lightblue"
                                        id="global-search"
                                        fullWidth
                                        //value={searchValue }
                                        //style={{ width: 200 }}
                                        disableClearable
                                        value={customerObj}
                                       
                                        inputValue={customerText}
                                        options={itemsCustomer}
                                        getOptionLabel={(option) => (option.customerName1 ? option.customerName1 : "")}
                                       //forcePopupIcon={false}
                                       // noOptionsText={searchValue && searchValue.length > 0 ? "No Results Found" : "Type to search"}
                                        onChange={handleChangeCustomer}
                                        onInputChange={handleInputChange}
                                        classes={{
                                            option: classes.option,
                                        }}
                                       // filterOptions={(options,state)=>options }
                                        filteselectedoptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Customer"
                                               // placeholder="Search"
                                                value={searchValue }
                                                style={{ backgroundColor: "var(--light-sand)" }}
                                                variant="filled"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    type: "search",
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1">
                                    <Autocomplete
                                        className="lightInputFields hover-lightblue"
                                        id="combo-box-demo"
                                        fullWidth
                                        disableClearable
                                       value={codeObj}
                                        //value={code }
                                        inputValue={codeText}
                                        options={itemsCode}
                                        getOptionLabel={(option) => (option.customerCode ? option.customerCode : "")}
                                        onChange={handleChangeCode}
                                        onInputChange={handleChangeCodeInput}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Code"
                                                variant="filled"
                                                // required
                                                error={errorCustomer}
                                                helperText={errorTextCustomer}
                                            />
                                        )}
                                    />
                                </Grid>


                                {/*<Grid item xs={12} sm={6} md={4}>*/}
                                {/*    */}{/*<TextField*/}
                                {/*    */}{/*    className=""*/}
                                {/*    */}{/*    fullWidth*/}
                                {/*    */}{/*    name="code"*/}
                                {/*    */}{/*    label="Code"*/}
                                {/*    */}{/*    variant="filled"*/}
                                {/*    */}{/*    value={codeVal}*/}
                                {/*    */}{/*    // helperText={firstNameHelperText}*/}
                                {/*    */}{/*    //onChange={codeValChange}*/}
                                {/*    */}{/*    id="code"*/}
                                {/*    */}{/*/>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel shrink htmlFor="bootstrap-input" >*/}
                                {/*            Code*/}
                                {/*        </InputLabel>*/}
                                {/*        <Select*/}
                                {/*            label="Technical Status"*/}
                                {/*            id=""*/}
                                {/*            name=""*/}
                                {/*            className=""*/}
                                {/*            fullWidth*/}
                                {/*            value={codeVal}*/}
                                {/*            onChange={codeValChange}*/}
                                {/*            input={<BootstrapInput />}*/}
                                {/*        >*/}
                                {/*            {code.map((item) => (*/}
                                {/*                <MenuItem key={item.id} value={item.id}>*/}
                                {/*                    {item.countryId}*/}
                                {/*                </MenuItem>*/}
                                {/*            ))}*/}
                                {/*        </Select>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}

                                <Grid item xs={12} sm={6} md={4}>
                                    {/*<TextField*/}
                                    {/*    className=""*/}
                                    {/*    fullWidth*/}
                                    {/*    name="country"*/}
                                    {/*    label="Country"*/}
                                    {/*    variant="filled"*/}
                                    {/*    value={countryVal}*/}
                                    {/*    // helperText={lastNameHelperText}*/}
                                    {/*    onChange={countryValChange}*/}
                                    {/*    id="country"*/}
                                    {/*/>*/}
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Country
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={countryVal}
                                            onChange={countryValChange}
                                            input={<BootstrapInput />}
                                        >
                                            {countryName.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.countryName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                </Grid>

                                {/*<Grid item xs={12} sm={6} md={4}>*/}
                                {/*    <TextField*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        name="state"*/}
                                {/*        label="State"*/}
                                {/*        variant="filled"*/}
                                {/*        value={stateVal}*/}
                                {/*        // helperText={emailHelperText}*/}
                                {/*        onChange={stateValChange}*/}
                                {/*        id="state"*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={6} md={4}>
                                   
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            State
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={stateVal}
                                            onChange={stateValChange}
                                            input={<BootstrapInput />}
                                        >
                                            {stateName.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.stateName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                </Grid>

                                {/*<Grid item xs={12} sm={6} md={4}>*/}
                                {/*    <TextField*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        name="region"*/}
                                {/*        label="Region"*/}
                                {/*        variant="filled"*/}
                                {/*        value={regionVal}*/}
                                {/*        // helperText={emailHelperText}*/}
                                {/*        onChange={regionValChange}*/}
                                {/*        id="region"*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                <Grid item xs={12} sm={6} md={4}>

                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Region
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={regionVal}
                                            onChange={regionValChange}
                                            input={<BootstrapInput />}
                                        >
                                            {regionName.map((item) => (
                                                <MenuItem key={item.regionMasterId} value={item.regionMasterId}>
                                                    {item.regionMasterName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>

                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Office
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={officeVal}
                                            onChange={officeValChange}
                                            input={<BootstrapInput />}
                                        >
                                            {officeName.map((item) => (
                                                <MenuItem key={item.officeMasterId} value={item.officeMasterId}>
                                                    {item.officeMasterName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>


                                </Grid>


                                {/*<Grid item xs={12} sm={6} md={4}>*/}
                                {/*    <TextField*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        name="office"*/}
                                {/*        label="Office"*/}
                                {/*        variant="filled"*/}
                                {/*        value={officeVal}*/}
                                {/*        // helperText={emailHelperText}*/}
                                {/*        onChange={officeValChange}*/}
                                {/*        id="office"*/}
                                {/*    />*/}
                                {/*</Grid>*/}

                                {/*<Grid item xs={12} sm={6} md={4}>*/}
                                {/*    <TextField*/}
                                {/*        className=""*/}
                                {/*        fullWidth*/}
                                {/*        name="serviceType"*/}
                                {/*        label="Service Type"*/}
                                {/*        variant="filled"*/}
                                {/*        value={serviceTypeVal}*/}
                                {/*        // helperText={emailHelperText}*/}
                                {/*        onChange={serviceTypeChange}*/}
                                {/*        id="serviceType"*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                                {prodFilter == 1 ? (
                                <Grid item xs={12} sm={3} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Service Type
                                        </InputLabel>

                                        <Select

                                            label="service"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={serviceTypeVal}
                                            onChange={serviceTypeChange}
                                            input={<BootstrapInput />}
                                        >
                                            {service.map((item) => (
                                                <MenuItem key={item.serviceId} value={item.serviceId}>
                                                    {item.serviceName}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>





                                </Grid>
                                ) : (
                                    <></>
                                )}

                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poNumber"
                                        label="PO Number"
                                        variant="filled"
                                        value={poNumberVal}
                                        // helperText={emailHelperText}
                                        onChange={poNumberValChange}
                                        id="poNumber"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            // margin="normal"
                                            id="date-picker-dialog"
                                            label="PO Date"
                                            format="MM/dd/yyyy"
                                            placeholder="MM/dd/yyyy"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                            // maxDate={new Date()}
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>


                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poValue"
                                        label="PO Value"
                                        variant="filled"
                                        value={poValueVal}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeSoNo}
                                        id="po"
                                    />
                                    <div class="form-helper">{poHelper}</div>
                                </Grid>

                                {/* <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="poValidity"
                                        label="PO Validity"
                                        variant="filled"
                                        value={poValidityVal}
                                        // helperText={emailHelperText}
                                        onChange={poValidityValChange}
                                        id="poValidity"
                                    />
                                </Grid> */}

                                <Grid item xs={12} sm={6} md={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            // margin="normal"
                                            id="date-picker-dialog"
                                            label="PO Validity"
                                            format="MM/dd/yyyy"
                                            placeholder="MM/dd/yyyy"
                                            InputProps={{ readOnly: true }}
                                            fullWidth
                                            // maxDate={new Date()}
                                            value={poValidityDate}
                                            onChange={handlePOValDateChange}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                {prodFilter == 1 ? (
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="noOfBreakers"
                                        label="No Of Breakers"
                                        variant="filled"
                                        value={noOfBreakersVal}
                                        // helperText={emailHelperText}
                                        onChange={noOfBreakersValChange}
                                        id="noOfBreakers"
                                    />
                                </Grid>
                                ) : (
                                    <></>
                                )}
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="ticketNumber"
                                        label="Ticket Number"
                                        variant="filled"
                                        value={ticketNumberVal}
                                        // helperText={emailHelperText}
                                        onChange={ticketNumberValChange}
                                        id="ticketNumber"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="soNumber"
                                        label="SO Number"
                                        variant="filled"
                                        value={soNumberVal}
                                        // helperText={emailHelperText}
                                        onChange={handleChangeSoNo}
                                        id="rollnumber"
                                        
                                    />
                                    <div class="form-helper">{rollHelper}</div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="assignedResource"
                                        label="Assigned Resource"
                                        variant="filled"
                                        value={assignedResourceVal}
                                        // helperText={emailHelperText}
                                        onChange={assignedResourceValChange}
                                        id="assignedResource"
                                    />
                                </Grid>

                                {/* <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        className=""
                                        fullWidth
                                        name="technicalStatus"
                                        label="Technical Status"
                                        variant="filled"
                                        // value={emailVal}
                                        // helperText={emailHelperText}
                                        // onChange={handleEmailChange}
                                        id="technicalStatus"
                                    />
                                </Grid> */}

                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Technical Status
                                        </InputLabel>
                                        <Select
                                            label="Technical Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={techStatus}
                                            onChange={techStatusChange}
                                            input={<BootstrapInput />}
                                        >
                                            {techStatusMaster.map((item) => (
                                                <MenuItem key={item.epTechnicalStatusId} value={item.epTechnicalStatusId}>
                                                    {item.technicalStatusDescription}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Commercial Status
                                        </InputLabel>
                                        <Select
                                            label="Commercial Status"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={commercialStatus}
                                            onChange={commercialStatusChange}
                                            input={<BootstrapInput />}
                                        >
                                            {commercialStatusMaster.map((item) => (
                                                <MenuItem key={item.epCommercialStatusId} value={item.epCommercialStatusId}>
                                                    {item.commercialstatusDescription}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {prodFilter == 2 ? (

                                    <Grid item xs={12} sm={6} md={4}>
                                        {/*<TextField*/}
                                        {/*    className=""*/}
                                        {/*    fullWidth*/}
                                        {/*    name="assignedResource"*/}
                                        {/*    label="Reason for order win"*/}
                                        {/*    variant="filled"*/}
                                        {/*    value={resVal}*/}
                                        {/*    // helperText={emailHelperText}*/}
                                        {/*    onChange={resValChange}*/}
                                        {/*    id="assignedResource"*/}
                                        {/*/>*/}
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input" >
                                                Reason for order win
                                            </InputLabel>
                                            <Select
                                                label="Technical Status"
                                                id=""
                                                name=""
                                                className=""
                                                fullWidth
                                                value={resVal}
                                                onChange={resValChange}
                                                input={<BootstrapInput />}
                                            >
                                                {res.map((item) => (
                                                    <MenuItem key={item.epReasonForOrderWinId} value={item.epReasonForOrderWinId}>
                                                        {item.epReasonForOrderWinName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 2 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poValue"
                                            label="BreakerQty"
                                            variant="filled"
                                            value={breakVal}
                                            // helperText={emailHelperText}
                                            onChange={breakValChange}
                                            id="poValue"
                                        />
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 2 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        {/*<TextField*/}
                                        {/*    className=""*/}
                                        {/*    fullWidth*/}
                                        {/*    name="assignedResource"*/}
                                        {/*    label="Old Make Breaker"*/}
                                        {/*    variant="filled"*/}
                                        {/*    value={oldVal}*/}
                                        {/*    // helperText={emailHelperText}*/}
                                        {/*    onChange={oldValChange}*/}
                                        {/*    id="assignedResource"*/}
                                        {/*/>*/}
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input" >
                                                Old Make Breaker
                                            </InputLabel>
                                            <Select
                                                label="Technical Status"
                                                id=""
                                                name=""
                                                className=""
                                                fullWidth
                                                value={oldVal}
                                                onChange={oldValChange}
                                                input={<BootstrapInput />}
                                            >
                                                {old.map((item) => (
                                                    <MenuItem key={item.epOldMakeBreakerId} value={item.epOldMakeBreakerId}>
                                                        {item.epOldMakeBreakerName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 2 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        {/*<TextField*/}
                                        {/*    className=""*/}
                                        {/*    fullWidth*/}
                                        {/*    name="assignedResource"*/}
                                        {/*    label="New Breaker Model"*/}
                                        {/*    variant="filled"*/}
                                        {/*    value={newVal}*/}
                                        {/*    // helperText={emailHelperText}*/}
                                        {/*    onChange={newValChange}*/}
                                        {/*    id="assignedResource"*/}
                                        {/*/>*/}
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input" >
                                                New Breaker Model
                                            </InputLabel>
                                            <Select
                                                label="Technical Status"
                                                id=""
                                                name=""
                                                className=""
                                                fullWidth
                                                value={newVal}
                                                onChange={newValChange}
                                                input={<BootstrapInput />}
                                            >
                                                {newBreak.map((item) => (
                                                    <MenuItem key={item.epNewBreakerModelId} value={item.epNewBreakerModelId}>
                                                        {item.epNewBreakerModelName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 2 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poValue"
                                            label="Notification Number"
                                            variant="filled"
                                            value={notVal}
                                            // helperText={emailHelperText}
                                            onChange={notValChange}
                                            id="poValue"
                                        />
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 2 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poValue"
                                            label="Retrofit Number"
                                            variant="filled"
                                            value={retroVal}
                                            // helperText={emailHelperText}
                                            onChange={retroValChange}
                                            id="poValue"
                                        />
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 3 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                 Type
                                            </InputLabel>

                                            <Select
                                                label="Model"
                                                id=""
                                                name=""
                                                className=""
                                                fullWidth
                                                value={typeVal}
                                                onChange={handleType}
                                                input={<BootstrapInput />}
                                            >
                                                {types.map((item) => (
                                                    <MenuItem key={item.typeMasterId} value={item.typeMasterId}>
                                                        {item.typeMasterName}
                                                    </MenuItem>
                                                ))}

                                            </Select>


                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <></>
                                )}

                                {prodFilter == 3 || prodFilter == 4 ? (
                                    <Grid item xs={12} sm={3} md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Order Type
                                            </InputLabel>

                                            <Select
                                                label="Model"
                                                id=""
                                                name=""
                                                className=""
                                                fullWidth
                                                value={orderValue}
                                                onChange={handleOrder}
                                                input={<BootstrapInput />}
                                            >
                                                {order.map((item) => (
                                                    <MenuItem key={item.epOrderTypeId} value={item.epOrderTypeId}>
                                                        {item.epOrderTypeName}
                                                    </MenuItem>
                                                ))}

                                            </Select>


                                        </FormControl>
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 3 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poValue"
                                            label="Meters Make"
                                            variant="filled"
                                            value={meterVal}
                                            // helperText={emailHelperText}
                                            onChange={meterValChange}
                                            id="poValue"
                                        />
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 3 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poValue"
                                            label="License Quantity"
                                            variant="filled"
                                            value={licenseVal}
                                            // helperText={emailHelperText}
                                            onChange={licenseValChange}
                                            id="poValue"
                                        />
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 3 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poValue"
                                            label="Digi Partner"
                                            variant="filled"
                                            value={digiVal}
                                            // helperText={emailHelperText}
                                            onChange={digiValChange}
                                            id="poValue"
                                        />
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 4 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poValue"
                                            label="Total Fronts"
                                            variant="filled"
                                            value={totalVal}
                                            // helperText={emailHelperText}
                                            onChange={totalValChange}
                                            id="poValue"
                                        />
                                    </Grid>
                                ) : (
                                    <></>
                                )}
                                {prodFilter == 4 ? (
                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField
                                            className=""
                                            fullWidth
                                            name="poValue"
                                            label="Overall Qty Services"
                                            variant="filled"
                                            value={overallVal}
                                            // helperText={emailHelperText}
                                            onChange={overallValChange}
                                            id="poValue"
                                        />
                                    </Grid>
                                ) : (
                                    <></>
                                )}

                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1">
                                    <form
                                        className="file-upload-form"
                                    >
                                        <TextField
                                            className="file-upload"
                                            fullWidth
                                            variant="filled"
                                            name="fileUpload"
                                            label="Documents Upload"
                                            type="file"
                                            inputProps={{
                                                multiple: true,
                                            }}
                                            onChange={fileUploadHandler}
                                            key={inputFileValue}
                                        />
                                    </form>
                                </Grid>
                                {/* <Button
                                    href={fileAttachments}
                                    color="transparent"
                                    target="_blank"
                                    download
                                >
                                    <Tooltip title="Download Attachment">
                                        <DownloadIconOutlined />
                                    </Tooltip>
                                </Button> */}

                                <Grid xs={12} alignItems="center">
                                    {fileAttachments.length > 0 ? <MaterialTable
                                        title="Attachments"
                                        fullWidth
                                        columns={attachColumns}
                                        data={fileAttachments}
                                        icons={tableIconsInputScreen}
                                        options={{ tableOptionsUser, search: false }}
                                    /> : <></>}
                                </Grid>

                                {/* <Grid item xs={12} sm={6} md={4} fullWidth className="d-flex Width-100 mt-1 btnCenter">
                                    <Button
                                        type="submit"
                                        className="pt-button--primary"
                                        id="fileUpload"
                                    >
                                        Upload
                                    </Button>
                                </Grid> */}
                            </Grid>

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            className="pt-button--secondary"
                            id="addUserSave"
                            onClick={
                                dialogSubmitBtn === "Save" ? handleSaveForm : handleUpdateForm
                            }
                        >
                            {dialogSubmitBtn}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="dialogTitleTxt">Delete User</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to Delete this Po Entry ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={deletePoEntryRow}
                            className="pt-button--primary"
                        >
                            Ok
                        </Button>
                        <Button
                             onClick={handleCloseDeleteDialog}
                            className="pt-button--secondary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

            </Grid>

            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
            {isLoader ? <Loader loaderText="Loading...!" /> : <></>}

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );

}


