import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Toolbar,
    IconButton,
    InputBase,
    Tooltip,
    Zoom,
} from "@material-ui/core";
import SideDrawer from "../SideDrawer/SideDrawer";
import SearchIcon from "@material-ui/icons/Search";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SiemensLogoGreen from "../../assets/images/siemens-logo-petrol.svg";
//import { storeUserDetailsAction } from "../../../Redux/Actions/actions";
import { useDispatch, useSelector } from "react-redux";
import "./Header.scss";

import PasswordIcon from "@material-ui/icons/VpnKey";
import { useHistory } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import IntraLogo from "../../assets/images/sie-intranet-black-rgb.svg";
import { spocEmail } from "../../Utilities/Utility";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { storeUserDetailsAction } from "../../Redux/Actions/actions";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    appStyle: {
        backgroundColor: `var(--white)`,
        color: `var(--dark-blue)`,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        /*display: 'none',*/
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    search: {
        display: "none",
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: `transparent`,
    },
    inputRoot: {
        color: "inherit",
        backgroundColor: "var(--light-sand)",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
        [theme.breakpoints.only("xs")]: {
            width: "2ch",
        },
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
}));

function logoutUser(auth, dispatch) {
    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((names) => {
        names.forEach((name) => {
            caches.delete(name);
        });
    });
    // if (auth !== undefined) {
    //   //auth.removeUser();
    //   // // dispatch(saveAccessTokenAction(''));
    //   dispatch(storeUserDetailsAction(""));

    // }
    window.location.href = "/";
}

//The "accountHolderDetails" must be updated with the credentials received from MyID

export default function Header() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const auth = useAuth();
    // let history = useHistory();

    let userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const headerType = useSelector((state) => state.custHeaderReducer);

    function getPasswordValidity() {
        let dateDiffDays = !userDetails.passwordChangedOn
            ? 0
            : Math.round(
                (new Date() - new Date(userDetails.passwordChangedOn)) /
                (1000 * 60 * 60 * 24)
            );
        let validityTill = 90 - dateDiffDays;
        return validityTill;
    }
    let [appHeaderText] = useState(
        window.innerWidth > 720 ? "EPQuotation Tool" : "EPQuotationTool"
    );

    return (
        <div className="hidden-print">
        <div className={classes.grow}>
            <AppBar position="fixed" className={classes.appStyle}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        style={{ margin: "0 0 5px 0" }}
                    >
                        <SideDrawer />
                    </IconButton>

                    {headerType === "defaultHeader" ? (
                        <Link to="/">
                            <img
                                src={SiemensLogoGreen}
                                alt="Siemens"
                                className="official_logo"
                            />
                        </Link>
                    ) : (
                        <Link to="/">
                            <img
                                src={SiemensLogoGreen}
                                alt="Siemens"
                                className="official-logo"
                            />
                        </Link>
                    )}

                    <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon className="header-search" />
                        </div>
                        <InputBase
                            placeholder="Search"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div>
                    <div className={classes.grow}>
                        <h2
                            className="text-center"
                            style={{
                                padding: "0 8rem 0 0",
                            }}
                        >
                            {appHeaderText}
                            {/* <img
                src={IntraLogo}
                alt="Intra Logo"
                className="ssiat-intra-logo"
              /> */}
                        </h2>
                    </div>
                    <div className={classes.sectionDesktop}></div>

                    {/*  // {headerType === "defaultHeader" ? (
            //     userDetails.roleId === 2 && (
            //         <>
            //             {userDetails.roleId === 2 && userDetails.passwordChangedOn ? (
            //                 <>
            //                     {getPasswordValidity() <= 0 ? (
            //                         <p className="clr-red">
            //                             <i>
            //                                 Your password has been expired. Please change your
            //                                 password immediately.
            //                             </i>
            //                         </p>
            //                     ) : getPasswordValidity() <= 15 ? (
            //                         <p className="clr-red">
            //                             <i>
            //                                 Your password will be expired in next{" "}
            //                                 {getPasswordValidity()} days. Please change your
            //                                 password before expiry.
            //                             </i>
            //                         </p>
            //                     ) : (
            //                         <p>
            //                             <i>
            //                                 Your password will be expired in next{" "}
            //                                 {getPasswordValidity()} days.
            //                             </i>
            //                         </p>
            //                     )}
            //                 </>
            //             ) : (
            //                 <></>
            //             )}

            //             <Tooltip
            //                 title="Change Password"
            //                 TransitionComponent={Zoom}
            //                 enterDelay={100}
            //                 leaveDelay={100}
            //             >
            //                 <IconButton
            //                 >
            //                     <PasswordIcon />
            //                 </IconButton>
            //             </Tooltip>
            //         </>
            //     )
            // ) : (
            //     <></>
                        // )}*/}

                    {
                        <>
                            <h4>{userDetails.roleName}</h4>
                            {/*<Tooltip*/}
                            {/*  title="Having issues? Contact us"*/}
                            {/*  TransitionComponent={Zoom}*/}
                            {/*  enterDelay={100}*/}
                            {/*  leaveDelay={100}*/}
                            {/*>*/}
                            {/*  <IconButton*/}
                            {/*    className="header-icon"*/}
                            {/*    href={"mailto:" + spocEmail}*/}
                            {/*  >*/}
                            {/*    <HelpOutlineIcon />*/}
                            {/*  </IconButton>*/}
                            {/*</Tooltip>*/}
                            <Tooltip
                                title="Logout"
                                TransitionComponent={Zoom}
                                enterDelay={100}
                                leaveDelay={100}
                                onClick={logoutUser}
                            >
                                <IconButton className="header-icon">
                                    <ExitToAppIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                        // headerType === "defaultHeader" && (
                        // )
                    }
                </Toolbar>
            </AppBar>
            </div>

        </div>
    );
}
