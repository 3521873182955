import { baseApiURL } from "../Utilities/Utility";
import axios from "axios";
import POEntry from "../Screens/POEntryPage/POEntryPage";

export function QuotationDataUpload(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {
            

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: formData,
    };
    return fetch(baseApiURL + "/Quotation/UploadQuotationDatafile?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}


//export function GetAll(siteToken,
//    azureToken,
//    userId,
//    userEmail,
//    gid,
//    roleName,
//    roleId) {
//    const options = {
//        method: "get",
//        url: baseApiURL + "/Quotation/GetAllQuotationData",
//        headers: {
//            Authorization: "Bearer " + siteToken.token,
//            AzureToken: azureToken,
//            UserEmail: userEmail,
//            RoleName: roleName,
//            Gid: gid,
//            Roleid: roleId,
//        },

//        params: {
//            EncdUserId: encodeURIComponent(userId),
//        }

//    };
//    return (
//        axios(options)
//            .then((response) => {
//                return response.data.result;
//            })
//            .catch((error) => {
//                console.log("Error : ", error);
//            })
//    );
//}
export function GetAll(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Quotation/GetAllQuotationData?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}

export function GetFilter(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,poentryid, customer, serviceId, customerPo) {
    const options = {
        method: "get",
        url: baseApiURL + "/Quotation/GetQuotationBasedOnFilters?poentryid=" + poentryid + "&Customer=" + customer + "&ServiceId=" + serviceId + "&CutomerPo=" + customerPo + "&EncdUserId=" + encodeURIComponent(userId),
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
        
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function downloadPDFCertificate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,poentryid) {
    const options = {
        method: "post",
        url: baseApiURL + "/Quotation/GetSilverReport?EncdUserId=" + encodeURIComponent(userId) + "&poentryid=" + poentryid,
        responseType: "arraybuffer",
        headers: {
            

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

    };
    return axios(options)
        .then((response) => {
            return response;
            console.log(response);
        })
        .catch((error) => {
            return error;
        });

}
export function downloadGoldCertificate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, custId, poentryid) {
    const options = {
        method: "post",
        url: baseApiURL + "/Quotation/GoldReport?EncdUserId=" + encodeURIComponent(userId) +"&poentryid=" + poentryid + "&custId=" + custId,
        responseType: "arraybuffer",
       
        headers: {
            

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

    };
    return axios(options)
        .then((response) => {
            return response;
            console.log(response);
        })
        .catch((error) => {
            return error;
        });

}
export function downloadPDF(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, quotationid) {
    const options = {
        method: "post",
        url: baseApiURL + "/Quotation/SilverReportOne?EncdUserId=" + encodeURIComponent(userId) + "&quotationId=" + quotationid ,
        responseType: "arraybuffer",
        headers: {
            

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

    };
    return axios(options)
        .then((response) => {
            return response;
            console.log(response);
        })
        .catch((error) => {
            return error;
        });

}
export function downloadGold(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, quotationid) {
    const options = {
        method: "post",
        url: baseApiURL + "/Quotation/GoldReportOne?EncdUserId=" + encodeURIComponent(userId) + "&quotationId=" + quotationid,
        responseType: "arraybuffer",
        mode: "cors",
        headers: {
            

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            userId: userId,
            RoleName: roleName,
        },

    };
    return axios(options)
        .then((response) => {
            return response;
            console.log(response);
        })
        .catch((error) => {
            return error;
        });

}
export function GetQuotationReport(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId,poentryid) {
    const options = {
        responseType: "arraybuffer",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
      
    };
    return axios
        .get(
            baseApiURL + "/Quotation/GetQuotationReport?poentryid="+ poentryid,
            options
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });

}
export function GetGridData(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },

    };
    return fetch(baseApiURL + "/Quotation/GetPoEntryDetailsForGSR?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function QuotationUpload(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, formData) {
    const options = {
        method: "post",
        headers: {
           

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        
        body: formData,
    };
    return fetch(baseApiURL + "/Quotation/UploadQuotationfile?EncdUserId=" + encodeURIComponent(userId), options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function GetView(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, poentryId) {
    const options = {
        method: "get",
        url: baseApiURL + "/Quotation/GetQuotationFromView?poentryId=" +poentryId +"&EncdUserId="
            +encodeURIComponent(userId),
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
       
    };
    return (
        axios(options)
            .then((response) => {
                return response.data.result;
            })
            .catch((error) => {
                console.log("Error : ", error);
            })
    );
}
export function GetGSRYear(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId ,yearId) {
    const options = {
        method: "get",
        headers: {
            Authorization: "Bearer " + siteToken.token,
            AzureToken: azureToken,
            UserEmail: userEmail,
            RoleName: roleName,
            Gid: gid,
            Roleid: roleId,
        },
       

    };
    return fetch(baseApiURL + "/Quotation/GetGSRbyYear?EncdUserId=" + encodeURIComponent(userId)  + "&yearId=" + yearId, options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function QuotationDate(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, UserId,PoEntryId,ServiceCompletedOn) {
    var formdata = {
        UserId: UserId,

        PoEntryId: PoEntryId,
        ServiceCompletedOn: ServiceCompletedOn,
        
    };
    const options = {
        method: "post",
        headers: {
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",

            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },
        body: JSON.stringify(formdata)
    };
    return fetch(baseApiURL + "/Quotation/UpdateServiceCompletedOn?EncdUserId=" + encodeURIComponent(userId), options)
        /* console.log(options)*/
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
}
export function downloadOffer(siteToken,
    azureToken,
    userId,
    userEmail,
    gid,
    roleName,
    roleId, ref) {
    const options = {
        method: "get",
       // url: baseApiURL + "/Offer/DownloadReport?EncdUserId=" + encodeURIComponent(userId) + "&offerReferenceNumber=" + ref,
       // responseType: "arraybuffer",
        headers: {


            Authorization: "Bearer " + siteToken.token,
            UserEmail: userEmail,
            Gid: gid,
            Roleid: roleId,
            AzureToken: azureToken,
            RoleName: roleName,
        },

    };
    return fetch(baseApiURL + "/Offer/DownloadReport?EncdUserId=" + encodeURIComponent(userId) + "&offerReferenceNumber=" + ref,options)
        .then((response) => response.json())
        .then((data) => {
            return data.result;
        })
        .catch((error) => {
            console.log("Error : ", error);
        });


}
