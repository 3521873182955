import React, { useEffect, useState } from "react";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import * as FileSaver from 'file-saver';
//import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import { BootstrapInput } from "../../Utilities/Utility";
import DownloadIcon from "@material-ui/icons/ArrowDownwardOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import InsertQuotation from "../../Screens/Index/Index";
import { DeleteOutlined } from "@material-ui/icons";

import SnackbarCustom from "../../Components/Snackbar/Snackbar";

import { lighttheme } from "../../../src/Themes/LightTheme";
import {
    TextField,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
    IconButton
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../../src/Themes/LightTheme";
import { MenuItem, Tooltip } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import "./Display.scss";
//import { getCustomer, getCustomerPo, getService } from "../../Redux/APIs/api_epwork";
import {
    getService, deleteView
} from "../../APIs/api_Insert";
import { QuotationDataUpload, GetAll, GetFilter, downloadPDFCertificate, GetQuotationReport, GetView, downloadPDF, downloadGold } from "../../APIs/api_Quotation";


export default function Display(props) {
    let [isLoader, setIsLoader] = useState(true);
    const api = axios.create({
        baseURL: "api/Quotation",
    });
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };
    const [openLoader, setopenLoader] = useState(false);
    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const [lastService, setLastService] = useState();
    const [data, setData] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [customer, setCustomer] = useState("");
    const [customerPo, setCustomerPo] = useState("");
    //const [service, setService] = useState([]);
    //const [serviceValue, setServiceValue] = useState("");
    const [service, setService] = useState([]);
    const [serviceValue, setServiceValue] = useState("");
    const [serviceId, setServiceId] = useState();
    const [openForm, setopenForm] = useState(false);
    const [editRowData, seteditRowData] = useState([]);
    const [mode, setMode] = useState("");
    const [poentryid, setPoEntryId] = useState(0)
    const handleCustomer = (e) => {
        setCustomer(e.target.value)
    }
    const handleCustomerPo = (e) => {
        setCustomerPo(e.target.value);
    }
    const handleService = (e) => {
        setServiceValue(e.target.value);
        setServiceId(e.target.value);
    }
    function atobSecure(value) {
        const { atob } = require("abab");
        let arrValue = atob(atob(value)).split(" ");
        let valueActual = arrValue[0];
        return valueActual;
    }
    function getURLParameter(sParam) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split("&");
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split("=");
            var value = "";
            for (var j = 0; j < sParameterName.length; j++) {
                if (sParameterName[j] == sParam) {
                    for (var k = j + 1; k < sParameterName.length; k++) {
                        value = value + sParameterName[k];
                    }
                    break;
                }
                //return sParameterName[1];
            }
            return value;
        }
    }
    var encryptedId = getURLParameter("id");
    useEffect(() => {
        if (encryptedId) {
            viewDisplay();
            setPoEntryId(atobSecure(encryptedId));
            Service();
        }
       
        else {
            setPoEntryId(0);
            Service();
            GridData();
        }
       
       
    }, [encryptedId]);
   
    function Service() {
        console.log("hi");
        setIsLoader(true);

        getService(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,)
            .then((response) => {
                console.log("service",response);
                setService(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    
    function filter() {
        console.log("hi");
        setIsLoader(true);
        //if (encryptedId != null) {
        //    setPoEntryId(atobSecure(encryptedId));
        //}
        //else {
        //    setPoEntryId(0);
        //}
        console.log("id", poentryid);
        GetFilter(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, poentryid, customer, serviceId, customerPo )
            .then((response) => {
                console.log(response);
                setData(response);
                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });
    }
    function clear() {
        setCustomerPo("");
        setCustomer("");
        setServiceValue(null);
    }
    function viewDisplay() {
        console.log("hi");
        setIsLoader(true);

        GetView(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, atobSecure(encryptedId))
            .then((response) => {
                console.log("gridres", response);

                setData(response);
                

                setIsLoader(false);
            })
            .catch((error) => {
                handleSnackOpen("Error : " + error, "error");
                setIsLoader(false);
            });


    }
    function GridData() {
        GetAll(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("hi");
                console.log(response);
                setData(response);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    }
    function getQuotationListReport() {
        setIsLoader(true);
        GetQuotationReport(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, poentryid)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("No Records are Found ");
                        setAlertType("success");
                    } else {
                        var blob = new Blob([response.data], {
                            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        });
                        FileSaver.saveAs(blob, "QuotationList.xlsx");
                        setIsLoader(false);
                        handleSnackOpen();
                        setSnackMessage("Report Downloaded");
                        setAlertType("success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen();
                    setSnackMessage("Report download failed");
                    setAlertType("error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen();
                setSnackMessage("Error: ", error);
                setAlertType("error");
            });
    }

    const handleCertFileDownload = (rowData) => {
        setIsLoader(true);
        //if (rowData.fileUrl == null) {
        //    setIsLoader(false);
        //    handleSnackOpen("Certificate Not Uploaded", "warning");
        //    return false;
        //}
        console.log(rowData.ourRef);
        
        var ourref = rowData.ourRef;
        var customerpo = rowData.cutomerPo;
        var quotationid = rowData.quotationId;
        downloadPDF(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, quotationid)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen("No Records are Found", "success");
                    } else {
                        
                        const fileName = "SilverReport";
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, fileName + ".pdf");
                        setIsLoader(false);
                        handleSnackOpen("Report downloaded.", "success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen("Report not Available to Download.", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }
    const handleCertFileDownloadGold = (rowData) => {
        setIsLoader(true);
        //if (rowData.fileUrl == null) {
        //    setIsLoader(false);
        //    handleSnackOpen("Certificate Not Uploaded", "warning");
        //    return false;
        //}
        console.log(rowData.ourRef);
        var quotationid = rowData.quotationId;
        var ourref = rowData.ourRef;
        var customerpo = rowData.cutomerPo;
        downloadGold(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, quotationid)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.byteLength <= 200) {
                        setIsLoader(false);
                        handleSnackOpen("No Records are Found", "success");
                    } else {

                        const fileName = "GoldReport";
                        var blob = new Blob([response.data], {
                            type: "application/pdf",
                        });
                        FileSaver.saveAs(blob, fileName + ".pdf");
                        setIsLoader(false);
                        handleSnackOpen("Report downloaded.", "success");
                    }
                } else {
                    setIsLoader(false);
                    handleSnackOpen("Report not Available to Download.", "error");
                }
            })
            .catch((error) => {
                setIsLoader(false);
                handleSnackOpen("Error while downloading Report.", "error");
            })
    }

    const columns = [
        { title: "sl_no", field: "slNo" },
        {
            title: "customer_po", field: "cutomerPo"
        },
        { title: "our_ref", field: "ourRef" },
        {
            title: "date", field: "date", type: "date",

            dateSetting: { locale: "en-GB" },
},
        { title: "service", field: "service" },
        { title: "customer", field: "customer" },
        { title: "location", field: "location" },
        { title: "contact_person", field: "contactPerson" },
        { title: "mobile", field: "mobile" },
        { title: "e_mail", field: "eMail" },
        { title: "panel", field: "panel" },
        { title: "feeder_name", field: "feederName" },
        { title: "model", field: "model" },
        { title: "mlfb", field: "mlfb" },
        { title: "Manufactured On", field: "manufacturedon" },
        { title: "z_options", field: "zOptions" },
        { title: "id_no", field: "idNo" },
        { title: "breaker_rating", field: "breakerRating" },
        { title: "size", field: "size" },
        { title: "pole", field: "pole" },
        { title: "breaker", field: "breaker" },
        { title: "etu", field: "etu" },
        { title: "etu_serial_no", field: "etuSerialNo" },
        { title: "last_serviced_on", field: "lastServicedOn" },
        { title: "in", field: "in" },
        { title: "l_tripping_ir", field: "lTrippingIr" },
        { title: "long_time_current", field: "longTimeCurrent" },
        { title: "l_time_lag_tr", field: "lTimeLagTr" },
        { title: "memory", field: "memory" },
        { title: "short_time_isd", field: "shortTimeIsd" },
        { title: "short_time_current_isd", field: "shortTimeCurrentIsd" },
        { title: "short_time_delay_tsd", field: "shortTimeDelayTsd" },
        { title: "i_tripping_ii", field: "iTrippingIi" },
        { title: "i_tripping_current_ii", field: "iTrippingCurrentIi" },
        { title: "n_tripping_in", field: "nTrippingIn" },
        { title: "i_n", field: "iN1" },
        { title: "g_ct", field: "gCt" },
        { title: "g_tripping_ig", field: "gTrippingIg" },
        { title: "g_alarm_ig", field: "gAlarmIg" },
        { title: "time_delay_tg_trip", field: "timeDelayTgTrip" },
        { title: "etu_status", field: "etuStatus" },
        { title: "ct_test", field: "ctTest" },
        { title: "mechanical_reclosing_lockout", field: "mechanicalReclosingLockout" },
        { title: "trip_unit", field: "tripUnit" },
        { title: "mechanical_interlock", field: "mechanicalInterlock" },
        { title: "racking_handle", field: "rackingHandle" },
        { title: "racking_mechanism", field: "rackingMechanism" },
        { title: "contact_erosion_indicator", field: "contactErosionIndicator" },
        { title: "arc_chutes", field: "arcChutes" },
        { title: "shutter", field: "shutter" },
        {
            title: "front facia", field: "frontfacia" },
        { title: "lamination_contacts", field: "laminationContacts" },
        { title: "guide_frame_terminals", field: "guideFrameTerminals" },
        { title: "contact_pressure", field: "contactPressure" },
        { title: "pole_set", field: "poleSet" },
        { title: "lubrication", field: "lubrication" },
        { title: "auxiliary_contact_block", field: "auxiliaryContactBlock" },
        { title: "spring_charging_manual", field: "springChargingManual" },
        { title: "spring_charging_motor", field: "springChargingMotor" },
        { title: "undervoltage", field: "undervoltage" },
        { title: "closing_coil", field: "closingCoil" },
        { title: "shunt_coil", field: "shuntCoil" },
        { title: "ready_to_close_interlock", field: "readyToCloseInterlock" },
        { title: "breaker_operations_manual", field: "breakerOperationsManual" },
        { title: "breaker_operations_electrical", field: "breakerOperationsElectrical" },
        { title: "mandatory_spares", field: "mandatorySpares" },
        { title: "recommended_spares", field: "recommendedSpares" },
        { title: "comments", field: "comments" },
        { title: "open_points", field: "openPoints" },
        { title: "overall_breaker_healthiness", field: "overallBreakerHealthiness" },
        { title: "tested_by", field: "testedBy" },
        {
            title: "contact_no_1", field: "contactNo1"
        },
        { title: "reviews_by", field: "reviewedBy" },
        { title: "contact_no_2", field: "contactNo2" },
        {
            title: "Silver Report",
            field: "product",
            editable: false,
            render: (rowData) => (
                <Button>
                    <DownloadIcon
                        className="checkIcon"
                        onClick={() => handleCertFileDownload(rowData)}
                    />
                </Button>
            ),
        },
        {
            title: "Gold Report",
            field: "product",
            editable: false,
            render: (rowData) => (
                <Button>
                    <DownloadIcon
                        className="checkIcon"
                        onClick={() => handleCertFileDownloadGold(rowData)}
                    />
                </Button>
            ),
        },





    ];
    function handleeditFormClose() {

        setopenForm(false);
        
    }
    function downloadRTCpdf(rowData) {
        let paramDetails = {};
        paramDetails = {
            "OurRef": rowData.ourRef,

        }
        api.post('/GetSilverReport', paramDetails, { responseType: 'arraybuffer' })
            .then(res => {
                var blob = new Blob([res.data], {
                    type: "application/pdf",

                });
                FileSaver.saveAs(blob, "RTCReportNew.pdf");
                console.log("Download PDF Clicked");
            });
    }
    function clickEdit(rowData) {
        console.log("edit data");
        console.log(rowData);
        setMode("edit");
        setopenForm(true);
        seteditRowData(rowData);

        //window.location.href = "/UpdateSlipInventory";
    }
    const goBackonClick = (obj) => {
        setopenForm(false);
        setopenLoader(false);
        console.log("obj", obj);
        viewDisplay()
        //if (obj) {
        //    GetViewSlipDetails();
        //    //let errorList = [];
        //    //if (obj.result.objResult) {
        //    //}
        //    //errorList.push(obj.result.responseMsg);
        //    //if (obj.result.response) {
        //    //    showMessage(errorList, true, 'success');
        //    //}
        //    //else {
        //    //    showMessage(errorList, true, 'error');
        //    //}
        //}
    }
    function GetEditButton(rowData) {
        return (
            <Tooltip title="Modify Quotation" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    function GetDeleteButton() {
        return (
            <Tooltip title="Delete" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <DeleteOutlined fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeleteRowData(null);
    };
    const handleDeleteIconClick = (rowData) => {
        setDeleteRowData(rowData);
        setDeleteDialogOpen(true);
    };
    function deletePoEntryRow() {
        deleteView(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, deleteRowData.quotationId)
            .then((response) => {
                console.log(response);
                //handleSnackOpen(response.responseMsg, "success");
                handleCloseDeleteDialog();
                handleSnackOpen(response.responseMsg, "success");
                //grid(userDetails.userId, deleteRowData.productId, deleteRowData.yearId);
                viewDisplay();

            })
            .catch((error) => {
                handleSnackOpen(error, "error");
            });
    }
    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        className="d-flex jc-space-bt"
                    >
                        <Grid item xs={12} className="text-left ml-1">
                            {/*<h2>View Quotation</h2>*/}
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        Customer
                                    </InputLabel>
                                    <BootstrapInput id="Sender-Name"
                                        name="Customer"
                                        onChange={handleCustomer}
                                        value={customer}

                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input">
                                        CustomerPo
                                    </InputLabel>
                                    <BootstrapInput id="Sender-Name"
                                        name="customerPo"
                                        onChange={handleCustomerPo}
                                        value={customerPo}

                                    />
                                </FormControl>
                            </Grid>

                            {/*<Grid item xs={12} sm={6} md={3}>*/}
                            {/*    <FormControl fullWidth>*/}
                            {/*        <InputLabel shrink htmlFor="bootstrap-input">Service</InputLabel>*/}
                            {/*        <Select*/}
                            {/*            labelId="developmenttype"*/}
                            {/*            id="developmenttype"*/}
                            {/*            name="developmenttype"*/}
                            {/*            nChange={handleService}*/}
                            {/*            value={serviceValue}*/}

                            {/*            input={<BootstrapInput />}*/}
                            {/*        >*/}
                            {/*            {service.map((item) => (*/}
                            {/*                <MenuItem key={item.serviceId} value={item.serviceId}>*/}
                            {/*                    {item.serviceName}*/}
                            {/*                </MenuItem>*/}
                            {/*            ))}*/}
                            {/*        </Select>*/}
                            {/*    </FormControl>*/}

                            {/*</Grid>*/}
                            <Grid item xs={12} sm={6} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel shrink htmlFor="bootstrap-input" >
                                        service
                                    </InputLabel>

                                    <Select

                                        label="service"
                                        id=""
                                        name=""
                                        className=""
                                        fullWidth
                                        value={serviceValue}
                                        onChange={handleService}
                                        input={<BootstrapInput />}
                                    >
                                        {service.map((item) => (
                                            <MenuItem key={item.serviceId} value={item.serviceId}>
                                                {item.serviceName}
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </FormControl>





                            </Grid>
                            <Grid className="mr-btm">
                                <Button className="pt-button--primary button-submit button-css search"
                                    variant="contained"
                                    onClick={filter }
                                >
                                    Search
                                </Button>
                            </Grid>
                            <Grid className="mr-btm">
                                <Button className="pt-button--primary button-submit button-css search"
                                    variant="contained"
                                    onClick={clear}
                                >
                                    Clear
                                </Button>
                            </Grid>

                        </Grid>
                        <div className="marginTop">
                            <Grid item xs={12}>
                                <MaterialTable
                                    title="Quotation Data"
                                    columns={columns}
                                    data={data}
                                    icons={tableIconsInputScreen}
                                    //isLoading={isLoader}
                                    //options={tableOptionsUser }

                                    options={{
                                       headerStyle: { background: '#d3d3d3' },
                                        exportButton: { csv: true },
                                        exportCsv: (columns, data) => {
                                            console.log("Report Icon Clicked");
                                            getQuotationListReport();
                                        },
                                        search: true,
                                        pageSize:10,
                                        //paginationPosition: "bottom",
                                        tableOptionsUser, maxBodyHeight: "700px", searchFieldAlignment: "left",
                                        toolbarButtonAlignment: "left",
                                        exportAllData: true,
                                        exportFileName: "QuotationList",
                                        emptyRowsWhenPaging: false,
                                    }}
                                    actions={[

                                        rowData => ({
                                            icon: GetEditButton,

                                            tisFreeAction: true,

                                            onClick: (event, rowData) => clickEdit(rowData)
                                        }),
                                        {
                                            icon: GetDeleteButton,
                                            tisFreeAction: true,
                                            onClick: (e, rowData) => {
                                                console.log("Row Data", rowData);
                                                handleDeleteIconClick(rowData);
                                            },
                                        },
                                    ]}
                                    
                                />
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <Dialog
                    open={openForm}
                    disableBackdropClick
                    maxWidth={"lg"}f
                    aria-labelledby="edit-dialog-title"
                    onClose={handleeditFormClose}
                >
                    <DialogContent>
                        <InsertQuotation clickgoback={goBackonClick} editData={editRowData} Mode={mode} ></InsertQuotation>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={handleCloseDeleteDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="dialogTitleTxt">Delete User</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure to Delete this Entry ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={deletePoEntryRow}
                            className="pt-button--primary"
                        >
                            Ok
                        </Button>
                        <Button
                            onClick={handleCloseDeleteDialog}
                            className="pt-button--secondary"
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
}