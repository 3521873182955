import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
    Select,
    MenuItem,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import {
    GetAddButton,

} from "../../Utilities/Utility";
import FormControl from "@material-ui/core/FormControl";
import MaterialTable from "material-table";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { BootstrapInput } from "../../Utilities/Utility";
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../Components/Loader/Loader";
import { blue } from "@material-ui/core/colors";
import PriceMaster_Template from "../../Common/PriceMaster Template.xlsx";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import { useSelector, useDispatch } from "react-redux";
import "../CreateQuotation/Create.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
import { CustomerDataUpload, GetAllCustomerList, getUpdateDetails, GetPriceList, PriceDataUpload, updatePrice, savePrice } from "../../APIs/api_Customer";
import { getOffer } from "../../APIs/api_PoEntry";
import * as XLSX from "xlsx";
import { offer, Type, year } from "../../Redux/Actions/actions";
export default function Price() {
    const [fileValue, setfileValue] = useState("");
    let [errorData, setErrorData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);

    const UID = useSelector((state) => state.saveUserIdReducer);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const [data, setData] = useState([]);
    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState("");
    const [isLoader, setIsLoader] = useState(false);
    const [nameHelper, setNameHelper] = useState("");
    const [flag, setFlag] = useState(false);
    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    //End - snackbar
    const [prodFilter, setProdFilter] = useState(0);
    const dispatch = useDispatch();
    const prodFilterChange = (e) => {
        setProdFilter(e.target.value);
        /*getRef(e.target.value)*/
        dispatch(offer(e.target.value))
     /*   dispatch(Type(e.target.name))*/
    }
    const [prodMasterData, setProdMasterData] = useState([]);
    const api = axios.create({
        baseURL: "api/Customer",
    });

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );

    useEffect(() => {
        GridData();
        getProdMaster();
        setProdFilter(0);
    }, []);

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const updateExcelHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleDialogMessage = (text) => {
        setDialogContent(text);
        setDialogOpen(true);
    };
    const handleClose = (e, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        if (
            typeof dialogContent === "string" &&
            dialogContent.toLowerCase().includes("success")
        ) {
            setInputFileValue(Date.now);
            setSelectedFile(null);
        }
        setDialogOpen(false);
        setInputFileValue(Date.now);
        setErrorData([]);
        setOpen(false);
    };
    const exportErrorData = () => {
        ExportCustomerErrorDataToExcel();
    };
    function ExportCustomerErrorDataToExcel() {
        const dataToDownload = errorData.map((row) => {
            return {

                epCustomerId: row.epCustomerId,
                customerName1: row.customerName1,
                customerCode: row.customerCode,



                remarks: row.remarks?.replace(".", ".\n "),

            };
        });
        let headers = ["sl_no", "customerName", "customerCode",
            "Remarks",

        ];
        //const workSheet = XLSX.utils.json_to_sheet(newData);
        let ws = XLSX.utils.book_new();
        let wb = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, dataToDownload, {
            origin: "A2",
            skipHeader: true,
            cellStyles: true,
        });
        ws["!cols"] = cellAdjustToContentsCustomer(dataToDownload);
        ws['!cols'] = [{ width: 20 }, { width: 20 }, { width: 20 }, { width: 150 }];
        XLSX.utils.book_append_sheet(wb, ws, "ErrorData");
        XLSX.write(wb, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(wb, "ExcelUploadErrors.xlsx");
    }
    function cellAdjustToContentsCustomer(data) {
        return [
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.epCustomerId ? c.epCustomerId?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerName1 ? c.customerName1?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) =>
                        c.customerCode ? c.customerCode?.toString()?.length : 0
                    )
                ),
            },
            {
                wch: Math.max(
                    ...data?.map((c) => (c.remarks ? c.remarks?.toString()?.length : 0))
                ),
            },
        ];
    }



    //const columns = [
    //    { title: "Name", field: "customerName" },
    //    {
    //        title: "Code", field: "customerCode"
    //    },
    //    { title: "Location", field: "customerLocation" },
    //    { title: "Address", field: "addess" },
    //    { title: "City", field: "city" },
    //    { title: "Pincode", field: "pincode" },
    //    { title: "Contact Person", field: "contactPerson" },
    //    { title: "Mobile", field: "mobile" },
    //    { title: "EmailId", field: "emailId" },
    //];

    const columns = [
        //{ title: "DistCh", field: "distCh" },
       // { title: "Div SlGrp", field: "div" },

        { title: "Offer Type", field: "offerName" },

        { title: "SPIRIDON Mat.No", field: "spiridonnumber" },
        { title: "MLFB", field: "mlfb" },
        // title: "CndTyp", field: "cndTyp" },
        {title:"Description", field:"description"},
        { title: "Rate", field: "rate" },
        {
            title: "RUnit", field: "rtUnit"
        },
        
        //{ title: "CndPrU", field: "cndPrU" },
        //{ title: "CndUnit", field: "cndUni" },
        //{ title: "StartDt", field: "startDt" },

    ];
    const onChange = (event) => {
        let valid;
        switch (event.target.id) {
           
            case "name":
                setSpirid(event.target.value);
                valid = /^[0-9]*$/.test(event.target.value);
                if (!valid) {
                    setNameHelper("Cannot contain special character and alphabets");
                    // setDisableButton(true);
                } else if (event.target.value.length === 0) {
                    setNameHelper("Cannot be left blank");
                    //setDisableButton(true);
                } else {
                    setNameHelper("");
                    // setDisableButton(false);
                }
                break;
            

            default:
                break;
        }
    };

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertClose = () => {
        setSelectedFile(null);
        setOpenAlertDialog(false);
        setIsLoading(false);
    };
    const [alertMessage, setAlertMessage] = useState("");
    const handleAlertOpen = (message) => {
        setOpenAlertDialog(true);
        setAlertMessage(message);
    };
    const handleErrorClose = () => {
        setOpenErrorDialog(false);
    };
    const [dist, setDist] = useState("");
    const handleChangeDist = (e) => {
        setDist(e.target.value);
    }
    const [div, setDiv] = useState("");
    const handleChangeDiv = (e) => {
        setDiv(e.target.value);
    }
    const [spirid, setSpirid] = useState("");
    const handleChangeSpirid= (e) => {
        setSpirid(e.target.value);
    }
    const [mlfb, setMlfb] = useState("");
    const handleChangeMlfb = (e) => {
        setMlfb(e.target.value);
    }
    const [desc, setDesc] = useState("");
    const handleChangeDesc= (e) => {
        setDesc(e.target.value);
    }
    const [cnd, setCnd] = useState("");
    const handleChangeCnd = (e) => {
        setCnd(e.target.value);
    }
    const [rate, setRate] = useState(null);
    const handleChangeRate = (e) => {
        setRate(e.target.value);
    }
    const [runit, setRunit] = useState("");
    const handleChangeRunit = (e) => {
        setRunit(e.target.value);
    }
    const [cndPru, setCndPru] = useState("");
    const handleChangeCndPru = (e) => {
        setCndPru(e.target.value);
    }
    const [cndUnit, setCndUnit] = useState("");
    const handleChangeCndUnit = (e) => {
        setCndUnit(e.target.value);
    }
    const [start, setStart] = useState(null);
    const handleChangeStart = (date) => {
        date.toISOString();
        setStart(date);
    }
    const [email, setEmail] = useState("");
    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    }
    const [openDia, setOpenDia] = useState(false);
    const handleCloseDia = () => {
        setOpenDia(false);
    }
    const [custId, setCustId] = useState(null);
    const [dialogSubmitBtn, setDialogSubmitBtn] = useState("Save");
    const [custDialogTitle, setCustDialogTitle] = useState("Add New MLFB");
    function submitValueDetails() {
        if (!prodFilter) {
            handleSnackOpen("Please select Product Type", "error");
            return;
        }
        setIsLoader(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("UserId", userDetails.userId);
        formData.append("OfferId", prodFilter);
        //prodFilter


        PriceDataUpload(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,formData)
            .then((response) => {
                console.log(response);
                if (response.response) {
                    setIsLoader(false);
                    GridData();
                    handleDialogMessage(response.responseMsg);

                } else {
                    setIsLoader(false);
                    setSelectedFile(null);
                    setInputFileValue(Date.now);
                    setErrorData(response.objResult);
                    handleDialogMessage(
                        response.responseMsgs && response.responseMsgs.length > 0
                            ? response.responseMsgs
                            : response.responseMsg
                    );
                }
            })
            .catch((error) => {
                setIsLoader(false);
                setSelectedFile(null);
                setInputFileValue(Date.now);
                handleDialogMessage("Exception in upload");
            });
    }
    const [priceId, setPriceId] = useState(null)
    const [prodId, setProdId] = useState(null)
    const dialogDraftValuesFn = (rowData) => {
        console.log("Row update Data", rowData);
        setFlag(true)
        setDist(rowData.distCh);
        setPriceId(rowData.priceMasterId)
        setProdId(rowData.offerId)
        setDiv(rowData.div);
        setCnd(rowData.cndTyp);
        //setName2(rowData.customerName2);
        setDesc(rowData.description)
        setMlfb(rowData.mlfb);
        setRate(rowData.rate);
        setRunit(rowData.rtUnit);
        setCndPru(rowData.cndPrU);
        setCndUnit(rowData.cndUni);
        setStart(rowData.startDt);
        setSpirid(rowData.spiridonnumber);
        //setMobile(rowData.mobile);
    }
    function submit() {
       // setIsLoader(true);
        //if (nameHelper != "") {
        //    handleSnackOpen("Check spiridon ", "error");
        //    return;
        //}
        //if (!prodFilter) {
        //    handleSnackOpen("Please select Product Type", "error");
        //    return;
        //}
        if (spirid === "" || spirid === null || spirid === undefined) {
            handleSnackOpen("spiridno is Empty", "error");
            return;
        }
        if (mlfb === "" || mlfb === null || mlfb === undefined) {
            handleSnackOpen("mlfb is Empty", "error");
            return;
        }
        if (rate === "" || rate === null || rate === undefined) {
            handleSnackOpen("Rate is Empty", "error");
            return;
        }
        if (runit === "" || runit === null || runit === undefined) {
            handleSnackOpen("runit is Empty", "error");
            return;
        }

        setIsLoader(true);

        let slipData = {
            PriceMasterId: priceId,
            OfferId: prodId,
            Spiridonnumber: spirid,
            Mlfb: mlfb,
            Description:desc,
            Rate: rate,
            RtUnit: runit,
           
            isActive: true,


        }
        console.log("slipData", slipData);
        //let errorList = [];
        //await api.post("/PostQuotationData/", slipData) 
        updatePrice(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,slipData)
            .then(response => {
                if (response.response) {

                    handleSnackOpen(response.responseMsg, "success");
                    GridData();
                    clear()
                    handleCloseDia();

                    setIsLoader(false);
                }
                else {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "error");
                    handleCloseDia();
                }
            })
            .catch(error => {
                handleSnackOpen("Error in saving.", "error");
                setIsLoader(false);
            })

    }
    function clear() {
        setSpirid("")
        setRate(null)
        setRunit("")
        setDesc("")
        setMlfb("")
        setNameHelper("")
        setFlag(false)
    }
    function save() {
        //setIsLoader(true);
        //if (nameHelper != "") {
        //    handleSnackOpen("Check spiridon ", "error");
        //    return;
        //}
        if (!prodFilter) {
            handleSnackOpen("Please select Product Type", "error");
            return;
        }

        if (spirid === "" || spirid === null || spirid === undefined) {
            handleSnackOpen("spiridno is Empty", "error");
            return;
        }
        if (mlfb=== "" || mlfb === null || mlfb === undefined) {
            handleSnackOpen("mlfb is Empty", "error");
            return;
        }
        if (rate === "" || rate === null || rate === undefined) {
            handleSnackOpen("Rate is Empty", "error");
            return;
        }
        if (runit === "" || runit=== null || runit === undefined) {
            handleSnackOpen("runit is Empty", "error");
            return;
        }
        setIsLoader(true);
        let slipData = {
            //PriceMasterId: priceId,
            OfferId: prodFilter,
            Spiridonnumber: spirid,
            Mlfb: mlfb,
            Description: desc,
            Rate: rate,
            RtUnit: runit,

           // isActive: true,
            CreatedBy: userDetails.userId,


        }
        console.log("slipData", slipData);
        //let errorList = [];
        //await api.post("/PostQuotationData/", slipData) 
        savePrice(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId,slipData)
            .then(response => {
                if (response.response) {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "success");
                    GridData();
                    clear()
                    handleCloseDia();

                   
                }
                else {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "error");
                    handleCloseDia();
                }
            })
            .catch(error => {
                handleSnackOpen("Error in saving.", "error");
                setIsLoader(false);
            })

    }
    function getProdMaster() {
        setIsLoading(true);
        getOffer(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                console.log("ResponseProd", response)
                setProdMasterData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })
    }
    function GridData() {
        setIsLoading(true);

        GetPriceList(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId)
            .then((response) => {
                setIsLoading(false);

                console.log("hicustomer", response);
                console.log(response.objResult);
                setData(response.objResult);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
            });
    }
    function GetEditButton() {
        return (
            <Tooltip title="Edit" placement="bottom">
                <span>
                    <Button href="javascript:void(0)">
                        <span>
                            <EditOutlinedIcon fontSize="small" className="attachFileIcon" />
                        </span>
                    </Button>
                </span>
            </Tooltip>
        );
    }
    return (
        <>
            <div>
                <Grid container spacing={2}>
                    {userDetails.roleId == 1 ? (
                        <Grid
                            container
                            spacing={2}
                            item
                            xs={12}
                            className="d-flex jc-space-bt"
                        >
                            <Grid item xs={12} className="text-left ml-1">
                                {/*<h2>Price Master</h2>*/}
                            </Grid>
                            <Grid container spacing={2} xs={12}>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        id="EPQUploadFile"
                                        variant="filled"
                                        className="bulk-upload"
                                        type="file"
                                        name="bulkUpload"
                                        key={inputFileValue}
                                        onChange={updateExcelHandler}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Button
                                        className="pt-button--primary bulk-upload-submit-btn"
                                        type="submit"

                                        onClick={submitValueDetails}
                                    >
                                        Upload
                                    </Button>
                                </Grid>


                                <Grid item xs={12} md={3}>
                                    <a
                                        href={PriceMaster_Template}
                                        download="PriceMaster_Template"
                                        target="_blank"
                                    >
                                        <Button className="pt-button--primary bulk-upload-submit-btn">
                                            Download Template
                                        </Button>
                                    </a>
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="bootstrap-input" >
                                            Product
                                        </InputLabel>
                                        <Select
                                            label="Product Filter"
                                            id=""
                                            name=""
                                            className=""
                                            fullWidth
                                            value={prodFilter}
                                            onChange={prodFilterChange}
                                            input={<BootstrapInput />}
                                        >
                                            {prodMasterData.map((item) => (
                                                <MenuItem key={item.offerId} value={item.offerId}>
                                                    {item.offerName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                            </Grid>
                        </Grid>

                    ) : (
                        <></>
                    )}

                    <div className="marginTop">
                        <Grid>
                            <MaterialTable
                                title="Price Master"
                                columns={columns}
                                data={data}
                                icons={tableIconsInputScreen}
                                options={{
                                    headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "370px", searchFieldAlignment: "left",
                                    toolbarButtonAlignment: "left", exportButton: { csv: true },  exportAllData: true,
                                }}
                                actions={[
                                    {
                                        icon: GetAddButton,
                                        isFreeAction: true,
                                        onClick: () => {
                                            setDialogSubmitBtn("Save");
                                            setCustDialogTitle("Add New MLFB");
                                            setOpenDia(true);
                                        },
                                    },

                                    {
                                        icon: GetEditButton,
                                        tisFreeAction: true,
                                        onClick: (e, rowData) => {
                                            setDialogSubmitBtn("Update");
                                            setCustDialogTitle("Update MLFB");
                                            setOpenDia(true)
                                            dialogDraftValuesFn(rowData);
                                        },
                                    },




                                ]}

                            />
                        </Grid>
                    </div>
                </Grid>
                <div>
                    <Dialog
                        open={dialogOpen}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {" Data Upload Alert"}
                        </DialogTitle>
                        <DialogContent>
                            {dialogContent && typeof dialogContent === "object" ? (
                                <DialogContentText id="alert-dialog-description">
                                    <h5>
                                        {dialogContent
                                            ?.filter((x, index) => index === 0)
                                            ?.map((c) => (
                                                <>&emsp;&emsp;{c.join(" ")}</>
                                            ))}
                                    </h5>
                                    <ol className="ol-bulkUpload-Error">
                                        {dialogContent
                                            ?.filter((x, index) => index !== 0)
                                            ?.map((c) => (
                                                <li>
                                                    {c.map((x) =>
                                                        x.includes("--") && x.includes(":") ? (
                                                            x
                                                        ) : (
                                                            <ul className="ul-bulkUpload-Error">
                                                                <li>{x}</li>
                                                            </ul>
                                                        )
                                                    )}
                                                </li>
                                            ))}
                                    </ol>
                                </DialogContentText>
                            ) : (
                                <DialogContentText
                                    id="alert-dialog-description "
                                    className="dialog-center"
                                >
                                    {dialogContent?.includes("successfully.") ? (
                                        <>
                                            {dialogContent?.split("successfully.")[0] +
                                                " successfully."}
                                            {dialogContent?.split("successfully.")?.length > 0 ? (
                                                <>
                                                    <br />
                                                    {dialogContent?.split("successfully.")[1]}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    ) : (
                                        dialogContent
                                    )}
                                </DialogContentText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                className="pt-button--secondary"
                                title="Close"
                            >
                                Close
                            </Button>
                            {errorData && errorData.length > 0 ? (
                                <Button
                                    onClick={exportErrorData}
                                    className="pt-button--secondary"
                                    title={
                                        "Export   upload error data to excel"
                                    }
                                >
                                    Export Error Data
                                </Button>
                            ) : (
                                <></>
                            )}
                        </DialogActions>
                    </Dialog>
                    <Dialog fullWidth
                        onClose={(event, reason) => {
                            if (reason !== "backdropClick") {
                                handleCloseDia();
                            }
                        }}
                        aria-labelledby="litigation-form--registration"
                        open={openDia}
                        maxWidth={"lg"}
                        disableEscapeKeyDown
                    >
                        <DialogTitle id="responsive-dialog-title">{custDialogTitle}</DialogTitle>
                        <div style={{ position: "absolute", right: "1%" }}>
                            <Tooltip title="Close">
                                <IconButton onClick={handleCloseDia}>
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                <Grid container spacing={2}>









                                    {/*<Grid item xs={12} sm={3}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                    {/*            DistCh*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <BootstrapInput id="cutomer_po"*/}
                                    {/*            name="cutomerPo"*/}
                                    {/*            value={dist}*/}
                                    {/*            onChange={handleChangeDist}*/}

                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}



                                    {/*<Grid item xs={12} sm={3}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                    {/*            Div SIGrp*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <BootstrapInput id="cutomer_po"*/}
                                    {/*            name="cutomerPo"*/}
                                    {/*            value={div}*/}
                                    {/*            onChange={handleChangeDiv}*/}

                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12} sm={3}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                    {/*            Name2*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <BootstrapInput id="cutomer_po"*/}
                                    {/*            name="cutomerPo"*/}
                                    {/*            value={name2}*/}
                                    {/*            onChange={handleChangeName2}*/}

                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}

                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                SPIRIDON Mat.No
                                            </InputLabel>
                                            <BootstrapInput id="name"
                                                name="cutomerPo"
                                                disabled={flag }

                                                onChange={handleChangeSpirid}
                                                value={spirid}

                                            />
                                            <div class="form-helper">{nameHelper}</div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                MLFB
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={mlfb}
                                                onChange={handleChangeMlfb}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Description
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={desc}
                                                onChange={handleChangeDesc}

                                            />
                                        </FormControl>
                                    </Grid>
                                    {/*<Grid item xs={12} sm={3}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                    {/*            CndTyp*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <BootstrapInput id="cutomer_po"*/}
                                    {/*            name="cutomerPo"*/}
                                    {/*            value={cnd}*/}
                                    {/*            onChange={handleChangeCnd}*/}

                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                Rate
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={rate}
                                                onChange={handleChangeRate}

                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControl fullWidth>
                                            <InputLabel shrink htmlFor="bootstrap-input">
                                                RUnit
                                            </InputLabel>
                                            <BootstrapInput id="cutomer_po"
                                                name="cutomerPo"
                                                value={runit}
                                                onChange={handleChangeRunit}

                                            />
                                        </FormControl>
                                    </Grid>
                                    {/*<Grid item xs={12} sm={3}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                    {/*           CndPrU*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <BootstrapInput id="cutomer_po"*/}
                                    {/*            name="cutomerPo"*/}
                                    {/*            value={cndPru}*/}
                                    {/*            onChange={handleChangeCndPru}*/}

                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12} sm={3}>*/}
                                    {/*    <FormControl fullWidth>*/}
                                    {/*        <InputLabel shrink htmlFor="bootstrap-input">*/}
                                    {/*            CndUnit*/}
                                    {/*        </InputLabel>*/}
                                    {/*        <BootstrapInput id="cutomer_po"*/}
                                    {/*            name="cutomerPo"*/}
                                    {/*            value={cndUnit}*/}
                                    {/*            onChange={handleChangeCndUnit}*/}

                                    {/*        />*/}
                                    {/*    </FormControl>*/}
                                    {/*</Grid>*/}
                                    {/*<Grid item xs={12} sm={3}>*/}
                                    {/*    <MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                    {/*        <KeyboardDatePicker*/}
                                    {/*            // margin="normal"*/}
                                    {/*            id="date-picker-dialog"*/}
                                    {/*            label="PO Date"*/}
                                    {/*            format="MM/dd/yyyy"*/}
                                    {/*            placeholder="MM/dd/yyyy"*/}
                                    {/*            fullWidth*/}
                                    {/*            // maxDate={new Date()}*/}
                                    {/*            value={start}*/}
                                    {/*            onChange={handleChangeStart}*/}
                                    {/*            KeyboardButtonProps={{*/}
                                    {/*                "aria-label": "change date",*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </MuiPickersUtilsProvider>*/}
                                        
                                    {/*</Grid>*/}

                                </Grid>

                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                type="submit"

                                className="pt-button--secondary"
                                id="addUserSave"
                                onClick={
                                    dialogSubmitBtn === "Save" ? save : submit
                                }
                            >
                                {dialogSubmitBtn}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {isLoader ? <Loader loaderText="Loading...!" /> : <></>}
                {isLoading ? <Loader loaderText="Loading...!" /> : <></>}

            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
}