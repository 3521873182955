
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MaterialTable from "material-table";
import * as FileSaver from 'file-saver';
import {
    TextField,
    Button,
    Grid,
    IconButton,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import Loader from "../../Components/Loader/Loader";
import { tableIcons } from "../../Utilities/Utility";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { uploadTNCDoc, getTermsAndConditionAttchData } from "../../APIs/api_Insert";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";
export default function Termncondition() {
    //const [tncuHandler, setTncuHandler] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const token = useSelector((state) => state.saveTokenReducer.token);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const UID = useSelector((state) => state.saveUserIdReducer);

    //fileUrlWithSasToken//filename//filetype
    var columns = [
        { title: "Options", field: "", hidden: true },
        { title: "File Name", field: "filename" },
        { title: "File Type", field: "filetype" },
        {
            title: "Download File", field: "doc", editable: false,
            render: () => (

                <a href={downloadUrl} target="_blank"><CloudDownloadIcon className="checkIcon" /></a>
            ),
        },

    ]

    const [tncUploadKey, setTncUploadKey] = useState(Date.now); //key
    const [tncUploadFile, setTncUploadFile] = useState(null);//file
    //const tncuHandler = (e) => {
    //    console.log("hii", e.target.files[0]);
    //    setTncUploadFile(e.target.files[0]);
    //}

    const tncuHandler = (e) => {
        const chosenFile = Array.prototype.slice.call(e.target.files);
        if (chosenFile.length > 0) {
            if (chosenFile[0].type.toString().includes("pdf")) {
                setTncUploadFile(chosenFile);
            } else {
                setTncUploadKey(Date.now);
                setTncUploadFile([]);
                handleSnackOpen("Upload only PDF files", "warning");
            }
        }
    };


    //uploadTNCDoc
    //const handleUpload = (e) => {

    //}

    const handleClose = (e) => {
        setOpen(false);
    };

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };

    const [downloadUrl, setDownloadUrl] = useState("");

    const handleUpload = (e) => {
        setIsLoading(true);
        const fileChoosen = new FormData();

        fileChoosen.append("file", tncUploadFile[0]);

      

        uploadTNCDoc(siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId, fileChoosen)
            .then((response) => {
                if (response.response) {
                    console.log("notesPost", response);
                    //handleCloseDia()

                    //setDownloadUrl(response.fileUrlWithSasToken);
                    setIsLoading(false);
                   
                    window.location.reload();
                    handleSnackOpen(response.responseMsg, "success");
                   
                    //setIsLoading(false);
                    //getItemGrid(refId)


                } else {

                    //handleCloseDia()
                    handleSnackOpen(response.responseMsg, "error");
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                //handleCloseDia()

                //handleSnackOpen("Exception : " + error, "error");
                setIsLoading(false);
            });

    }
    //console.log("downloadUrl", downloadUrl);

    //getTermsAndConditionAttchData
    const [gridData, setGridData] = useState([]);
    function getGridData() {
        setIsLoading(true);
        getTermsAndConditionAttchData(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId
           
           )
            .then((response) => {
                console.log("getTable data", response);
                //response.map((x) => setDownloadUrl(x.fileUrlWithSasToken));
                let xyz = response[0].fileUrlWithSasToken;
                console.log("xyz", xyz);
                setDownloadUrl(xyz);
                setGridData(response);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                handleSnackOpen(error, "error");
            })


    }
   

    //const handleFileDownload = (rowData) => {
    //    setIsLoading(true);
       
    //    console.log(rowData.ourRef);

    //    var ourref = rowData.ourRef;
    //    downloadPDFCertificate(siteToken,
    //        azureToken.token,
    //        UID.id,
    //        userDetails.userEmail,
    //        userDetails.gid,
    //        userDetails.roleName,
    //        userDetails.roleId, ourref)
    //        .then((response) => {
    //            if (response.status === 200) {
    //                if (response.data.byteLength <= 200) {
    //                    setIsLoading(false);
    //                    handleSnackOpen("No Records are Found", "success");
    //                } else {

    //                    const fileName = "SilverReport";
    //                    var blob = new Blob([response.data], {
    //                        type: "application/pdf",
    //                    });
    //                    FileSaver.saveAs(blob, fileName + ".pdf");
    //                    setIsLoading(false);
    //                    handleSnackOpen("Report downloaded.", "success");
    //                }
    //            } else {
    //                setIsLoading(false);
    //                handleSnackOpen("Report download failed.", "error");
    //            }
    //        })
    //        .catch((error) => {
    //            setIsLoading(false);
    //            handleSnackOpen("Error while downloading Report.", "error");
    //        })
    //}

    useEffect(() => {
        getGridData();
    },[])
    return (
        <div className="po-upload">
            <Grid item xs={12}  >
                <h3 className="title-heading">Terms and Condition</h3>
            </Grid>

            <Grid container spacing={3} md={12} className="req-grid">
                <Grid item xs={12} sm={6} md={3} >
                    <form className="upload-form">
                        <TextField
                            id="snapshotUpload"
                            variant="filled"
                           
                            label="Upload Pdf"
                            inputProps={{ accept: "application/pdf", multiple: false }}
                            fullWidth
                            onChange={tncuHandler}
                            key={tncUploadKey}
                            type="file"
                            InputLabelProps={{ shrink: true }}
                        />
                    </form>
                    <span>*formats allowed are .pdf</span>
                </Grid>

                <Grid item xs={12} sm={2} md={1} >
                    <Button
                        onClick={handleUpload}
                        className="pt-button--primary button-submit" variant="contained">
                        SAVE
                    </Button>
                </Grid>

            </Grid>

            <Grid container spacing={2} >
                <Grid xs={12}>
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={gridData}
                        icons={tableIconsInputScreen}
                        
                        //isLoading={isLoader}

                        options={{
                            /*rowStyle: rowData => new Date(rowData.povalidity).getTime() > new Date(d1).getTime() ? ({ backgroundColor: "rgba(255,84,84,0.5019607843137255)" }) : ({ backgroundColor: "#FFFFFF", }),*/
                            exportButton: { csv: true }, exportAllData: true,
                            //exportCsv: (columns, data) => {
                            //    console.log("Report Icon Clicked");
                            //    //getQuotationListReport();
                            //},
                           
                            headerStyle: { background: '#d3d3d3' }, tableOptionsUser, maxBodyHeight: "400px", searchFieldAlignment: "left",
                            toolbarButtonAlignment: "left",
                        }}
                       

      

                    />
                </Grid>
            </Grid>
            {/*{isLoader ? <Loader loaderText="Loading...!" /> : <></>}*/}
            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />

        </div>);
}
