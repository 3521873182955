import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import Cognisphere from "../../assets/images/Cognisphere.png";
import Loader from "../../Components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import Header from "../../Components/Header/Header";
import "./Home.scss";
import { MasterRouteLanding } from "../../Utilities/Utility";
import "./Home.scss";
import {
    TextField,
    Button,

    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import { getAllRoles } from "../../APIs/api_Insert";
import { storeLandingMenuItemsAction, storeMenuItemsAction } from "../../Redux/Actions/actions";



export default function HomePage() {
    const dispatch = useDispatch();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const token = useSelector((state) => state.saveTokenReducer.token);

    const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const azureToken = useSelector((state) => state.saveAzureTokenReducer);
    const [routes, setRoutes] = useState([]);
    function getMenus() {
        //console.log("menu", MasterRouteLanding)
        //let filtered = MasterRouteLanding.filter((c) => c.roleId === 1);
        //setRoutes(filtered);
        getAllRoles(
            siteToken,
            azureToken.token,
            UID.id,
            userDetails.userEmail,
            userDetails.gid,
            userDetails.roleName,
            userDetails.roleId
        )
            //.then((response) => {
            //    if (response) {
            //        console.log('land', response)
            //        setRoutes(response);
            //    } else {
            //        setRoutes([]);
            //    }
            //})
            .then((response) => {
                if (response) {
                    console.log(response)
                   // var resp = response.slice(1, response.length - 1);
                    // setRoutes(resp);
                    dispatch(storeLandingMenuItemsAction(response));
                    dispatch(storeMenuItemsAction(response));
                } else {
                    // setRoutes([]);
                    dispatch(storeMenuItemsAction([]));
                    dispatch(storeLandingMenuItemsAction(response));
                }
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
            });
    }
    var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;

    window.history.pushState({ path: newurl }, "", newurl);

    //useEffect(() => {
    //    if (userDetails && userDetails.userId > 0 && siteToken.token !== "") {

    //        getMenus();
    //    }
    //    // dispatch(isForgotPasswordAction(false));
    //}, [userDetails, siteToken]);
    useEffect(() => {
        if (userDetails?.userId > 0 && token !== "" && landingMenuItems?.length <= 0) {
            getMenus();
        }
    }, [userDetails]);
    return (
        <>
            <div className="potts-master-block">
                <Header />
                <main className="main-component">
                    {/* <img
            src={Cognisphere}
            alt="Cognisphere"
            className="homepage-cognisphere"
          /> */}
                    {landingMenuItems.length > 0 ? (
                        <div className="bg-landing-page">
                            <Container maxWidth="xl">
                                <Grid container className="homepage-container">
                                    <Grid item xs={12} sm={12} md={12} lg={5} className="z-9">
                                        <div className="logged-in-user">
                                            <h1 className="logged-in-user-heading">
                                                <span className="logged-in-greetings">Hello,</span>
                                                <span className="logged-in-user-name">{userDetails.firstName}</span>
                                                <span className="logged-in-user-name">{userDetails.userFirstName}</span>
                                            </h1>
                                            <p className="logged-in-project-desc">
                                                Welcome to EP Quotation Tool
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={7}
                                        className="landing-page-menu">
                                        <Grid container spacing={2}>
                                            {landingMenuItems.map((item, index) => (
                                                <Grid item xs={12} sm={6} md={6} key={index}>
                                                    <Link to={item.menuLink} key={index}>
                                                        <div className="menu-item-container">
                                                            <div className="menu-item-text-block">
                                                                <span>{item.menuName}</span>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    {/*<Grid*/}
                                    {/*    item*/}
                                    {/*    xs={12}*/}
                                    {/*    sm={12}*/}
                                    {/*    md={12}*/}
                                    {/*    lg={7}*/}
                                    {/*    className="landing-page-menu"*/}
                                    {/*>*/}
                                    {/*    <Grid container spacing={2}>*/}

                                    {/*        <Grid container spacing={2}>*/}
                                    {/*            <Grid item xs={12} sm={6} md={6} >*/}
                                    {/*                <Link to="" >*/}
                                    {/*                    <div className="menu-item-container">*/}
                                    {/*                        <div className="menu-item-text-block">*/}
                                    {/*                            <span className="align-center ml-1">*/}
                                    {/*                                Dashboard*/}
                                    {/*                            </span>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                </Link>*/}
                                    {/*            </Grid>*/}
                                    {/*            */}{/*<Grid item xs={12} sm={6} md={6} >*/}
                                    {/*            */}{/*    <Link to="/generateReport" >*/}
                                    {/*            */}{/*        <div className="menu-item-container">*/}
                                    {/*            */}{/*            <div className="menu-item-text-block">*/}
                                    {/*            */}{/*                <span className="align-center ml-1">*/}
                                    {/*            */}{/*                    Generate Report*/}
                                    {/*            */}{/*                </span>*/}
                                    {/*            */}{/*            </div>*/}
                                    {/*            */}{/*        </div>*/}
                                    {/*            */}{/*    </Link>*/}
                                    {/*            */}{/*</Grid>*/}
                                    {/*            <Grid item xs={12} sm={6} md={6} >*/}
                                    {/*                <Link to="/poupload" >*/}
                                    {/*                    <div className="menu-item-container">*/}
                                    {/*                        <div className="menu-item-text-block">*/}
                                    {/*                            <span className="align-center ml-1">*/}
                                    {/*                                PO Upload*/}
                                    {/*                            </span>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                </Link>*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid item xs={12} sm={6} md={6} >*/}
                                    {/*                <Link to="/upload" >*/}
                                    {/*                    <div className="menu-item-container">*/}
                                    {/*                        <div className="menu-item-text-block">*/}
                                    {/*                            <span className="align-center ml-1">*/}
                                    {/*                                Master Upload*/}
                                    {/*                            </span>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                </Link>*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid item xs={12} sm={6} md={6} >*/}
                                    {/*                <Link to="/customerTestimonal" >*/}
                                    {/*                    <div className="menu-item-container">*/}
                                    {/*                        <div className="menu-item-text-block">*/}
                                    {/*                            <span className="align-center ml-1">*/}
                                    {/*                                Customer Testimonial*/}
                                    {/*                            </span>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                </Link>*/}
                                    {/*            </Grid>*/}
                                    {/*            <Grid item xs={12} sm={6} md={6} >*/}
                                    {/*                <Link to="/userManagement" >*/}
                                    {/*                    <div className="menu-item-container">*/}
                                    {/*                        <div className="menu-item-text-block">*/}
                                    {/*                            <span className="align-center ml-1">*/}
                                    {/*                                User Management*/}
                                    {/*                            </span>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                </Link>*/}
                                    {/*            </Grid>*/}

                                    {/*            <Grid item xs={12} sm={6} md={6} >*/}
                                    {/*                <Link to="/offer" >*/}
                                    {/*                    <div className="menu-item-container">*/}
                                    {/*                        <div className="menu-item-text-block">*/}
                                    {/*                            <span className="align-center ml-1">*/}
                                    {/*                                Prepare Offer*/}
                                    {/*                            </span>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                </Link>*/}
                                    {/*            </Grid>*/}

                                    {/*        </Grid>*/}



                                    {/*    </Grid>*/}


                                    {/*</Grid>*/}
                                </Grid>
                            </Container>
                        </div>
                    ) : (
                        <Loader />
                    )}
                </main>
            </div>
        </>
    );
}
